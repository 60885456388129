
import { defaultApi } from "./api";
import { BASE_URL } from "./constant";

export async function getHomeHero() {
  const res = await defaultApi.get(`/api/home-heroes`);
  return res.data.data;
}

export async function getSolarPower() {
  const res = await defaultApi.get(`/api/solar-powers?populate=*`);
  return res.data.data;
}

export async function getWhySolar() {
  const res = await defaultApi.get(`/api/why-solars?populate=*`);
  return res.data.data;
}

export async function getWhyDivy() {
  const res = await defaultApi.get(`/api/why-divies?populate=*`);
  return res.data.data;
}
export async function getProducts() {
  const res = await defaultApi.get(`/api/our-products?populate=*`);
  return res.data.data;
}

export async function getLatestProject() {
  const res = await defaultApi.get(`/api/latest-projects?populate=*`);
  return res.data.data;
}

export async function getCalculator() {
  const res = await defaultApi.get(`/api/calculators`);
  return res.data.data;
}

export async function getTestimonials() {
  const res = await defaultApi.get(`/api/testimonials?populate=*`);
  return res.data.data;
}
export async function getTieUps() {
  const res = await defaultApi.get(`/api/our-tie-ups?populate=*`);
  return res.data.data;
}



