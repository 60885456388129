import React, { useState } from "react";
import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import Select from "react-select";
import { State, City } from "country-state-city";
import { addContact } from "../../Services/ContactService";
import { ToastContainer, toast } from "react-toastify";

function JoinUs() {
  const onloadCallback = () => {
    console.log("confirm");
  };
  const style = {
    control: base => ({
      ...base,
      border: 0,
      borderBottom: '2px solid rgb(107, 114, 128)',
      borderRadius: 0
    })
  };

  const [selectedCity, setSelectedCity] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [state, setState] = useState("");
  const [message, setMessage] = useState("");
  const [feedback, setFeedback] = useState("");

  const dataToSent = {
    data: {
      firstname: firstname,
      lastname: lastname,
      email: email,
      number: number,
      state: state.name,
      city: selectedCity.name,
      message: message,
      feedback: feedback,
    }

  }
  const addContactData = async (e) => {
    try {
      const { data } = await addContact(dataToSent);
      toast.success("Success!")
    }
    catch (e) {
      toast.error(e?.response?.data?.error?.message)
      console.log(e);
    }
  }

 
  return (
    <div>
      <div className="flex flex-col items-center">
        <div className=" flex flex-col justify-center items-center">
          <h1 className="text-2xl lg:text-5xl xl:text-5xl text-md text-center ">Join Us Now !</h1>
          <p className="text-md text-slate-700 ">
            Need a true supporter like you
          </p>
        </div>
          <div className="flex flex-col lg:flex-row justify-center items-center lg:w-full max-w-5xl pt-10">
            <div className="w-full lg:w-[30rem] mx-4 text-white rounded-t-3xl bg-[#F56B39] rounded-bl-3xl relative overflow-hidden">
              <div className="p-8">
                <h1 className="text-2xl font-bold text-center mb-6">
                  Contact Information 
                </h1>
                <p className="text-sm text-center font-light mb-10 ">
                  Fill up the form and our team will get in touch with you
                </p>
                <div className="space-y-10  mt-6">
                  <div className="flex items-center font-thin gap-2 text-sm">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 text-white fill-current rotate-[16deg]"
                      viewBox="0 0 512 512"
                    >
                      <path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" />
                    </svg>

                    <a href="tel:+91 9310259325">
                      <span>+91 9310259325</span>
                    </a>
                  </div>

                  <div className="flex items-center font-thin text-sm gap-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="w-6 h-6"
                    >
                      <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
                      <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
                    </svg>

                    <div>
                   <a href="mailto:customersupport1@divypower.com">
                      <span>customersupport1@divypower.com </span>
                    </a>
                    <a href="mailto:info@divypower.com">
                      <span>info@divypower.com</span>
                    </a>
                   </div>
                  </div>

                  <div className="flex items-center font-thin text-sm gap-2 ">
                    {/* <MapPin className='w-6 h-6 mr-2' /> */}
                    <img
                      src="/Image/Location1.png"
                      className="white-image"
                      alt="White Phone"
                    />
                  <a
                      href="https://www.google.com/maps/search/53, Sony Complex Ramteram Road Ghaziabad"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <h1>53, Sony Complex Ramteram Road Ghaziabad</h1>
                    </a>
                  </div>

                  <div className="flex gap-2 pt-36 relative z-10">
                    <a
                      href="https://www.facebook.com/yourprofile"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="/Image/facebook.png"
                        alt="Facebook"
                        className="w-8 h-8"
                      />
                    </a>
                    <a
                      href="https://www.instagram.com/yourprofile"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="/Image/instagram.png"
                        alt="Instagram"
                        className="w-8 h-8"
                      />
                    </a>
                    <a
                      href="https://www.linkedin.com/in/yourprofile"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="/Image/linkedin.png"
                        alt="LinkedIn"
                        className="w-8 h-8"
                      />
                    </a>
                  </div>
                </div>
              </div>

              <div className="absolute -right-[200px] md:-right-[300px] -bottom-[235px]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-[500px]"
                  viewBox="0 0 592 592"
                >
                  <circle cx="296" cy="296" r="296" fill="#ffc680" />
                  <g
                    fill="#f56b39"
                    stroke="#ffc680"
                    strokeWidth="4"
                    transform="translate(13 32)"
                  >
                    <circle cx="80" cy="80" r="80" stroke="none" />
                    <circle cx="80" cy="80" r="78" fill="none" />
                  </g>
                </svg>
              </div>
            </div>
            <div className="bg-white mt-10  space-y-10  lg:ml-10  lg:mr-auto mb-24  lg:mb-10 text-black rounded-lg">
              <div className="px-8 py-6">
                <div className=" sm:grid md:grid lg:grid xl:grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2  gap-6 ">
                  <div className="">
                    <input
                      type="text"
                      name="firstName"
                      id="firstName"
                      placeholder="First Name"
                      className="mt-1 outline-none my-input focus:ring-orange-500 focus:border-orange-500  border-b-2 w-full  border-gray-500"
                      value={firstname}
                      onChange={(e) => setFirstname(e.target.value)}
                   />
                  </div>
                  <div className="mt-5 sm:mt-0 md:mt-0 lg:mt-0 xl:mt-0" style={{ color: "#5D5D5D" }}> 
                    <input
                     value={lastname}
                     onChange={(e) => setLastname(e.target.value)}
                      type="text"
                      name="lastName"
                      id="lastName"
                      placeholder="Last Name"
                      className="mt-1  my-input outline-none my-input focus:ring-orange-500 focus:border-orange-500  border-b-2 w-full    border-gray-500"
                    />
                  </div>
                  <div className="mt-5 sm:mt-0 md:mt-0 lg:mt-0 xl:mt-0">
                    <input
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Enter Your Email"
                      className="mt-1 outline-none my-input focus:ring-orange-500 focus:border-orange-500  border-b-2 w-full    border-gray-500"
                    />
                  </div>
                  <div className="mt-5 sm:mt-0 md:mt-0 lg:mt-0 xl:mt-0 ">
                    <input
                     value={number}
                     onChange={(e) => setNumber(e.target.value)}
                      type="text"
                      name="number"
                      id="number"
                      placeholder="Enter Your Contact Number "
                      className="mt-1 outline-none my-input focus:ring-orange-500 focus:border-orange-500  border-b-2 w-full    border-gray-500"
                    />
                  </div>

                  <div className="focus:ring-orange-500 focus:border-orange-500 mt-5 sm:mt-0 md:mt-0 lg:mt-0 xl:mt-0">
                    <Select
                      placeholder="Select State"
                      options={State?.getStatesOfCountry("IN")}
                      styles={style}
                      getOptionLabel={(options) => {
                        return options["name"];
                      }}
                      getOptionValue={(options) => {
                        return options["name"];
                      }}
                      onChange={(item) => {
                        setState(item);
                      }}
                    />
                  </div>

                  <div className=" focus:ring-orange-500 focus:border-orange-500 mt-5 sm:mt-0 md:mt-0 lg:mt-0 xl:mt-0">
                  <Select
                        placeholder="Select City"
                        styles={style}
                        name="city"
                        options={City.getCitiesOfState(
                          state?.countryCode,
                          state?.isoCode
                        )}
                        getOptionLabel={(options) => {
                          return options["name"];
                        }}
                        getOptionValue={(options) => {
                          return options["name"];
                        }}
                        value={selectedCity}
                        onChange={(item) => {
                          setSelectedCity(item);
                        }}
                      />
                  </div>
                  <div className="col-span-2 mt-5 sm:mt-0 md:mt-0 lg:mt-0 xl:mt-0 ">
                    <input
                     value={message}
                     onChange={(e) => setMessage(e.target.value)}
                      type="text"
                      name="hearingSource"
                      id="hearingSource"
                      placeholder="How did you hear about us?"
                      className="mt-1 outline-none my-input   focus:ring-orange-500 placeholder-ml-5  focus:border-orange-500  w-full rounded-2xl border-2 py-4  border-gray-500"
                      style={{ paddingLeft: "1rem" }} // Add left padding to the input field
                    />
                  </div>

                  <div className="col-span-2 mt-5 sm:mt-0 md:mt-0 lg:mt-0 xl:mt-0 ">
                    <input
                     value={feedback}
                     onChange={(e) => setFeedback(e.target.value)}
                      id="feedback"
                      name="feedback"
                      placeholder="Feedback"
                      className="mt-1 outline-none my-input focus:ring-orange-500 placeholder-ml-5  focus:border-orange-500 rounded-2xl  w-full  border-2 py-4   border-gray-500"
                      style={{ paddingLeft: "1rem" }}
                    />
                  </div>

                  <div className="col-span-1    flex items-center">
                    <div
                      style={{ transform: "scale(0.7) translate(-60px, 25px)" }}
                    >
                      <ReCAPTCHA
                        sitekey="6LfjIpYnAAAAAGFWXPVXqV0NyUHtuFd1XENtXar2"
                        onChange={onloadCallback}
                        size="normal"
                      />
                    </div>
                  </div>
                  <div className="col-span-1 ml-24 rounded-lg  flex items-end justify-start sm:justify-end md:justify-end lg:justify-end xl:justify-end mt-5 sm:mt-0 md:mt-0 lg:mt-0 xl:mt-0">
                    <button
                      type="submit"
                      style={{ backgroundColor: "#F56B39" }}
                      className="inline-flex justify-center py-2 px-12 border border-transparent shadow-sm text-sm font-medium rounded-md  text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 "
                      onClick={() => addContactData()}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

  );
}

export default JoinUs;
