import React from 'react'
import { Link } from 'react-router-dom'

const ComponentsOfSolarSolution = () => {
    const componentData = [
        {
            "img": "Assets/Solar panel.jpg",
          "heading": "Solar Panel",
          "to":"/photovoltaic"
        },
        {
            "img": "Assets/Solar inverter.png",
          "heading": "Inverter",
          "to":"/inverter"
        },
        {
            "img": "Assets/Solar structutre.jpg",
          "heading": "Structure",
          "to":"/solar-structure"
        },
        {
            "img": "Assets/chemical-earthing-new.png",
          "heading": "Earthing",
          "to":"/earthing"
        },
        {
            "img": "Assets/lightning-arrestor-solar.webp",
          "heading": "Lightning Arrester",
          "to":"/ese"
        },
        {
            "img": "Assets/balance of sheet.jpg",
          "heading": "Balanced System",
          "to":"/balance-sheet"
        },
        {
            "img": "Assets/Solar batteried.jpeg",
          "heading": "Batteries",
          "to":"/off-grid"
        }
    ]
    return (
        <div>
            <h1 className="text-center text-4xl font-bold">Components</h1>
            <div className="flex flex-row flex-wrap justify-center items-center space-x-4 gap-10  mt-8">
               {
                componentData?.map(item=>
                    <Link to={item.to} onClick={() => window.location.reload()}>
                    <div className="flex flex-col items-center">
                        <img
                            src={item.img}
                            alt="1"
                            className=" w-full sm:w-48 md:w-48 lg:w-48 xl:w-48 sm:h-48 md:h-48 lg:h-48 xl:h-48 h-auto p-2 hover:opacity-80"
                        />
                        <p
                            className="text-center mt-2 text-xl font-bold"
                            style={{
                                fontFamily: "  normal normal normal 35px/53px Poppins;",
                                color: "#000000",
                            }}
                        >
                            {item.heading}
                        </p>
                    </div>
                </Link>
                    )
               }
            </div>
        </div>
    )
}

export default ComponentsOfSolarSolution

