import React from 'react'
import styles from "../Home.module.css";
import { useQuery } from '@tanstack/react-query';
import { getSolarPower } from '../../../Services/HomeService';
import { BASE_URL } from '../../../Services/constant';

const WhatSolarPower = () => {
    const { data: solarPower , status ,error } = useQuery({
        queryKey: ["solarPower"],
        queryFn: getSolarPower,
      });
      if (status === 'loading') {
        return <span>Loading...</span>
      }
    
      if (status === 'error') {
        return <span>Error: {error.message}</span>
      }
  return (
    <>
       {
        solarPower?.map(item =>
          <div className="mt-24 ml-0 sm:ml-0 md:ml-10 lg:ml-10 xl:ml-10  lg:mx-26  flex flex-col-reverse items-center  sm:flex-col-reverse md:flex-row  lg:flex-row xl:flex-row gap-10 p-10 sm:p-10 md:p-10 lg:p-0 xl:p-0" key={item.id}>
            {/* First Row - Image Section */}
            <div className="w-full sm:w-full md:w-1/2 lg:w-1/2 xl:w-1/2">
              <img
                src={`${BASE_URL}${item?.attributes?.image.data.attributes.url}`}
                className="w-full sm:w-full md:w-full lg:w-96 xl:w-96 mx-auto ml-0 sm:ml-0 md:ml-0 lg:ml-20  md:mr-auto"
                alt="Solar Power"
              />
            </div>
            {/* First Row - What is Solar Power Section */}
            <div
              className=" md:w-1/2 sm:w-full text-center md:mr-20 lg:mr-20 sm:mr-0 md:mt-0 mt-4 md:p-0"
              style={{
                font: "normal normal normal 32px/48px Poppins",
                letterspacing: "10px",
                color: "#000000",
                opacity: "1",
              }}
            >
              <h1 className="text-3xl md:text-4xl lg:text-4xl xl:text-4xl  font-bold container3 ">
                {item?.attributes?.Heading}
              </h1> 
              <div className="leading-normal">
                <p className="solar break-words text-xl md:text-2xl lg:text-2xl xl:text-2xl mt-5 " dangerouslySetInnerHTML={{ __html: item?.attributes?.Content }}>
                </p>
              </div>
            </div>
          </div>
        )
      }
    </>
  )
}

export default WhatSolarPower
