import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { getSubsidy } from '../../../../Services/ProductServices';

const SubSidyTable = () => {
    const {
        data: subsidy,
        status,
        error,
    } = useQuery({
        queryKey: ["subsidy"],
        queryFn: getSubsidy,
    });
    if (status === "loading") {
        return <span>Loading...</span>;
    }

    if (status === "error") {
        return <span>Error: {error.message}</span>;
    }
    return (
        <div>
            <div className="mt-24 px-6 overflow-auto">
                <h1 className="text-center font-bold text-4xl mt-10">
                    Subsidy
                </h1>
                <table className="max-w-5xl mx-auto w-full mt-10 border-collapse">
                    <thead>
                        <tr>
                            <th
                                className="border-2 text-3xl border-black  px-4 py-4 first:border-l-0 last:border-r-0"
                                style={{
                                    borderBottom: "4px solid black",
                                    borderRight: "4px solid black",
                                    borderTop: "4px solid black",
                                }}
                            >
                                KWP.
                            </th>
                            <th
                                className="border-2 text-3xl  border-black  px-4 py-4 first:border-l-0 last:border-r-0"
                                style={{
                                    borderTop: "4px solid black",
                                    borderBottom: "4px solid black",
                                }}
                            >
                                Price
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            subsidy?.map(el =>
                                <tr>

                                    <td
                                        className="border-3 border-black  px-4 py-12 first:border-l-0 last:border-r-0 text-center"
                                        style={{
                                            borderBottom: "4px solid black",
                                            borderRight: "4px solid black",
                                        }}
                                    >
                                        {el.attributes.KWP}
                                    </td>
                                    <td
                                        className="border-2 border-black  px-4 py-12 first:border-l-0 last:border-r-0 text-center"
                                        style={{ borderBottom: "4px solid black" }}
                                    > {el.attributes.price}</td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default SubSidyTable
