import { useState } from "react";
import cn from "../../Utils/cn";
import { motion } from "framer-motion";
import { getTestimonials } from "../../Services/HomeService";
import { useQuery } from "@tanstack/react-query";
import { ChevronRight, ChevronLeft } from "lucide-react";
import { BASE_URL } from "../../Services/constant";


export default function Testimonial() {
  const { data: testimonials } = useQuery({
    queryKey: ["testimonials"],
    queryFn: getTestimonials,
  });

  const [active, setActive] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(0);
  const handleNextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % totalSlides);
  };

  const handlePrevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide - 1 + totalSlides) % totalSlides);
  };
  const totalSlides = 4;

  const onActive = (index) => () => {
    setActive(index);
  };

  return (
    <div className="  mt-24 mb-32">
      <h3 className="text-center font-bold  text-3xl">Testimonial</h3>
       {/* desktop testimonial  */}
      <div
        className={`flex flex-col relative justify-center testimonial-section hidden-property-desktop`}
        id="testimonialSection"
      >
        <div className="flex items-start justify-center mt-8 ">
          {/* First Column - Three Circular Images on the Left Side */}
          <div className="flex-col mr-24 sm:mr-32 md:mr-32 lg:mr-32 xl:mr-32 testimonial-wrapper space-y-10">
            {testimonials?.slice(0, 3).map((item, index) => (
              <div className="flex flex-col items-center ml-24" key={`part-left-${index}${item?.id}`}>
                <div
                  className="rounded-full overflow-hidden w-20 h-20 transition-all"
                  onClick={onActive(index)}
                >
                  <motion.img
                    src="Image/old1.png"
                    alt="John"
                    className={cn(
                      "object-cover rounded-full block absolute", 
                      active === index ? "w-32" : "w-20"
                    )}
                    initial={{ width: 80 }}
                    animate={{
                      top: active === index ? 20 : "auto",
                      left: active === index ? "calc(50vw - 75px)" : "auto",
                      width: active === index ? 128 : 80,
                    }}
                  />
                </div>
              </div>
            ))}


          </div>

          {/* Second Column - Centered Image and Content */}
          <div className="flex flex-col testimonial-wrapper items-center">
            <div className="w-32 h-32 rounded-full"></div>
            {testimonials?.map((item, index) => (
              <motion.div
              key={item?.id}
                initial={{ opacity: 0, y: 20, height: 0 }}
                animate={{
                  opacity: active === index ? 1 : 0,
                  y: active === index ? 0 : 20,
                  height: active === index ? "auto" : 0,
                }}
                className="text-center"
              >
                <p className="text-2xl font-md mt-4">{item?.attributes?.Name}</p>
                <p className="text-center font-md text-lg  mt-2"  dangerouslySetInnerHTML={{
                        __html:item?.attributes?.Feedback,
                      }}>
                </p>
              </motion.div>
            ))}
          </div>

          {/* Third Column - Three Circular Images on the Right Side */}

          <div className="flex-col ml-24  testimonial-wrapper space-y-10 ">
            {testimonials?.slice(3, 6).map((item, index) => (
              <div className="flex flex-col items-center mx-4" key={`part-right-${index}${item?.id}`} >
                <div
                  className="rounded-full overflow-hidden w-20 h-20 mr-24 "
                  onClick={onActive(index + 3)}
                >
                  <motion.img
                    src="Image/old1.png"
                    alt="Emily"
                    className={cn(
                      "object-cover rounded-full absolute",
                      active === (3 + index) ? " w-32" : "w-20"
                    )}
                    initial={{ width: 80 }}
                    animate={{
                      top: active === (3 + index) ? 20 : "auto",
                      left: active === (3 + index) ? "calc(50vw - 75px)" : "auto",
                      width: active === (3 + index) ? 128 : 80,
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
 {/* mobile testimonal  */}
      <div
        className={`flex flex-col relative justify-center testimonial-section hidden-property-mobile`}
        id="testimonialSection"
      >
        {
          testimonials &&
          <div className="flex flex-col items-start justify-center ">
            <div className="flex flex-col items-center justify-center mt-10 ">
              <div className="w-full sm:w-full md:w-1/2 lg:w-1/2 xl:w-1/2  flex flex-col items-center justify-center">
                <img
                  src={`${BASE_URL}${testimonials[currentSlide].attributes?.Image.data.attributes?.url}`}
                  className="w-1/4 sm:w-full h-auto"
                  alt="Product"
                />
                <p className="text-2xl font-md mt-4">{testimonials[currentSlide].attributes?.Name}</p>
                <p className="text-center font-md text-lg  mt-2 px-5" dangerouslySetInnerHTML={{
                        __html: testimonials[currentSlide].attributes?.Feedback,
                      }}>
                  {/* {testimonials[currentSlide].attributes?.Feedback} */}
                </p>
              </div>

            </div>

            <div className="flex justify-center m-auto mt-10 gap-4 ">
              {
                testimonials?.map((item, index) =>
                  <button
                    key={item?.id}
                    className={`h-5 w-5 rounded-full ${currentSlide === index ? "bg-orange-500" : "bg-gray-300"
                      }`}
                    onClick={() => setCurrentSlide(index)}
                  />
                )}
            </div>
            <button
              type="button"
              className="absolute arrow-btn-left bottom-6  flex items-center justify-center h-full  cursor-pointer group focus:outline-none"
              data-carousel-prev
              onClick={handlePrevSlide}
            >
              <ChevronLeft className="w-16 h-16 rounded-full  bg-white   py-4 group-hover:bg-white group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none" />
            </button>

            <button
              type="button"
              className="absolute arrow-btn-right bottom-6 flex items-center justify-center h-full  cursor-pointer group focus:outline-none"
              data-carousel-next
              onClick={handleNextSlide}
            >
              <ChevronRight className="w-16 h-16 rounded-full  bg-white  py-4 group-hover:bg-white group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none" />
            </button>
          </div>
        }

      </div>
    </div>
  );
}

