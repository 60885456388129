export const serviceLinks = [

  {
    url: "/epc",
    name: "EPC",
  },
  {
    url: "/pmc",
    name: "PMC",
  },
  {
    url: "/installation",
    name: "Installation and Commission",
  },
  {
    url: "/health",
    name: "Health Checkup",
  },
  {
    url: "/feasibility",
    name: "Feasibility Checkup",
  },
];

export const solarSolutionLinks = [
  {
    url: "/on-grid",
    name: "ON-GRID Solar Solutions",
  },
  {
    url: "/off-grid",
    name: "OFF-GRID Solar Solutions",
  },
  {
    url: "/hybrid",
    name: "Hybrid Solar Solutions",
  },
];

export const solarComponentsLinks = [
  { url: "/photovoltaic", name: "Solar Photovoltaic Panel" },
  { url: "/inverter", name: "Solar Inverter" },
  { url: "/solar-structure", name: "Solar Structure" },
  { url: "/earthing", name: "Earthing" },
  { url: "/balance-sheet", name: "Balance of Sheet" },
];

export const earthingLinks = [
  { url: "/chemical-earthing", name: "Chemical Earthing" },
  { url: "/plate-earthing", name: "Plate Earthing" },
  { url: "/rod-chemical-earthing", name: "Rod Chemical Earthing" },
  { url: "/chemical-earthing-grid", name: "Chemical Earthing Grid" },
];

export const gensetLinks = [
  { url: "/diesel-genset", name: "Diesel Genset" },
  { url: "/petrol-genset", name: "Petrol Genset" },
  { url: "/gas-genset", name: "Gas Genset" },
  { url: "/duel-fuel-genset", name: "Duel fuel Genset" },
];

export const lightningLinks = [
  { url: "/conventional", name: "Conventional LA" },
  { url: "/ese", name: "ESE LA" }
]
