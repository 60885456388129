export const socials = [
  {
    name: "Facebook",
    url: "https://www.facebook.com/",
    icon: null,
    image: "/Image/facebook.png",
  },
  {
    name: "Instagram",
    url: "https://www.instagram.com/",
    icon: null,
    image: "/Image/instagram.png",
  },
  {
    name: "LinkedIn",
    url: "https://www.linkedin.com",
    icon: null,
    image: "/Image/linkedin.png",
  },
  {
    name: "Twitter",
    url: "https://www.twitter.com",
    icon: null,
    image: "/Image/twitter00.svg",
    class: "bg-white rounded-full",
  },
  {
    name: "YouTube",
    url: "https://www.youtube.com/",
    icon: null,
    image: "/Image/youtube.png",
  },
];

export const useFullLinks = [
  {
    name: "Home",
    url: "/",
  },
  {
    name: "About Us",
    url: "/about",
  },
  {
    name: "Blogs and News",
    url: "/blogs-and-news",
  },
  {
    name: "Products",
    url: "/on-grid",
  },
  {
    name: "Services",
    url: "/service",
  },
  {
    name: "Sampark",
    url: "/sampark",
  },
  {
    name: "Contact Us",
    url: "/contact",
  },
];

export const companyLinks = [
  {
    name: "Customer Services",
    url: "/"
  },
  {
    name: "Offers",
    url: "/"
  },
  {
    name: "Our Tie-Ups",
    url: "/our-tie-ups"
  },
  {
    name: "Careers",
    url: "/work-with-us"
  }
]
