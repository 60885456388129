import React from "react";
import { BASE_URL } from "../../../../Services/constant";

function CopyGenset({ className,genset }) {
  return (
    <div className={`${className} mt-10 `}>
       {
        genset?.slice(1,3).map(item=>
      <div className="px-10  lg:px-32 xl:px-32 " key={item.id}>
        <div className="py-8 flex flex-col md:flex-row justify-between items-center">
          <div className="md:flex-1">
            <div className=" ">
              <h1 className="text-3xl md:text-4xl mb-4 font-semibold">
              {item?.attributes?.heading}
              </h1>
              <p className="text-lg md:text-xl leading-relaxed break-words" dangerouslySetInnerHTML={{ __html: item?.attributes?.content }}>
              </p>
            </div>
          </div>

          <div className="w-full md:w-80 mb-6  md:mb-0">
            <img
              src={`${BASE_URL}${item?.attributes?.image?.data?.attributes?.url}`}
              alt="Solar Power System"
              className="w-full mx-auto md:mx-0 rounded-3xl "
            />
          </div>
        </div>
      </div>
        )}


      <div className="mt-10  px-5  ">
        <h1 className="text-center font-semibold text-3xl md:text-4xl">
          Certification for Genset
        </h1>
        <div className="flex flex-row flex-wrap justify-center items-center  gap-20  mt-8">
          <div className="flex flex-col items-center">
              <img
                src="Image/R.png"
                alt="1"
                className="w-full md:w-80 lg:w-80 xl:w-80 h-auto md:h-80 lg:h-80 xl:h-80 p-2 rounded-3xl"
                />
          </div>

          <div className="flex flex-col items-center">
              <img
                src="Image/R.png"
                alt="1"
                className="w-full md:w-80 lg:w-80 xl:w-80 h-auto md:h-80 lg:h-80 xl:h-80 p-2 rounded-3xl"
                />
          </div>

          <div className="flex flex-col items-center">
              <img
                src="Image/R.png"
                alt="1"
                className="w-full md:w-80 lg:w-80 xl:w-80 h-auto md:h-80 lg:h-80 xl:h-80 p-2 rounded-3xl"
                />
          </div>
        </div>
      </div>
      <div className="mt-10 px-10  lg:px-32 xl:px-32">
        {
          genset?.slice(3, 1000).map(item =>
            <div className="">
              <h1 className="text-center font-semibold text-3xl md:text-4xl lg:text-4xl xl:text-4xl">
                {item?.attributes?.heading}
              </h1>
              <div className="">
                <div className="mt-8 flex flex-col md:flex-row justify-between items-start md:items-center">
                  <div className="md:flex-1 inline">
                    <div className="">
                      <div className="flex ">
                        <div className="w-9 px-3 py-2 h-9 mr-2 mt-1 bg-orange-600 rounded-full flex items-center justify-center text-white font-medium">
                          <span className="w-3 h-3 px-2 py-2   bg-white rounded-full"></span>
                        </div>
                        <p className="text-lg md:text-xl leading-relaxed ml-3 break-words" dangerouslySetInnerHTML={{ __html: item?.attributes?.content }}>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="w-full md:w-80 mb-6 md:mb-0 mt-3 md:mt-0 lg:mt-0 xl:mt-0 ">
                    <img
                      src={`${BASE_URL}${item?.attributes?.image?.data?.attributes?.url}`}
                      alt="Solar Power System"
                      className="w-full lg:w-60 mx-auto md:mx-0 rounded-3xl md:mr-auto px-2"
                    />
                  </div>
                </div>
              </div>

            </div>
          )
        }
      </div>
    </div>
  );
}

export default CopyGenset;
