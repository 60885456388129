import React from 'react'
import { TwitterTimelineEmbed } from 'react-twitter-embed'

const TwitterTimeline = () => {
  return (
    <div className="ml-4 sm:ml-4 md:ml-4 lg:ml-4 xl:ml-4">
    <p className="text-white text-3xl">News Feed</p>
    <div className="mt-3 mr-5 w-full  sm:w-full md:w-full " style={{width:"110%"}} >
    <section className="twitterContainer">
      <div className="twitter-embed ">
        <TwitterTimelineEmbed
          sourceType="profile"
          screenName="DivyPower"
          options={{
            tweetLimit: "10",
            width: 500,
            height: 300
          }}
          theme="dark"
          noHeader="true"
          noBorders="true"
          noFooter="true"
        >
        </TwitterTimelineEmbed>
    {/* <a class="twitter-timeline" data-width="500" data-height="400" data-theme="dark" href="https://twitter.com/DivyPower?ref_src=twsrc%5Etfw">Tweets by DivyPower</a>  */}
      </div>
    </section>
    </div>
  </div>
  )
}

export default TwitterTimeline
