import { defaultApi } from "./api";
import { BASE_URL } from "./constant";

export async function getContinueLearning() {
  const res = await defaultApi.get(`/api/work-with-uses?populate=*`);
  return res.data.data;
}
export async function getJoinus() {
  const res = await defaultApi.get(`/api/work-with-us-part-twos?populate=*`);
  return res.data.data;
}
export async function getBlogs() {
  const res = await defaultApi.get(`/api/blogs?populate=*`);
  return res.data.data;
}

export async function getNews() {
  const res = await defaultApi.get(`/api/news?populate=*`);
  return res.data.data;
}
export async function getFooter() {
  const res = await defaultApi.get(`/api/footers?populate=*`);
  return res.data.data;
}
