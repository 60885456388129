import React, { useEffect, useState, useRef } from "react";
import { useInView } from "framer-motion";
import BaseLayout from "../../Layout/BaseLayout";
import Testimonial from "./Testimonial";
import "./Home.css";
import "./MediaQuery.css";
import HomeHero from "./Home/HomeHero";
import WhatSolarPower from "./Home/WhatSolarPower";
import WhySolarPower from "./Home/WhySolarPower";
import OurProducts from "./Home/OurProducts";
import LatestProjects from "./Home/LatestProjects";
import Calculator from "./Home/Calculator";
import WhyDivy from "./Home/WhyDivy";
import WhyDivyViewBtn from "./Home/WhyDivyViewBtn";
import { getHomeHero } from "../../Services/HomeService";
import { Spinner } from "react-bootstrap";
import { useQuery } from "@tanstack/react-query";

const AnimatedProgressBar = ({ title, value, maxValue }) => {
  const [animatedWidth, setAnimatedWidth] = useState(0);
  const [animatedValue, setAnimatedValue] = useState(0);
  const titleRef = useRef(null);
  const isInView = useInView(titleRef);

  const handleScroll = () => {
    // Calculate the animated width
    setAnimatedWidth((value / maxValue) * 100);

    // Calculate the animated value (increase gradually)
    const step = Math.ceil(value / (maxValue * 100)); // Adjust the step as needed
    const newValue = Math.min(animatedValue + step, value);
    setAnimatedValue(newValue);
  };

  const handleResize = () => {
    // Recalculate the animated width
    setAnimatedWidth((value / maxValue) * 100);
  };

  useEffect(() => {
    if (isInView) {
      handleScroll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animatedValue, isInView, value, maxValue, title]);

  // useEffect(() => {
  //   window.addEventListener("resize", handleResize);
  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <div ref={titleRef} className="w-full">
      <div className="flex flex-col sm:flex-row justify-between items-center mt-2 mb-4">
        <h2>{title}</h2>

        <span>{animatedValue.toFixed(0)}</span>
      </div>
      <div className="w-full bg-gray-300 rounded-lg overflow-hidden">
        <div
          className="h-1 bg-orange-300 animated-bar"
          style={{ width: `${animatedWidth}%` }}
        ></div>
      </div>
    </div>
  );
};

function Home() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [latestProjects, setLatestProjects] = useState(0);

  const handleNextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % totalSlides);
  };

  const handlePrevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide - 1 + totalSlides) % totalSlides);
  };
  const totalSlides = 4; // Update this with the total number of slides in the slider

  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  // useEffect(() => {
  //   function isInViewport(element) {
  //     const rect = element.getBoundingClientRect();
  //     return (
  //       rect.top >= 0 &&
  //       rect.bottom <=
  //         (window.innerHeight || document.documentElement.clientHeight)
  //     );
  //   }

  //   function handleScrollAnimation() {
  //     const testimonialSection = document.getElementById("testimonialSection");
  //     if (isInViewport(testimonialSection)) {
  //       window.removeEventListener("scroll", handleScrollAnimation);
  //     }
  //   }

  //   window.addEventListener("scroll", handleScrollAnimation);

  //   return () => {
  //     window.removeEventListener("scroll", handleScrollAnimation);
  //   };
  // }, []);
  const { data: homeData, status, error, isFetching } = useQuery({
    queryKey: ["homeData"],
    queryFn: getHomeHero,
  });
  if (status === 'loading') {
    return <span>Loading...</span>
  }

  if (status === 'error') {
    return <span>Error: {error.message}</span>
  }
  return (
    <BaseLayout>
      <HomeHero homeData={homeData} />
      <WhatSolarPower />
      <WhySolarPower />
      <WhyDivy
        AnimatedProgressBar={AnimatedProgressBar}
        toggleShowMore={toggleShowMore}
        showMore={showMore}
      />
      <WhyDivyViewBtn />

      <OurProducts
        handlePrevSlide={handlePrevSlide}
        handleNextSlide={handleNextSlide}
        currentSlide={currentSlide}
        setCurrentSlide={setCurrentSlide}
      />
      <Calculator />
      <LatestProjects
        handlePrevSlide={handlePrevSlide}
        handleNextSlide={handleNextSlide}
        setLatestProjects={setLatestProjects}
        latestProjects={latestProjects}
      />
      <Testimonial />
    </BaseLayout>
  );
}

export default Home;
