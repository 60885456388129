import React from "react";
import cn from "../../Utils/cn";
import { Link } from "react-router-dom";

const EAppliances = ({ className }) => {
  return (
    <div className={cn("mt-40", className)} data-testid="e-appliances">
      <div className="p-4 mb-10 max-w-4xl mx-auto">
        <div className="flex flex-col items-center">
          <div className="flex w-full gap-16">
            <div className="flex justify-between w-full items-center">
              <p>Lights(Bulb)</p>
              <p>
                <button className="btn-plus">+</button>
              </p>
            </div>
            <div className="flex justify-between w-full ml-4">
              <p>Lights(CFL)</p>
              <p>
                <button className="btn-plus">+</button>
              </p>
            </div>
          </div>
          <div className="flex w-full mt-10 gap-16">
            <div className="flex justify-between w-full">
              <p>Lights(LED)</p>
              <p>
                <button className="btn-plus">+</button>
              </p>
            </div>
            <div className="flex justify-between w-full ml-4">
              <p>Tubelights</p>
              <p>
                <button className="btn-plus">+</button>
              </p>
            </div>
          </div>

          <div className="flex w-full mt-10 gap-16">
            <div className="flex justify-between w-full">
              <p>Fans</p>
              <p>
                <button className="btn-plus">+</button>
              </p>
            </div>
            <div className="flex justify-between w-full ml-4">
              <p>TV</p>
              <p>
                <button className="btn-plus">+</button>
              </p>
            </div>
          </div>
          <div className="flex w-full mt-10 gap-16">
            <div className="flex justify-between w-full">
              <p>Kitchen Appliances</p>
              <p>
                <button className="btn-plus">+</button>
              </p>
            </div>
            <div className="flex justify-between w-full ml-4">
              <p>Desktop PCs</p>
              <p>
                <button className="btn-plus">+</button>
              </p>
            </div>
          </div>
          <div className="flex w-full mt-10 gap-16">
            <div className="flex justify-between w-full">
              <p>Laptops</p>
              <p>
                <button className="btn-plus">+</button>
              </p>
            </div>
            <div className="flex justify-between w-full ml-4">
              <p>Servers</p>
              <p>
                <button className="btn-plus">+</button>
              </p>
            </div>
          </div>
          <div className="flex w-full mt-10 gap-16">
            <div className="flex justify-between w-full">
              <p>IT peripherals</p>
              <p>
                <button className="btn-plus">+</button>
              </p>
            </div>
            <div className="flex justify-between w-full ml-4">
              <p>Printers/Fax/copiers</p>
              <p>
                <button className="btn-plus">+</button>
              </p>
            </div>
          </div>
        </div>
        <div className="mt-20 flex flex-wrap w-full gap-10 sm:gap-10 md:gap-10 lg:gap-2 xl:gap-20">
          <div className="">
            <label>
              Please enter wattage of any other appliances you wish to run
            </label>
            <div className="flex gap-20 mt-3">
              <input
                defaultValue="0"
                className="mt-1 my-input p-1"
                style={{ border: "1px solid grey", borderRadius: "5px" }}
              />
              <select
                style={{
                  border: "1px solid grey",
                  borderRadius: "5px",
                  width: "20%",
                }}
              >
                <option>Watts</option>
              </select>
            </div>
          </div>
          <div className="">
            <label>Extra power for future expansion</label>
            <div className="flex gap-20 mt-3">
              <select
                style={{
                  border: "1px solid grey",
                  borderRadius: "5px",
                  width: "30%",
                }}
                className="p-1"
              >
                <option>0%</option>
              </select>
            </div>
          </div>
        </div>
        <div className="flex justify-center mt-10">
          <Link to="/calculator">
            <button className="calcution-btn  bg-[#FFC680] rounded-2xl  text-black font-semibold  text-lg py-2.5 px-8">
              {" "}
              Calculate my savings
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default EAppliances;
