import React, { useState } from 'react'
import  TieUp  from "../../Tieups/TieUp"

const WhyDivyViewBtn = () => {
  const [showMore, setShowMore] = useState(false);
  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  return (
    <div>
       {showMore && (
                <div className="mt-10">
                 <TieUp />
                </div>
              )}
              <div className="mt-10 items-center justify-center flex">
                <button
                  className="bg-orange-600 text-white rounded-full py-3 px-8 view-more-font"
                  onClick={toggleShowMore}
                >
                  {showMore ? "View Less" : "View More"}
                </button>
              </div>
    </div>
  )
}

export default WhyDivyViewBtn
