import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Joinus from "../../JoinNow/Joinus";


function JoinUsPopup() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <div className="mt-5 flex justify-center mr-12">
                <button
                    className="text-4xl text-white bg-orange-500 rounded-2xl py-4 px-10 items-center"
                    onClick={handleShow}
                >
                    Join us now!
                </button>
            </div>
            {
                show &&

                <div id="defaultModal" tabindex="-1" aria-hidden="true" className="p-0 md:p-20 lg:p-20 xl:p-20 fixed top-0 z-50 w-full p-4 overflow-x-hidden  md:inset-0 h-[calc(100%-1rem)] max-h-full " style={{backgroundColor: "rgb(0 0 0 / 57%)"}}>
                    <div className=" bg-white w-full mx-auto p-4 rounded-lg shadow-lg   overflow-y-auto">
                        <div className="flex justify-end ">
                            <button
                                className="text-gray-500 hover:text-gray-700"
                                onClick={handleClose}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            </button>
                        </div>

                        <div className="">
                            <Joinus />
                        </div>
                    </div>
                </div>
            }

        </>
    );
}

export default JoinUsPopup;