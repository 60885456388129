import { defaultApi } from "./api";
import { BASE_URL } from "./constant";

export async function getOngrid() {
  const res = await defaultApi.get(`/api/product-ongrids?populate=*`);
  return res.data.data;
}
export async function getHybrid() {
  const res = await defaultApi.get(`/api/product-hybrids?populate=*`);
  return res.data.data;
}
export async function getChemEarthing() {
  const res = await defaultApi.get(`/api/chemical-earthings?populate=*`);
  return res.data.data;
}
export async function getRodChemEarthing() {
  const res = await defaultApi.get(`/api/rod-chemical-earthings?populate=*`);
  return res.data.data;
}
export async function getPlateEarthing() {
  const res = await defaultApi.get(`/api/plate-earthings?populate=*`);
  return res.data.data;
}
export async function getChemEarthingGrid() {
  const res = await defaultApi.get(`/api/chemical-earthing-grids?populate=*`);
  return res.data.data;
}
export async function getOffgrid() {
  const res = await defaultApi.get(`/api/product-offgrids?populate=*`);
  return res.data.data;
}
export async function getBalanceSheets() {
  const res = await defaultApi.get(`/api/product-balance-of-sheets?populate=*`);
  return res.data.data;
}
export async function getPhotovoltic() {
  const res = await defaultApi.get(`/api/solar-photovoltaics?populate=*`);
  return res.data.data;
}
export async function getDieselGensets() {
  const res = await defaultApi.get(`/api/diesel-gensets?populate=*`);
  return res.data.data;
}
export async function getPetrolGensets() {
  const res = await defaultApi.get(`/api/petrol-gensets?populate=*`);
  return res.data.data;
}
export async function getGasGensets() {
  const res = await defaultApi.get(`/api/gas-gensets?populate=*`);
  return res.data.data;
}
export async function getFuelGensets() {
  const res = await defaultApi.get(`/api/duel-fuel-gensets?populate=*`);
  return res.data.data;
}
export async function getConventional() {
  const res = await defaultApi.get(`/api/conventional-las?populate=*`);
  return res.data.data;
}
export async function getEsela() {
  const res = await defaultApi.get(`/api/ese-las?populate=*`);
  return res.data.data;
}
export async function getSolarInvertors() {
  const res = await defaultApi.get(`/api/solar-invertors?populate=*`);
  return res.data.data;
}
export async function getSolarStructures() {
  const res = await defaultApi.get(`/api/solar-structures?populate=*`);
  return res.data.data;
}
export async function getEarthings() {
  const res = await defaultApi.get(`/api/earthings?populate=*`);
  return res.data.data;
}
export async function getSolarSolution() {
  const res = await defaultApi.get(`/api/solar-solution-differences`);
  return res.data.data;
}
export async function getSubsidy() {
  const res = await defaultApi.get(`/api/subsidies`);
  return res.data.data;
} 
export async function getSafetyGuidelines() {
  const res = await defaultApi.get(`/api/safety-guidelines`);
  return res.data.data;
} 
export async function getphotovolticDifferences() {
  const res = await defaultApi.get(`/api/solar-photovoltaic-differences`);
  return res.data.data;
} 