import React, { useEffect, useState } from "react";
import "./Dashboard.css";
import { Link } from "react-router-dom";
import BaseLayout from "../../Layout/BaseLayout";
import { getDashboard, getPdfs } from "../../Services/SamparkService";
import { useQuery } from "@tanstack/react-query";
import { BASE_URL } from "../../Services/constant";
import Breadcrumb from "../../Components/navbar/Breadcrumb";

const EmpDashboard = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabClick = (index) => {
    setSelectedTab(index === selectedTab ? null : index);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const [openAccordionIndex, setOpenAccordionIndex] = useState(-1);

  const toggleAccordion = (index) => {
    setOpenAccordionIndex((prevIndex) => (prevIndex === index ? -1 : index));
  };
  const accordions = [
    { title: 'Period leave', content: 'Content for Period leave' },
    { title: 'Health and Fitness', content: 'Content for Health and Fitness' },
    { title: 'No Probation and Notice Periods', content: 'Content for No Probation and Notice Periods' },
    { title: 'Medical Coverage', content: 'Content for Medical Coverage' },
    { title: 'Paternity and Maternity Leave', content: 'Content for Paternity and Maternity Leave' },
    { title: 'In-house Wellness Support', content: 'Content for In-house Wellness Support' },
  ];

  const [userInfo, setUserInfo] = useState({});

  useEffect(() => {
    getUserInfo();
  }, []);
  async function getUserInfo() {
    // const t = JSON.parse(localStorage.getItem('token'))
    const userData = JSON.parse(localStorage.getItem('userData'))
    setUserInfo(userData);
  }
  // console.log(userInfo);

  const { data: dashboard, status, error } = useQuery({
    queryKey: ["dashboard"],
    queryFn: getDashboard,
  });
  const { data: pdfs } = useQuery({
    queryKey: ["pdfs"],
    queryFn: getPdfs,
  });
  if (status === 'loading') {
    return <span>Loading...</span>
  }

  if (status === 'error') {
    return <span>Error: {error.message}</span>
  }

  return (
    <BaseLayout>
      <Breadcrumb subLink="Sampark" subLinkOne="Employee Dashboard" />
      <div className="bg ">
        <div className="flex items-center ">
          <img
            src="/Image/femaleAvatar.avif"
            alt="beanie"
            className="ml-5 sm:ml-20 md:ml-20 lg:ml-20 xl:ml-20 rounded-full w-16 md:w-24 lg:w-24 xl:w-24 mr-5"
          />
         <div className="flex justify-between w-full">
         <div style={{ lineHeight: "30px" }}>
            <strong className="text-2xl">{userInfo && userInfo?.user?.username}</strong>
            <p>{userInfo && userInfo?.user?.designation}</p>
            <p>Login Id:{userInfo && userInfo?.user?.id}</p>
          </div>
        </div>
        <div>
    {/* <button className="bg-white rounded px-5 py-2" onClick={logOut}>Logout</button> */}
  </div>
         </div>
        <div></div>
        <div className="flex flex-wrap justify-between px-10 md:px-20 lg:px-20 xl:px-20 mt-20 gap-10" >
          {
            dashboard?.slice(0, 4).map(item =>
              <div className=" flex white-box-section text-3xl space-y-4 px-24 py-24" key={item?.id}>
                <strong >{item?.attributes?.heading}</strong>
                <strong dangerouslySetInnerHTML={{
                  __html: item?.attributes?.content,
                }}></strong>
              </div>
            )
          }
        </div>
 
      </div>

      <div className="px-10">
        <div className="">
          <h1 className="text-4xl  text-center mb-10 mt-10 font-bold">
            Upcoming events
          </h1>
          {
            dashboard?.slice(4, 6).map(item =>
              <div className="flex justify-center items-center gap-5 md:gap-10 lg:gap-10 xl:gap-10 text-3xl mt-5" key={item?.id}>
                <strong>{item?.attributes?.heading}</strong>
                <div className="flex flex-col" style={{ width: "80%" }}>
                  <input
                    type="text"
                    name="firstName"
                    id="firstName"
                    className="mt-1 outline-none my-input focus:ring-orange-500 focus:border-orange-500  border-b-2 w-full  border-gray-500"
                    style={{ borderColor: "grey" }}
                  />
                  <input
                    type="text"
                    name="firstName"
                    id="firstName"
                    className="mt-1 outline-none my-input focus:ring-orange-500 focus:border-orange-500  border-b-2 w-full  border-gray-500"
                    style={{ borderColor: "grey" }}
                  />
                </div>
              </div>
            )}
          <div className="flex justify-center mt-14 mb-14 ">
            <button className="form-btn  py-2 md:py-4 lg:py-4 xl:py-4 px-10 md:px-24 lg:px-24 xl:px-24  rounded-full  ">
              {" "}
              <Link to="/grievance-form">Grievance Form</Link>
            </button>
          </div>
        </div>

        <div className="ml-0 md:ml-60 lg:ml-60 xl:ml-60 mr-0 md:mr-60 lg:mr-60 xl:mr-60 mt-10">
          <h1 className="font-semibold text-3xl text-center mt-28">FAQs</h1>
          {dashboard?.slice(6, 100).map((accordion, index) => (
            <div key={index} className="shadow-2xl py-6  space-y-4  rounded-xl mb-8 ">
              <div
                className="flex justify-between py-4 px-4 text-center items-center cursor-pointer"
                onClick={() => toggleAccordion(index)}
              >
                <h1 className="text-orange-600 text-2xl font-semibold">{accordion?.attributes?.heading}</h1>
                <img
                  src="/Image/dropdown.svg"
                  className={`w-10 h-10  transform ${openAccordionIndex === index ? 'rotate-180' : ''}`}
                  alt="Dropdown"
                />
              </div>
              {openAccordionIndex === index && (
                <div className="px-4 pb-4">
                  <p dangerouslySetInnerHTML={{
                    __html: accordion?.attributes?.content,
                  }}></p>
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="px-0 lg:px-20 xl:px-20 mb-24 overflow-x-auto">
          <table className="w-full text-3xl">
            <thead>
              <tr>
                {
                  pdfs?.map(item => {
                    return item?.attributes?.pdf?.data?.map(pdf =>
                      <th
                      key={pdf?.id}
                        className={`w-1/4 p-14 text-center ${selectedTab === 0 ? 'font-semibold' : 'font-light'}`}
                        style={{ border: "2px solid grey" }}
                      >
                        <a href={`${BASE_URL}${pdf?.attributes?.url}`} download={pdf?.downloadable ? pdf?.attributes?.name : null  } target='_blank' >
                        {/* <a href="/Image/emp.pdf" download target='_blank' > */} 
                          <button >{pdf?.attributes?.name}</button>
                        </a>
                      </th>
                    )
                  }
                  )
                } 
              </tr>
            </thead>
            <tbody></tbody>
          </table>
        </div>
      </div>
    </BaseLayout>
  );
};

export default EmpDashboard;
