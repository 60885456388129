/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from "react";
import { MapPin } from "lucide-react";
import "./OffGrid.css";
import ElectricityBill from "../../../ElectricityBill";
import EAppliances from "../../../EAppliances";
import BaseLayout from "../../../../../Layout/BaseLayout";
import { getOffgrid } from "../../../../../Services/ProductServices";
import { useQuery } from "@tanstack/react-query";
import { BASE_URL } from "../../../../../Services/constant";
import SolarSolutionTable from "../../SolarSolutionTable";
import Breadcrumb from "../../../../navbar/Breadcrumb";
import Calculator from "../../../Home/Calculator";
import ComponentsOfSolarSolution from "../../ComponentsOfSolarSolution";

function OffGrid() {
  const [show, setShow] = useState(null);
  const {
    data: offgridData,
    status,
    error,
  } = useQuery({
    queryKey: ["offgridData"],
    queryFn: getOffgrid,
  });
  if (status === "loading") {
    return <span>Loading...</span>;
  }

  if (status === "error") {
    return <span>Error: {error.message}</span>;
  }
  return (
    <BaseLayout>
      <Breadcrumb subLink="Products" subLinkOne="Off-Grid Solar Solution" />
      <div
        style={{
          backgroundImage: "url(/Image/offgrid.svg)",
          backgroundPosition: "top 0 center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
        }}
        className="bg-layer pb-40 mb-10"
      >
        {offgridData?.map((item) => (
          <div
            className="relative px-10 sm:px-10 md:px-20 lg:px-20 xl:px-20"
            style={{ zIndex: 2 }}
          >
            <div className="">
              <div className="flex justify-start md:justify-center lg:justify-center xl:justify-center  ">
                {/* <div></div> */}
                {/* <img src="Image/solar.svg" className="w-40" /> */}
                <h1
                  className=" text-4xl mt-24 font-bold"
                  style={{ color: "#000000" }}
                >
                  {item?.attributes?.heading_one}
                </h1>
                {/* <div></div> */}
                {/* <img src="Image/Battery.svg" className="w-40" /> */}
              </div>
            </div>
            <div
              className="text-start sm:text-start md:text-center lg:text-center xl:text-center text-xl break-words"
              style={{
                color: "#000000",
                fontFamily: "normal normal normal Poppins;",
              }}
            >
              <p
                className="mt-5 sm:mt-5 md:mt-10 lg:mt-10 xl:mt-10"
                dangerouslySetInnerHTML={{
                  __html: item?.attributes?.content_one,
                }}
              ></p>
            </div>

            <h1 className="text-start  md:text-center lg:text-center xl:text-center  font-bold text-4xl mt-24 text-black">
              {item?.attributes?.heading_two}
            </h1>
            <div className="mt-5 sm:mt-3 md:mt-10 lg:mt-10 xl:mt-10 flex items-center">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
                <div className="flex items-center">
                  <p
                    className="text-xl text-start sm:text-start md:text-center lg:text-start xl:text-start break-words"
                    style={{
                      fontFamily: "normal normal normal 35px/53px Poppins;",
                    }}
                    dangerouslySetInnerHTML={{
                      __html: item?.attributes?.content_two,
                    }}
                  ></p>
                </div>
                <div className="mt-5">
                  <img
                    src={`${BASE_URL}${item?.attributes?.image.data.attributes.url}`}
                    alt="Solar Power System"
                    className="w-full  md:h-auto mx-auto md:mx-0 md:ml-auto px-3"
                  />
                </div>
              </div>
            </div>

            <div className="mt-24">
            <ComponentsOfSolarSolution/>
              <SolarSolutionTable />
              <Calculator/>
            </div>
          </div>
        ))}
      </div>
    </BaseLayout>
  );
}

export default OffGrid;
