import React from 'react'
import { getphotovolticDifferences } from '../../../../Services/ProductServices';
import { useQuery } from '@tanstack/react-query';

const DifferenceTable = () => {
    const { data: differences, status, error } = useQuery({
        queryKey: ["differences"],
        queryFn: getphotovolticDifferences,
      });
      console.log(differences);
      if (status === 'loading') {
        return <span>Loading...</span>
      }
    
      if (status === 'error') {
        return <span>Error: {error.message}</span>
      }
  return (
    <div className='mt-10'>
    <h1 className='text-3xl font-bold text-center'>Difference</h1>
    <div className='overflow-x-auto'>
      <table className='border-collapse border border-black mt-5 w-full overflow-x-auto'>
        <thead>
          <tr>

            <th className='border border-black p-3 font-semibold text-2xl'></th>
            <th className='border border-black p-3 text-2xl font-medium'>Mono Crystalline Solar Panel</th>
            <th className='border border-black p-3 text-2xl font-medium' >Poly Crystalline Solar Panel</th>
            <th className='border border-black p-3 text-2xl font-medium'>Thin Film Solar Panel </th>
            <th className='border border-black p-3 text-2xl font-medium'>Bifacial Solar Panel</th>
          </tr>
        </thead>
        <tbody>
        {
            differences?.map(item=>
          <tr key={item.id}>
                
                <td className='border border-black p-3 py-5 text-bold text-3xl'  dangerouslySetInnerHTML={{
                  __html: item?.attributes?.basis }}></td>
            <td className='border border-black p-3 py-8' dangerouslySetInnerHTML={{
                  __html: item?.attributes?.bifacial }}></td>
                  <td className='border border-black p-3 py-8' dangerouslySetInnerHTML={{
                  __html: item?.attributes?.monoCrystaline }}></td>
                  <td className='border border-black p-3 py-8' dangerouslySetInnerHTML={{
                  __html: item?.attributes?.polyCrystalline }}></td>
                  <td className='border border-black p-3 py-8' dangerouslySetInnerHTML={{
                  __html: item?.attributes?.thinFilm }}></td>
          </tr>
                )
        }
          {/* <tr>
            <td className='border border-black p-3 py-8'></td>
            <td className='border border-black p-3 py-8'></td>
            <td className='border border-black p-3 py-8'></td>
            <td className='border border-black p-3 py-8'></td>
            <td className='border border-black p-3 py-8'></td>

          </tr>
          <tr>
            <td className='border border-black p-3 py-8'></td>
            <td className='border border-black p-3 py-8'></td>
            <td className='border border-black p-3 py-8'></td>
            <td className='border border-black p-3 py-8'></td>
            <td className='border border-black p-3 py-8'></td>

          </tr>
          <tr>
            <td className='border border-black p-3 py-8'></td>
            <td className='border border-black p-3 py-8'></td>
            <td className='border border-black p-3 py-8'></td>
            <td className='border border-black p-3 py-8'></td>
            <td className='border border-black p-3 py-8'></td>

          </tr> */}
        </tbody>
      </table>
    </div>
  </div>
  )
}

export default DifferenceTable
