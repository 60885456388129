import React, { useEffect } from "react";
import "./Footer.css";
import { socials, useFullLinks, companyLinks } from "./constants";
import { Link } from "react-router-dom";
import cn from "../../../Utils/cn";
import TwitterTimeline from "./TwitterTimeline";
import { getFooter } from "../../../Services/FooterService";
import { useQuery } from "@tanstack/react-query";

function Footer() {
  const { data: footer, status, error } = useQuery({
    queryKey: ["footer"],
    queryFn: getFooter,
  });
  console.log(footer);
 
  if (status === 'loading') {
    return <span>Loading...</span>
  }

  if (status === 'error') {
    return <span>Error: {error.message}</span>
  }
  return (
    <footer className="bg-black">
      <div className="container mx-auto">
        <div className="">
          <div className="flex space-x-4 justify-center">
            {socials.map((social) => (
              <a
                key={`${social.name}-links`}
                href={social.url}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={social.image}
                  alt="Facebook"
                  className={cn("w-10 h-10 mt-10", social.class)}
                />
              </a>
            ))}
          </div>

          <hr className="mt-10 mx-32 border-gray-400" />

          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 justify-center mt-24 mr-10">
            {/* First Column */}
            {
              footer?.map(item=>
                <div className="ml-4 space-y-4" key={item?.id}>
                <p className="text-white text-3xl ">{item?.attributes?.heading}</p>
                <p className="text-white mt-2 text-start text-align-style"  dangerouslySetInnerHTML={{
                        __html: item?.attributes?.content
                      }}>
                 
                </p>
                <a
                  href={item?.attributes?.map}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="/Image/location.png"
                    alt="map"
                    className="w-full mt-4"
                    style={{height: '165px'}}
                  />
                </a>
              </div>
                )
            }
           

            {/* Second Column */}
            <div className="space-y-4 ml-4 sm:ml-24">
              <p className="text-white text-3xl">Useful Links</p>
              <ul className="text-white mt-2 space-y-4 ">
                {useFullLinks.map((useFullLink) => (
                  <li key={`${useFullLink.name}-useFullLink`}>
                    <Link
                      to={useFullLink.url}
                      className="hover:text-orange-500"
                    >
                      {useFullLink.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            {/* Third Column */}
            <div className=" space-y-4 ml-4 sm:ml-4 md:ml-4 lg:ml-4 xl:ml-4 ">
              <p className="text-white text-3xl">Company</p>
              <ul className="text-white mt-2 space-y-4">
                {companyLinks.map((companyLink) => (
                  <li key={`${companyLink.name}-companyLink`}>
                    <Link
                      to={companyLink.url}
                      className="hover:text-orange-500"
                    >
                      {companyLink.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>

            {/* Fourth Column */}
           <TwitterTimeline/>
          </div>

          <div className="flex justify-between text-white mt-5 mr-24 ">
            <p className="ml-4 sm:ml-24 text-white hover:text-orange-500">
              <Link to="/terms-and-conditions">
                © 2023 Construe. All rights reserved 
              </Link>
            </p>

            <div className="flex justify-between mb-10 ">
              <Link
                to="privacy-policy.html"
                className="mr-10 hover:text-orange-500"
              >
                Privacy Policy
              </Link>
              <Link to="terms-and-conditions" className="hover:text-orange-500">
                Terms & Conditions
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
export default Footer;
