import React, { useState } from "react";
import { Link } from "react-router-dom";
import BaseLayout from "../../../../Layout/BaseLayout";
import { getSafetyGuidelines, getSolarStructures } from "../../../../Services/ProductServices";
import { useQuery } from "@tanstack/react-query";
import Breadcrumb from "../../../navbar/Breadcrumb";
import styles from './Solarstructure.module.css'

function SolarStructure() {
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [activeRoof,setActiveRoof]=useState(0)
  const [selectedTab, setSelectedTab] = useState(0);
const roofTabData=[
  {
    id:1,
    heading:"RCC"
  },
 {
  id:2,
  heading:"TIN"
 }
]
  const { data: solarStructures, status, error } = useQuery({
    queryKey: ["solarStructures"],
    queryFn: getSolarStructures,
  });
  const { data: safetyGuidelines } = useQuery({
    queryKey: ["safetyGuidelines"],
    queryFn: getSafetyGuidelines,
  });
  console.log(safetyGuidelines);
  if (status === 'loading') {
    return <span>Loading...</span>
  }

  if (status === 'error') {
    return <span>Error: {error.message}</span>
  }
  const toggleDropdown = (dropdown) => {
    if (activeDropdown === dropdown) {
      setActiveDropdown(null);
    } else {
      setActiveDropdown(dropdown);
    }
  };

  const handleTabClick = (index) => {
    setSelectedTab(index === selectedTab ? null : index);
  };
  // const handleRoofTabClick = (index) => {
  //   setActiveRoof(index === activeRoof ? null : index);
  // };
  return (
    <BaseLayout>
      <Breadcrumb subLink="Products" subLinkOne="Solar Structure" />
      <div className="px-10 sm:px-10 md:px-10 lg:px-0 xl:px-0">
        <div className="mt-10">
          <h1 className="text-4xl text-center font-bold">Types of Platform</h1>
          <div className="flex justify-center px-0 sm:px-0 md:px-20 lg:px-20 xl:px-20">
            <table className="w-full  mt-4">
              <tbody>
                <tr>
                  <td
                    className={`w-1/2   border border-black text-3xl md:text-4xl font-medium text-center ${activeDropdown === 'roof' ? 'text-black p-0' : 'text-gray-500 p-10'
                      }`}
                  >
                    <button onClick={() => toggleDropdown('roof')}>Roof</button>
                    {activeDropdown === 'roof' && (
                      <div className="">
                        <hr className={`w-full border-black  mt-8  `} />
                        <div className="flex px-5 text-2xl">
                          {roofTabData.map((item,index)=>
                          <div onClick={() => handleTabClick(index)} className={`dropdown-content w-1/2 flex justify-start p-5 font-normal  border-black ${styles.border_remove}  ${selectedTab === index ? 'text-black' : 'text-gray-500'}`} >
                           {item.heading}
                          </div>
                          
                          )}
                        </div>
                      </div>
                    )}
                  </td>
                  <td
                    className={`w-1/2  m-10 border border-black text-3xl md:text-4xl font-medium  text-center ${activeDropdown === 'ground' ? 'text-black p-0' : 'text-gray-500 p-10'
                      }`}
                  >
                    <button onClick={() => toggleDropdown('ground')}>Ground</button>
                    {activeDropdown === 'ground' && (
                      <div >
                        <hr className={`w-full border-black  mt-8 `} />
                        <div className="flex px-5  text-2xl">
                          {
                            solarStructures?.map((item, index) =>
                              <div key={item.id}  onClick={() => handleTabClick(index)} className={` p-5 border-black dropdown-content w-1/2 flex justify-start  font-normal ${styles.border_remove} ${selectedTab === index ? 'text-black' : 'text-gray-500'}`} >
                                {item?.attributes?.heading}
                              </div>
                            )}
                        </div>
                      </div>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="mt-24">
          <h1 className="text-4xl font-bold text-center">Types of Structure</h1>
          <div className="mt-10">
            {
              solarStructures?.map(item =>
                <div
                  className="mt-20 px-0 sm:px-20 md:px-20 lg:px-20 xl:px-20 relative"
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    letterSpacing: "0px",
                    color: "#000000",
                    opacity: "1",
                  }}
                >
                  <ul className="list-none p-0 m-0">
                    <li className="flex items-center mb-4">
                      <div className="w-8 h-8 bg-orange-600 rounded-full flex items-center justify-center text-white font-medium ">
                        <span className="w-3 h-3 bg-white rounded-full flex items-center justify-center">
                          &bull;
                        </span>
                      </div>
                      <h1 className="text-lg font-semibold pl-3">{item?.attributes?.heading}</h1>
                    </li>
                  </ul>
                  <p className="mt-4 ml-11 break-words" dangerouslySetInnerHTML={{ __html: item?.attributes?.content }}>
                  </p>
                </div>
              )
            }
          </div>
        </div>
        <div className="mt-24 mb-20 px-10">
          <h1 className="text-center font-bold text-4xl">
            3-S Guidelines for <br /> safety
          </h1>
          <div className="mt-10">
            <div className="mt-10 overflow-x-auto">
              <table className="mx-auto border-collapse">
                <thead>
                  <tr>
                   {
                    safetyGuidelines?.map(item=>
                      <th className="text-center px-3 py-4 border-b-2   border-black  border-x-2">
                      <span className="text-3xl font-medium">
                        {item?.attributes?.heading}
                      </span>
                    </th>
                      )
                   }
                   
                  </tr>
                </thead>
                <tbody>
                  <tr>
                  {
                    safetyGuidelines?.map(item=>
                    <td className="text-center px-6 py-4  h-96 border-black  border-x-2"  dangerouslySetInnerHTML={{
                        __html: item?.attributes?.content
                      }}>
                       </td>
                    )}
                   
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </BaseLayout>
  );
}

export default SolarStructure;
