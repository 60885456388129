import React, { useState } from "react";
import { MapPin } from "lucide-react";
import ElectricityBill from "../../ElectricityBill";
import EAppliances from "../../EAppliances";
import BaseLayout from "../../../../Layout/BaseLayout";
import { useQuery } from "@tanstack/react-query";
import { getOngrid } from "../../../../Services/ProductServices";
import { BASE_URL } from "../../../../Services/constant";
import SolarSolutionTable from "../SolarSolutionTable";
import Breadcrumb from "../../../navbar/Breadcrumb";
import Calculator from "../../Home/Calculator";
import ComponentsOfSolarSolution from "../ComponentsOfSolarSolution";
import SubSidyTable from "./SubSidyTable";

function OnGrid() {
  const [show, setShow] = useState(null);

  const {
    data: ongridData,
    status,
    error,
  } = useQuery({
    queryKey: ["ongridData"],
    queryFn: getOngrid,
  });
  if (status === "loading") {
    return <span>Loading...</span>;
  }

  if (status === "error") {
    return <span>Error: {error.message}</span>;
  }
  return (
    <BaseLayout>
      <Breadcrumb subLink="Products" subLinkOne="On-Grid Solar Solution" />

      <div
        style={{
          backgroundImage: "url(/Image/ongrid.svg)",
          backgroundPosition: "top 0 center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
        }}
        className="bg-layer"
      >
        {ongridData?.map((item) => (
          <div
            className="relative px-10 sm:px-10 md:px-20 lg:px-20 xl:px-20"
            style={{ zIndex: 2 }}
            key={item.id}
          >
            <div className="">
              <div className="flex justify-start md:justify-center lg:justify-center xl:justify-center ">
                {/* <div className="w-40 ml-24  "></div> */}
                {/* <img src="Image/solar.sv" className="w-40 ml-24  " /> */}
                <h1
                  className="text-4xl  mt-24 font-bold"
                //   style={{color: "#000000"}}
                >
                  {item?.attributes?.heading_one}
                </h1>
                {/* <div className="w-40 ml-24  "></div> */}
                {/* <img src="/Image/Gird.sv" className="w-40 mr-24 " /> */}
              </div>
              <div
                className="text-start md:text-center lg:text-center xl:text-center text-xl break-words"
                style={{
                  color: "#000000",
                  fontFamily: "normal normal normal Poppins;",
                }}
              >
                <p
                  className="mt-5 sm:mt-5 md:mt-10 lg:mt-10 xl:mt-10 "
                  dangerouslySetInnerHTML={{
                    __html: item?.attributes?.heading_one_content,
                  }}
                ></p>
              </div>
            </div>

            <div className="">
              <h1 className="text-start md:text-center lg:text-center xl:text-center  font-bold text-4xl mt-24 text-black">
                {item?.attributes?.heading_two}
              </h1>
              <div className="mt-5 sm:mt-3 md:mt-10 lg:mt-10 xl:mt-10 flex items-center">
                <div className="grid grid-cols-1 md:grid-cols-2 items-center">
                  <div className="flex items-center">
                    <p
                      className="text-xl text-start md:text-center lg:text-start xl:text-start break-words"
                      style={{
                        fontFamily: "normal normal normal 35px/53px Poppins;",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: item?.attributes?.heading_two_content,
                      }}
                    ></p>
                  </div>

                  <div className="mt-5">
                    <img
                      src={`${BASE_URL}${item?.attributes?.heading_two_image.data.attributes.url}`}
                      alt="Solar Power System"
                      className="w-full 0  md:h-auto mx-auto md:mx-0 md:ml-auto px-3"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-24">
              <ComponentsOfSolarSolution />
              <div className="grid min-[760px]:gap-10 max-[790px]:gap-10 grid-cols-1  md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3 gap-4 mt-8">
              <div className="flex flex-col items-center">
                    <div className=" relative w-54 sm:w-54 md:w-80 lg:w-80 xl:w-80 2xl:w-80 ">
                      <img
                        src="Assets/Net-Metering1-1.jpg"
                        alt="1"
                        className="w-full  h-56 p-2 hover:opacity-80 "
                      />
                      <div className="absolute top-0 left-0 w-full h-full flex flex-col items-center justify-center text-center p-2 bg-black opacity-60">
                        <p className="text-white text-2xl">
                          {item?.attributes?.image_one_heading}
                        </p>
                      </div>
                    </div>
                    <p
                      className="text-center mt-5 text-md w-4/5 break-words"
                      style={{
                        fontFamily: "normal normal normal 35px/53px Poppins",
                        color: "#000000",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: item?.attributes?.image_one_content,
                      }}
                    ></p>
                  </div>
                  <div className="flex flex-col  items-center">
                  <div className=" relative w-54 sm:w-54 md:w-80 lg:w-80 xl:w-80 2xl:w-80 ">
                      <img
                        src="Assets/net zero.png"
                        alt="1"
                        className="w-full  h-56 p-2 hover:opacity-80"
                      />
                      <div className="absolute top-0 left-0 w-full h-full flex flex-col items-center justify-center text-center p-2 bg-black opacity-60">
                        <p className="text-white text-2xl">
                          {item?.attributes?.image_two_heading}
                        </p>
                      </div>
                    </div>
                    <p
                      className="text-center mt-6 text-md w-4/5 break-words"
                      style={{
                        fontFamily: "normal normal normal 35px/53px Poppins",
                        color: "#000000",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: item?.attributes?.image_two_content,
                      }}
                    ></p>
                  </div>
                  <div className="flex flex-col  items-center">
                  <div className=" relative w-54 sm:w-54 md:w-80 lg:w-80 xl:w-80 2xl:w-80 ">
                      <img
                        src="Assets/Net-Metering1-1.jpg"
                        alt="1"
                        className="w-full  h-56 p-2 hover:opacity-80"
                      />
                      <div className="absolute top-0 left-0 w-full h-full flex flex-col items-center justify-center text-center p-2 bg-black opacity-60">
                        <p className="text-white text-2xl">
                          {item?.attributes?.image_three_heading}
                        </p>
                      </div>
                    </div>
                    <p
                      className="text-center mt-6 text-md w-4/5 break-words"
                      style={{
                        fontFamily: "normal normal normal 35px/53px Poppins",
                        color: "#000000",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: item?.attributes?.image_three_content,
                      }}
                    ></p>
                  </div>

              </div>
              <SolarSolutionTable />

              <Calculator />

              <SubSidyTable />

              <div className="mt-10 flex justify-center  mr-20 ">
                {/* <img src="Image/Bulb.svg" /> */}
                <div className="h-32"></div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </BaseLayout>
  );
}

export default OnGrid;
