import React from "react";
import { Link } from "react-router-dom";
import BaseLayout from "../../../../Layout/BaseLayout";
import Report from "./Report";
import { getEarthings } from "../../../../Services/ProductServices";
import { useQuery } from "@tanstack/react-query";
import { BASE_URL } from "../../../../Services/constant";
import Breadcrumb from "../../../navbar/Breadcrumb";

function Earthing() {
  const { data: earthings, status, error } = useQuery({
    queryKey: ["earthings"],
    queryFn: getEarthings,
  });
  if (status === 'loading') {
    return <span>Loading...</span>
  }

  if (status === 'error') {
    return <span>Error: {error.message}</span>
  }
  return (
    <BaseLayout>
             <Breadcrumb subLink="Products" subLinkOne="Earthing"/>
             <div className="px-10 lg:px-32 xl:px-32">
        {
          earthings?.slice(0, 1).map(item =>
            <div className="mt-10 mx-auto max-w-full" key={item.id}>
                <h1 className="text-4xl font-bold mb-5 ">{item?.attributes?.heading}</h1>
            <div className="flex flex-col sm:flex-col md:flex-row lg:flex-row xl:flex-row justify-between items-center gap-10  ">
              <div className=" " >
                <p
                  className="text-lg break-words"
                  style={{ fontFamily: "normal normal normal 35px/53px Poppins;" }}
                  dangerouslySetInnerHTML={{ __html: item?.attributes?.content }}
                >
                </p>
              </div>
              <div className="w-full  ">
                <img
                  src={`${BASE_URL}${item?.attributes?.image.data.attributes.url}`}
                  alt="Solar Power System"
                  className="w-full lg:w-3/5 xl:w-3/5 h-auto rounded-2xl  md:h-auto mx-auto md:mx-0 md:ml-auto  "
                />
              </div>
            </div>
            </div>
          )
        }

      {
        earthings?.slice(1, 2).map(item =>
          <div className="mt-20 mx-auto max-w-full" key={item.id}>
          <h1 className="text-4xl text-center font-bold  mb-5 ">{item?.attributes?.heading}</h1>
      <div className="flex flex-col sm:flex-col md:flex-row lg:flex-row xl:flex-row justify-between items-center gap-10  ">
      <div className="w-full">
          <img
            src={`${BASE_URL}${item?.attributes?.image.data.attributes.url}`}
            alt="Solar Power System"
            className="w-full lg:w-3/4 xl:w-3/4 h-auto rounded-2xl  md:h-auto mx-auto md:mx-0 "
          />
        </div>
        <div className="" >
          <p
            className="text-lg break-words"
            style={{ fontFamily: "normal normal normal 35px/53px Poppins;" }}
            dangerouslySetInnerHTML={{ __html: item?.attributes?.content }}
          >
          </p>
        </div>
        
      </div>
      </div>
        )}

      <div
        className="mt-20 mx-auto max-w-full"
        style={{
          font: "",
          letterSpacing: "",
        }}
      >
        <h2 className="text-center font-bold text-4xl">Types of Earthing</h2>
        {
          earthings?.slice(2, 6).map(item =>
            <div className="">
              <div className="">
                <h1 className="text-3xl mt-10 font-bold ">{item?.attributes?.heading}</h1>
                <p
                  className="mt-5 text-lg break-words "
                  style={{
                    letterSpacing: "0px",
                    opacity: "1",
                  }}
                  dangerouslySetInnerHTML={{ __html: item?.attributes?.content }}
                >
                </p>
              </div>
            </div>
          )
        }
      </div>


      <div className="mt-24 ">
        <h1 className="text-center text-4xl font-bold ">
          Points to keep in mind while <br />
          installing Earthing
        </h1>


        <div className="mt-10 flex items-center justify-center">
          <div className="flex flex-col sm:flex-col md:flex-row lg:flex-row xl:flex-row items-center gap-3 lg:gap-10 xl:gap-10 justify-between"  >

            <div
              className="space-y-5 text-lg"
              style={{
                fontFamily: "normal normal 600 62px/93px Poppins;",
                letterSpacing: "0px",
                color: "#000000;",
                opacity: "1;",
              }}
            >
              {
                earthings?.slice(6, 11).map(item =>
                  <ul className="list-none p-0 m-0" key={item.id}>
                    <li className="flex items-center mb-4">
                      <div className="w-8 h-8 bg-orange-600 rounded-full flex items-center justify-center text-white font-medium mr-3">
                        <span className="w-3 h-3 bg-white rounded-full flex items-center justify-center">
                          &bull;
                        </span>
                      </div>
                      <h1 className="text-lg font-semibold ">
                        {item?.attributes?.heading}
                      </h1>
                    </li>
                  </ul>
                )
              }
            </div>
            <div className="mr-0 sm:mr-0 md:mr-0 lg:mr-32 xl:mr-32 ">
              <div className="">
                <img
                  src="Image/R.png"
                  alt="Solar Power System"
                  className="w-full sm:w-full md:w-full lg:w-80 xl:w-80 h-auto md:h-auto mx-auto md:mx-0  "
                />
              </div>
            </div>

          </div>
        </div>

      </div>
      {
        earthings?.slice(11, 12).map(item =>
          <div className="mt-24" key={item.id}>
            <h1 className="text-center font-bold text-4xl">
              {item?.attributes?.heading}
            </h1>
            <div className="space-y-5 ">
              <div className="mt-10 flex flex-col sm:flex-col md:flex-row lg:flex-row xl:flex-row  items-center justify-center ">
                <div  className="text-lg break-words "
                    style={{
                      fontFamily: "normal normal normal 35px/53px Poppins;",
                    }} dangerouslySetInnerHTML={{ __html: item?.attributes?.content }}>
                </div>

                <div className="w-full mt-5 lg:mt-0 xl-0 ">
                  <img
                    src={`${BASE_URL}${item?.attributes?.image.data.attributes.url}`}
                    alt="Solar Power System"
                    className="w-full lg:w-60 xl:w-60 h-auto md:h-auto mx-auto md:mx-0 md:ml-auto"
                  />
                </div>
              </div>
            </div>
          </div>
        )
      }
      {
        earthings?.slice(12, 13).map(item =>
          <div className="mt-20  ">
            <div className="">
              <div className="flex flex-col-reverse md:flex-row lg:flex-row xl:flex-row justify-between items-center first-letter:px-24 lg:grid-cols-2 gap-4">
                <div className="w-full">
                  <img
                    src={`${BASE_URL}${item?.attributes?.image.data.attributes.url}`}
                    alt="Solar Power System"
                    className="w-full lg:w-80 xl:w-80 h-auto  md:h-auto mx-auto md:mx-0  "
                  />
                </div>

                <div className="">
                  <p
                    className="text-lg break-words "
                    style={{
                      fontFamily: "normal normal normal 35px/53px Poppins;",
                    }}
                    dangerouslySetInnerHTML={{ __html: item?.attributes?.content }}
                  >
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}

      <div className="mt-24">
        <h1 className="text-center font-bold text-4xl">
          Good Earthing Reports
        </h1>
        <Report />
      </div>

      <div className="mt-24">
        <h1 className="text-center font-bold text-3xl">Reports</h1>
        <Report />
      </div>

      <div className="my-24">
        <h1 className="text-center font-bold text-3xl">
          {" "}
          Indian Standards Certification
        </h1>
        <Report />
      </div>
      </div>
    </BaseLayout>
  );
}

export default Earthing;
