import React, { useState } from "react";
import ElectricityBill from "../ElectricityBill";
import EAppliances from "../EAppliances";
import { MapPin } from "lucide-react";

const Calculator = () => {
  const [show, setShow] = useState(null);

  return (
    <div>
      <div className="mt-24 px-6">
        <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-4xl xl:text-4xl font-bold text-center">Solar Calculator</h1>
        <p className="text-center Calculator  font-bold  mt-5 text-xl sm:text-2xl md:text-2xl lg:text-2xl xl:text-2xl">
          Select an option to get the “Best-Fit” Solar Recommendation
        </p>

        <div className="mt-10 flex justify-center items-center flex-row sm:flex-row md:flex-row ">
          <MapPin className="text-teal-400" />
          <h1 className="">Current Pin code</h1>
          <input
            type="text"
            placeholder="110078"
            style={{ width: "", height: "50px" }}
            className="border-2  w-24 sm:w-48 md:w-48 lg:w-48 xl:w-48 border-gray-400 rounded px-4 py-2 focus:outline-none focus:border-orange-500 ml-5"
          />
        </div>

        <p className="mt-10 text-xl sm:text-3xl md:text-3xl lg:text-3xl xl:text-3xl font-medium text-center ">
          Enter the details of your
        </p>

        <div className="mt-5 flex flex-col items-center md:flex-row md:justify-center md:space-x-20 ">
          <button
            onClick={() => setShow("electricity-bill")}
            className="flex items-center justify-center bg-orange-300 text-black font-semibold px-16 py-5 rounded-2xl w-full md:w-auto h-16 md:h-auto"
          >
            Electricity Bill
          </button>
          {/* <span className="text-md my-4 md:my-0">OR</span>
                <button
                  onClick={() => setShow("electricity-appliances")}
                  className="bg-orange-300 text-black font-semibold px-10 py-5 rounded-2xl  w-full md:w-auto h-16 md:h-auto"
                >
                  Electricity Appliances
                </button> */}
        </div>
        <div>
          {show === "electricity-bill" ? (
            <div>
              <ElectricityBill className="mt-20" />
            </div>
          ) : null}
          {show === "electricity-appliances" ? (
            <div>
              <EAppliances className="mt-20" />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Calculator;
