import React, { useState } from "react";
import { Link } from "react-router-dom";
import BaseLayout from "../../../../Layout/BaseLayout";
import TypeSolarPanel from "../Photovoltaic/TypeSolarPanel";
import { getSolarInvertors } from "../../../../Services/ProductServices";
import { useQuery } from "@tanstack/react-query";
import { BASE_URL } from "../../../../Services/constant";
import TypeTechnologyPanel from "../Photovoltaic/TypeTechnologyPanel";
import Breadcrumb from "../../../navbar/Breadcrumb";
import InverterDifference from "./InverterDifference";
import InverterTypeSolarPanel from "./InverterTypeSolar";

function Inverter() {

  const [selectedTab, setSelectedTab] = useState(0);
  const { data: invertors, status, error } = useQuery({
    queryKey: ["invertors"],
    queryFn: getSolarInvertors,
  });
  if (status === 'loading') {
    return <span>Loading...</span>
  }

  if (status === 'error') {
    return <span>Error: {error.message}</span>
  }

  const handleTabClick = (index) => {
    setSelectedTab(index === selectedTab ? 0 : index);
  };

  return (
    <BaseLayout>
      <Breadcrumb subLink="Products" subLinkOne="Inverter" />
      <div className="px-5 sm:px-20 md:sm:px-20 lg:sm:px-20 xl:sm:px-20">

        {
          invertors.slice(0, 1).map(item =>
            <div>
              <div className='mt-10 flex items-center justify-center'>

                <div className='flex flex-col sm:flex-col md:flex-col lg:flex-row xl:flex-row justify-between gap-10 items-center'>
                  <div className=''>
                    <h1 className='text-4xl font-bold mb-5 '>{item?.attributes?.heading}</h1>
                    <p className='text-lg break-words' style={{ fontFamily: 'normal normal normal 35px/53px Poppins;' }} dangerouslySetInnerHTML={{ __html: item?.attributes?.content }}>
                    </p>
                  </div>

                  <div className=''>
                    <img
                      src={`${BASE_URL}${item?.attributes?.image.data.attributes.url}`}
                      alt='Solar Power System' className='w-full sm:w-full md:w-full lg:w-full xl:w-full h-auto rounded-2xl  md:h-auto mx-auto md:mx-0 md:ml-auto  ' />
                  </div>
                </div>
              </div>
              <div className='mt-20'>
                <h1 className='text-center font-bold text-4xl'>{item?.attributes?.second_heading}</h1>
                <InverterTypeSolarPanel solarPanel={invertors} />
              </div>
            </div>
          )
        }


        {
          invertors.slice(0, 1).map(item =>

            <div className='mt-10 mb-24 ' key={item.id}>
              <h1 className='text-center font-bold text-3xl'>{item?.attributes?.third_heading}</h1>

              <InverterDifference solarPanel={invertors} />
            </div>
          )}
      </div>
    </BaseLayout>
  );
}

export default Inverter;
