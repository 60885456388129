import React from "react";
import { Link } from "react-router-dom";
import BaseLayout from "../../../Layout/BaseLayout";
import { ChevronUp } from "lucide-react";
import "../Home.css";

function Calculator() {
  return (
    <BaseLayout>
      <div className=" lg:mt-48  sm:mt-20 md:mt-32 mx-auto  max-w-5xl">
        <div className="flex flex-col sm:flex-row w-400 items-center justify-between bg-blue-700 p-2 sm:mt-36">
          <h1 className="text-white text-lg mb-4 sm:mb-0 px-5 whitespace-nowrap">
            Your Monthly Electricity Bill Rs.
          </h1>
          <input
            type="text"
            placeholder="1000"
            className="bg-white text-black w-full lg:mr-10 rounded-md lg:w-1/2 xl:w-1/2 py-1 mb-4 sm:mb-0 sm:mr-4 placeholder-black placeholder-ml-4"
          />
          <button className="bg-orange-300 text-white text-2xl py-1 px-20 mr-4 ">
            Change
          </button>
        </div>
      </div>

      <div className="mt-5 ml-3 mr-3 mb-20">
        <div className="flex justify-center gap-10  ">
          <div className=" w-full ml-60 bg-gray-100 rounded-r-2xl rounded-b-2xl">
            <div className="card-body ">
              <div className=" flex items-center justify-between py-4 w-full bg-[#707070]  text-white">
                <div className="flex-grow flex items-center ">
                  <img
                    src="/Image/hybridselect.png"
                    alt="error"
                    className="mr-4 ml-10 "
                  />
                  <div
                    className="h-10 bg-white w-px mx-4"
                    style={{ height: "3.5rem" }}
                  ></div>
                  <div className="text-left">
                    <h5 className="text-xl font-semibold">Off-grid system</h5>
                    <h6>Solar with Battery backup</h6>
                  </div>
                </div>
                <div className="text-end justify-items-end flex items-center">
                  {/* Vertical Line */}
                  <ChevronUp className="w-24 h-10" />
                </div>
              </div>

              <div className=" ">
                <p className="card-text text-gray-800 mt-5 ml-4 ">
                  Solar energy is consumed & stored for future use within the
                  home
                </p>
                <div className="ml-4 mt-12 space-y-2">
                  <h1 className="">
                    <span>&#10004;</span> Provide Back-up power during
                    power-cuts
                  </h1>
                  <h1>
                    <span>&#10004;</span> Assured Bill Reduction over the
                    lifetime of the Solar system
                  </h1>
                </div>
              </div>
            </div>
            <div className="flex justify-center mt-10 ">
              <button className=" w-full text-lg  text-black  bg-orange-300 rounded-2xl   mt-50  px-8 py-3  ">
                {" "}
                <Link to="/mysaving"> View Recommendation </Link>
              </button>
            </div>
          </div>

          <div className="card w-full flex flex-col justify-end bg-gray-100 mr-60   rounded-r-2xl rounded-b-2xl">
            <div className="card-body">
              <div className="bg-[#707070] flex items-center py-4 w-full  text-white">
                <div className="inline-flex flex-grow items-center">
                  <img
                    src="/Image/gridtiedselect.png"
                    alt="error"
                    className="mr-4 ml-10 "
                  />
                  <div
                    className=" bg-white w-px mx-4"
                    style={{ height: "3.5rem" }}
                  ></div>{" "}
                  {/* Vertical Line */}
                  <div className="text-left">
                    <h5 className="text-xl font-semibold">Grid-tied System</h5>
                    <h6>Solar without Battery backup</h6>
                  </div>
                </div>
                <div className="ml-auto flex items-center">
                  <ChevronUp className="w-24 h-10" />
                </div>
              </div>

              <div className="space-y-4 ">
                <p className="card-text text-gray-800 mt-4  ml-4">
                  Solar energy is consumed within the home and excess energy is
                  sold to the grid (under a net metering agreement)
                </p>

                <div className="ml-4 space-y-4">
                  <h1>
                    <span className="checkmark">&#10004;</span> suitable for no
                    daytime power-cuts
                  </h1>
                  <h1 className="text-blue-500">
                    <span className="checkmark-blue">&#10004;</span> Bill
                    Reduction Dependent on Net/Gross metering policy
                  </h1>
                </div>
              </div>
            </div>
            <div className="flex justify-center mt-10 ">
              <button className=" w-full text-lg  text-black  bg-orange-300 rounded-2xl   mt-50  px-8 py-3 ">
                {" "}
                <Link to="/mysaving">View Recommendation </Link>
              </button>
            </div>
          </div>
        </div>
      </div>
    </BaseLayout>
  );
}

export default Calculator;
