import React from 'react'
import { getSolarSolution } from '../../../Services/ProductServices';
import { useQuery } from '@tanstack/react-query';

const SolarSolutionTable = () => {
    const { data: solarSolutionData , status , error } = useQuery({
        queryKey: ["solarSolutionData"],
        queryFn: getSolarSolution,
      });
      if (status === 'loading') {
        return <span>Loading...</span>
      }
    
      if (status === 'error') {
        return <span>Error: {error.message}</span>
      }
  return (
    <div>
        <div className="mt-24">
              <div className="overflow-auto	">
                
                    <table className="w-full border-collapse border-black ">
                  <thead>
                    <tr>
                      <th
                        className="w-1/4 p-4 text-center  text-2xl"
                        style={{
                          borderBottom: "4px solid black",
                          borderRight: "4px solid black",
                        }}
                      >
                        Basis
                      </th>
                      <th
                        className="w-1/4 p-4 text-center  text-2xl"
                        style={{
                          borderBottom: "4px solid black",
                          borderRight: "4px solid black",
                        }}
                      >
                        Ongrid
                      </th>
                      <th
                        className="w-1/4 p-4 text-center  text-2xl"
                        style={{
                          borderBottom: "4px solid black",
                          borderRight: "4px solid black",
                        }}
                      >
                        Offgrid
                      </th>
                      <th
                        className="w-1/4 p-4 text-center  text-2xl"
                        style={{ borderBottom: "4px solid black" }}
                      >
                        Hybrid
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                  {
                  solarSolutionData?.map(item=>
                    <tr key={item.id}>
                    <td
                      className=" text-center break-words"
                      style={{
                        borderBottom: "4px solid black",
                        borderRight: "4px solid black",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: item?.attributes?.basis
                      }}
                    ></td>
                    <td
                      className="p-8 text-center break-words"
                      style={{
                        borderBottom: "4px solid black",
                        borderRight: "4px solid black",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: item?.attributes?.ongrid
                      }}
                    ></td>
                    <td
                      className="p-8 text-center break-words"
                      style={{
                        borderBottom: "4px solid black",
                        borderRight: "4px solid black",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: item?.attributes?.offgrid
                      }}
                    ></td>
                    <td
                      className="p-8 text-center break-words"
                      style={{ borderBottom: "4px solid black" }}
                      dangerouslySetInnerHTML={{
                        __html: item?.attributes?.hybrid
                      }}
                    ></td>
                  </tr>
                  )}
                  </tbody>
                </table>
                   
              </div>
            </div>
    </div>
  )
}

export default SolarSolutionTable
