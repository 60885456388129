import React, { useState } from 'react'
import { Link } from "react-router-dom";
import { Search, MapPin, ChevronRight, ChevronLeft } from "lucide-react";
// import Dropdown from "./Dropdown/Dropdown";
import Dropdown from '../Homesection/Dropdown/Dropdown';
import "./Service.css";
import Footer from '../Homesection/Footer/Footer';
import BaseLayout from '../../Layout/BaseLayout';
import Breadcrumb from '../navbar/Breadcrumb';

function Service() {
  // const handleSearchClick = () => {
  //   console.log("Search button clicked!");
  // };

  return (
    <>
     <BaseLayout>
     <Breadcrumb subLink="Services" color="text-orange-600" />
      <div>
        <h1 className='text-5xl text-md text-center mb-10 mt-10 font-bold'>Project Feasibility Analysis</h1>
      </div>
      <div className='p-5'>
        <h3 className='text-3xl text-md ml-10 mb-3 font-bold'>Project Detail</h3>
        <p className='text-1xl text-md ml-10 mb-10 '>
          In this section, we will provide you with valuable insights and information to assess the feasibility of a solar panel project. Whether you are considering investing in solar panel manufacturing, installation, or any related ventures, our analysis will help you make informed decisions. Let's delve into the key factors to consider.
        </p>
        <h3 className='text-2xl text-md ml-10 mb-3 font-bold '> 1. Market Demand and Trends: </h3>
        <p className='text-1xl text-md ml-10 mb-10 '>
          Understand the current and projected market demand for solar panels. Analyze the growth trends, government incentives , and policies that influence the adoption of solar energy. Explore the potential market segments, both residential and commercial, and identify the target customers for your solar panel project. This analysis will give you a comprehensive understanding of the market landscape and growth potential.
        </p>

        <h3 className='text-2xl text-md ml-10 mb-3 font-bold '> 2. Technological Advancements: </h3>
        <p className='text-1xl text-md ml-10 mb-10 '>
          Evaluate the state of solar panel technology and identify the advancements that can give you a competitive edge. Consider the efficiency, durability, and cost-effectiveness of different solar panel technologies, such as monocrystalline, polycrystalline, or thin-film. Assess the potential for innovation and improvement within the industry and how it can impact your project's feasibility.
        </p>

        <h3 className='text-2xl text-md ml-10 mb-3 font-bold '> 3. Resource Assessment: </h3>
        <p className='text-1xl text-md ml-10 mb-10 '>
          Conduct a thorough assessment of the resources required for your solar panel project. Evaluate the availability and accessibility of raw materials, such as silicon, glass, and metals. Consider the land requirements for manufacturing facilities or solar farms, as well as the availability of suitable sites. Additionally, assess the availability of skilled labor, equipment, and infrastructure to support your project's implementation.
        </p>

        <h3 className='text-2xl text-md ml-10 mb-3 font-bold '> 4. Financial Analysis: </h3>
        <p className='text-1xl text-md ml-10 mb-10 '>
          Perform a detailed financial analysis to determine the economic viability of your project. Evaluate the capital investment required for manufacturing or installation, including costs associated with land, equipment, production facilities, and distribution networks. Consider operational expenses, such as material sourcing, labor, maintenance, and marketing. Calculate revenue projections based on pricing, market demand, and potential sales volumes. Assess the return on investment (ROI), payback period, and profitability indicators to evaluate the financial feasibility of your project.
        </p>

        <h3 className='text-2xl text-md ml-10 mb-3 font-bold '>5. Regulatory and Policy Landscape: </h3>
        <p className='text-1xl text-md ml-10 mb-10 '>
          Analyze the regulatory and policy environment surrounding the solar panel industry. Stay updated on government incentives, feed-in tariffs, tax credits, and other subsidies that can impact the financial feasibility of your project. Consider any regulatory barriers, permits, or licensing requirements that may need to be addressed. Understanding the policy landscape will help you assess the feasibility and potential risks associated with your solar panel venture.
        </p>
        <h3 className='text-2xl text-md ml-10 mb-3 font-bold '>6. Environmental Impact: </h3>
        <p className='text-1xl text-md ml-10 mb-10 '>
          Evaluate the environmental benefits and impact of your solar panel project. Consider the reduction in greenhouse gas emissions, the potential for energy independence, and the contribution to sustainability goals. Assess any environmental regulations or certifications that need to be complied with. Demonstrating a positive environmental impact can enhance the viability and marketability of your project.
        </p>
      </div>
      {/* <div className='bg-black '>
        <Footer/>
      </div> */}
      </BaseLayout>
    </>
  )
}

export default Service;
