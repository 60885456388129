import { useQuery } from '@tanstack/react-query';
import React from 'react'
import { getHomeHero } from '../../../Services/HomeService';
import styles from "../Home.module.css";
import "../MediaQuery.css";
import { Spinner } from 'react-bootstrap';
const HomeHero = ({homeData}) => {
   
    
  return (
    <div>
       {
        homeData?.map(item =>
          <div className="relative overflow-hidden pb-32 sm:pb-32 md:pb-0 lg:pd-0 xl:pb-0" key={item.id}>
            <img
              src="/Image/bg.svg"
              alt="background"
              className="absolute -bottom-[95px]"
            /> 
            <div
              className={`${styles["pocket-friendly-card"]}  bg-white p-1 sm:p-4 md:p-4 lg:p-4 xl:p-4 justify-between items-center font-medium absolute flex text-center text-sm sm:text-md md:text-md lg:text-md xl:text-md`}
            >
              <span className="text-slate-600">Pocket Friendly</span>
              <img src="/Image/money.svg" alt="money" className="h-10 sm:h-12 md:h-12 lg:h-12 xl:h-12 w-10 sm:w-12 md:w-12 lg:w-12 xl:w-12 mx-2" />
            </div>
            <div className="container2 solar mt-20  mx-auto flex items-center flex-col sm:flex-col md:flex-row lg:flex-row">
              <div style={{ zIndex: 5 }} className=" ml-24 relative mr-8 grow-0 mt-12 sm:mt-12 md:mt-0 lg:mt-0 xl:mt-0 ">
                <h1 className="text-4xl font-light" style={{ textTransform: "uppercase" }} dangerouslySetInnerHTML={{ __html: item?.attributes?.home_heading }}>
                </h1>
                <div className="mt-6 w-full max-w-lg	max-w-md">
                  <div className="text-lg break-words" dangerouslySetInnerHTML={{ __html: item?.attributes?.home_content }}>
                  </div>
                </div>
              </div>
              <div
               
                className=" background1 bg-black  bg-fixed mr-28  relative "
              >
                <div className="absolute  inset-0 w-full h-full  sm:rounded-[0_40px_0_40px] md:rounded-[0_40px_0_40px] lg:rounded-[0_40px_0_40px] xl:rounded-[0_40px_0_40px] overflow-hidden ">
                  <iframe
                    className="absolute inset-0 w-full h-full"
                    src={item?.attributes?.home_video}
                    title="YouTube video player" 
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  ></iframe>
                </div>

                <div
                  className={`${styles["enviroment-friendly-card"]} bg-white p-1 sm:p-4 md:p-4 lg:p-4 xl:p-4 justify-between items-center font-medium absolute flex text-center text-sm sm:text-md md:text-md lg:text-md xl:text-md`}
                >
                  <span className="text-slate-600">Environment Friendly</span>
                  <img src="/Image/tree-1.png" alt="tree" className="h-6 sm:h-8 md:h-8 lg:h-8 xl:h-8 w-6 sm:w-8 md:w-8 lg:w-8 xl:w-8 mx-2" />
                </div>

                <div
                  className={`${styles["trending-card"]} bg-white p-1 sm:p-4 md:p-4 lg:p-4 xl:p-4 justify-between items-center font-medium absolute flex text-center text-sm sm:text-md md:text-md lg:text-md xl:text-md`}
                >
                  <span className="text-slate-600">Trending</span>
                  <img src="/Image/chart-20.png" alt="chart" className="h-6 sm:h-8 md:h-8 lg:h-8 xl:h-8 w-6 sm:w-8 md:w-8 lg:w-8 xl:w-8 mx-2" />
                </div>
              </div>
            </div>
          </div>
        )
      }
    </div>
  )
}

export default HomeHero
