import React from "react";
import { Link } from "react-router-dom";
import BaseLayout from "../../../../Layout/BaseLayout";
import { useQuery } from "@tanstack/react-query";
import { getBalanceSheets } from "../../../../Services/ProductServices";
import { BASE_URL } from "../../../../Services/constant";
import Breadcrumb from "../../../navbar/Breadcrumb";

function BalanceSheet() {
  const { data: balanceSheets, status, error } = useQuery({
    queryKey: ["balanceSheets"],
    queryFn: getBalanceSheets,
  });
  if (status === 'loading') {
    return <span>Loading...</span>
  }

  if (status === 'error') {
    return <span>Error: {error.message}</span>
  }
  return (
    <BaseLayout>
     <Breadcrumb subLink="Products" subLinkOne="Solar Components" subLinkTwo="Balance of Sheet"/>
    <div className="px-10 lg:px-32 xl:px-32 ">
    <div className="mt-20 text-center">
        <h1 className="text-5xl font-bold">Balance of Sheet</h1>
        <hr className="border w-full sm:w-1/2  md:w-80 lg:w-80 xl:w-80  mx-auto my-2 border-black" />
      </div>
      <div className="mt-14 ">
        {
          balanceSheets?.slice(0,1).map((item, index) =>
            <div className="flex flex-col-reverse md:flex-row lg:flex-row xl:flex-row justify-between gap-10 items-center break-words" key={item.id} >
            {/* <div dir={index === index % 2 == 0 ? "rtl" : "rtr"} className="p-8 flex flex-col md:flex-row justify-between items-center break-words" key={item.id} > */}
              <div className="w-full md:w-80 mb-6 md:mb-0" >
                <img
                  src={`${BASE_URL}${item?.attributes?.image.data.attributes.url}`}
                  alt="Solar Power System"
                  className="w-full lg:w-60 mx-auto md:mx-0 rounded-3xl  "
                />
              </div>

              <div className="md:flex-1 " >
                  <h1 className="text-3xl md:text-4xl mb-4 font-semibold">
                    {item?.attributes?.heading}
                  </h1>
                  <p className="text-lg md:text-xl leading-relaxed break-words" dangerouslySetInnerHTML={{ __html: item?.attributes?.content }}>
                  </p>
              </div>
            </div>
          )
        }
      </div>

      <div className="mt-10 ">
        {
          balanceSheets?.slice(1, 2).map(item =>
            <div className="flex flex-col md:flex-row lg:flex-row xl:flex-row justify-between gap-10 items-center break-words" key={item.id} >
              <div className="md:flex-1">
                  <h1 className="text-3xl md:text-4xl mb-4  text-start md:text-end lg:text-end xl:text-end font-semibold">
                    {item?.attributes?.heading}
                  </h1>
                  <p className="text-lg md:text-xl leading-relaxed break-words" dangerouslySetInnerHTML={{ __html: item?.attributes?.content }}>
                  </p>

              </div>

              <div className="w-full md:w-80 mb-6  md:mb-0">
                <img
                  src={`${BASE_URL}${item?.attributes?.image.data.attributes.url}`}
                  alt="Solar Power System"
                  className="w-full lg:w-60 mx-auto md:mx-0 rounded-3xl"
                />
              </div>
            </div>
          )}
      </div>

      <div className="mt-10  mb-20">
        {
          balanceSheets?.slice(2, 3).map(item =>
            <div className="flex flex-col-reverse md:flex-row lg:flex-row xl:flex-row justify-between gap-10 items-center break-words" key={item.id} >
            {/* <div dir={index === index % 2 == 0 ? "rtl" : "rtr"} className="p-8 flex flex-col md:flex-row justify-between items-center break-words" key={item.id} > */}
              <div className="w-full md:w-80 mb-6 md:mb-0" >
                <img
                  src={`${BASE_URL}${item?.attributes?.image.data.attributes.url}`}
                  alt="Solar Power System"
                  className="w-full lg:w-60 mx-auto md:mx-0 rounded-3xl  "
                />
              </div>

              <div className="md:flex-1 " >
                  <h1 className="text-3xl md:text-4xl mb-4 font-semibold">
                    {item?.attributes?.heading}
                  </h1>
                  <p className="text-lg md:text-xl leading-relaxed break-words" dangerouslySetInnerHTML={{ __html: item?.attributes?.content }}>
                  </p>
              </div>
            </div>
          )}
      </div>
    </div>
    </BaseLayout>
  );
}

export default BalanceSheet;
