import { Search } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import { pages } from '../../constant'
import { Link } from 'react-router-dom'
const SearchBar = () => {
    const [search, setSearch] = useState("")
    const [filterItem, setFilterItem] = useState([])
    

    useEffect(() => {
        if (search) {
            let searchBar = pages.filter(el => el.title.includes(search))
            setFilterItem(searchBar)
        } 
        else{
            setFilterItem(null)
        }
    }, [search])
    return (
        <div className=''>
        <div className="flex relative">
            <form >
            <input

                type="text"
                className="bg-orange-500 max-w-[220px] text-white placeholder-white lg:w-96 px-4 py-2 "
                placeholder="Search here .."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                style={{borderRadius: search ? 0 : "9999px" }}
            />
            <Search className="w-6 h-6 text-white absolute right-1 top-2 mr-4 " />
            </form>
        </div>
        

              <div className={search && 'bg-orange-200 text-black p-3 border absolute  w-full divide-y overflow-y-auto '}>
          {
            filterItem?.length == 0 ?
            (
                <div>No Match Found.</div>
              )
              :
              (
                filterItem?.map(el=>
                  <Link to={el.url} >
                      <div className=''>
                        {el.title}
                    </div>
                  </Link>
                    ) 
            )
            }
              </div>
                </div>
    )
}

export default SearchBar
