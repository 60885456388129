import React, { useEffect, useRef, useState } from "react";
import { grievanceForm } from "../../Services/SamparkService";
import { toast } from "react-toastify";

const GrievanceForm = () => {
  const [employeeName, setEmployeeName] = useState("");
  const [date, setDate] = useState("");
  const [jobTitle, SetJobTitle] = useState("");
  const [employeeId, setEmployeeId] = useState("");
  const [joiningDate, setJoiningDate] = useState("");
  const [homeMailingAddress, setHomeMailingAddress] = useState("");
  const [workMailingAddress, setWorkMailingAddress] = useState("");
  const [dateTimePlace, setDateTimePlace] = useState("");
  const [detailedAccount, setDetailedAccount] = useState("");
  const [policyProcedure, setPolicyProcedure] = useState("");
  const [proposedSolution, setProposedSolution] = useState("");


  const dataToSent = {
    data: {
      employee_name: employeeName,
      date: date,
      job_title: jobTitle,
      employee_Id: employeeId,
      joining_date: joiningDate,
      home_mailing_address: homeMailingAddress,
      work_mailing_address: workMailingAddress,
      date_time_place: dateTimePlace,
      detailed_account: detailedAccount,
      policy_procedure: policyProcedure,
      proposed_solution: proposedSolution,
    }
  }
  const handleSubmit = async (e) => {
    e.preventDefault()
    console.log(dataToSent);
    try {
      const { data } = await grievanceForm(dataToSent);
      toast.success(" Details Added!")
    }
    catch (e) {
      toast.error("please check your inputs!")
      console.log(e);

    }
  }

  return (
    <div className="p-5 md:p-10 lg:p-10 xl:p-10">
      <div className="mx-5 sm:mx-20 md:mx-32 lg:mx-32 xl:mx-32 my-5 pb-24">
        <h2 className="font-bold text-2xl" >
          Employee Grievance Form
        </h2>
        <p
          className=" pl-2 mt-3 border border-black text-lg rounded"
        >
          Grievant Information
        </p>
        <form className="my-5 " >
          <div className="w-full flex flex-col sm:flex-row  md:flex-row lg:flex-row xl:flex-row items-center gap-5 items-center">
           <div className="w-full">
           <label  className="block text-gray-700 text-sm font-medium mb-1">Employee Name:</label>
            <input
              type="text"
              name="name"
              id="name"
              className=" shadow appearance-none border rounded py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mt-1 outline-none my-input  w-full "
              value={employeeName}
              onChange={(e) => setEmployeeName(e.target.value)}
            />
           </div>
             <div className="w-full">
           <label className="block text-gray-700 text-sm font-medium mb-1">Date:</label>
            <input
              type="date"
              name="date"
              id="date"
              className=" shadow appearance-none border rounded py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mt-1 outline-none my-input  w-full   "
              value={date}
              onChange={(e) => setDate(e.target.value)}
            />
           </div>
          </div>
          <div className="w-full flex flex-col sm:flex-row md:flex-row lg:flex-row xl:flex-row items-center gap-5 mt-3">
          
             <div  className="w-full">
             <label className="block text-gray-700 text-sm font-medium mb-1">Job Title:</label>
            <input
              type="text"
              name="title"
              id="title"
              className=" shadow appearance-none border rounded py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mt-1 outline-none my-input  w-full   "
              value={jobTitle}
              onChange={(e) => SetJobTitle(e.target.value)}
            />
             </div>
             <div className="w-full">
            <label className="block text-gray-700 text-sm font-medium mb-1">Employee ID:</label>
            <input
              type="text"
              name="emp_id"
              id="emp_id"
              className=" shadow appearance-none border rounded py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mt-1 outline-none my-input  w-full   "
              value={employeeId}
              onChange={(e) => setEmployeeId(e.target.value)}
            />
          </div>

          </div>
          <div className="w-full flex flex-col sm:flex-row md:flex-row lg:flex-row xl:flex-row mt-3 gap-5 items-center">

           <div className="w-full">
           <label className="block text-gray-700 text-sm font-medium mb-1">Date of Hire:</label>
            <input
              type="date"
              name="date"
              id="date"
              className="shadow appearance-none border rounded py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mt-1 outline-none my-input  w-full   "
              value={joiningDate}
              onChange={(e) => setJoiningDate(e.target.value)}
            />
           </div>
           <div className="w-full ">
           <label className="block text-gray-700 text-sm font-medium mb-1">Home Mailing Address:</label>
            <input
              type="text"
              name="address"
              id="address"
              className="shadow appearance-none border rounded py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mt-1 outline-none my-input  w-full   "
              value={homeMailingAddress}
              onChange={(e) => setHomeMailingAddress(e.target.value)}
            />
            </div>
          </div>

          <div className="w-full flex flex-col sm:flex-row md:flex-row lg:flex-row xl:flex-row mt-3 items-center">
           
          </div>
          <div className="w-full  flex-col sm:flex-row md:flex-row lg:flex-row xl:flex-row mt-3 items-center">
            <label className="block text-gray-700 text-sm font-medium mb-1">work Mailing Address:</label>
            <input
              type="text"
              name="address"
              id="address"
              className="shadow appearance-none border rounded py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mt-1 outline-none my-input  w-full   "
              value={workMailingAddress}
              onChange={(e) => setWorkMailingAddress(e.target.value)}
            />
          </div>
          <div className=" mt-5">
            <label for="message" class=" block mb-2 text-sm font-medium text-gray-700 dark:text-white font-weight500">Date,time and place of event leading to grievance:</label>
            <textarea id="message" rows="4" class="block p-2.5 w-full text-sm text-gray-700 bg-gray-50 rounded-lg border focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              value={dateTimePlace}
              onChange={(e) => setDateTimePlace(e.target.value)}
            />

            <label for="message" class=" mt-3 block mb-2 text-sm font-medium text-gray-700 dark:text-white font-weight500"> Detailed account of occurrence (include names of persons involved,if any):</label>
            <textarea id="message" rows="4" class="h-24 block p-2.5 w-full text-sm text-gray-700 bg-gray-50 rounded-lg border focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              value={detailedAccount}
              onChange={(e) => setDetailedAccount(e.target.value)}
            />

            <label for="message" class="mt-3 block mb-2 text-sm font-medium text-gray-700 dark:text-white font-weight500"> Please state policies,procedures,or guidelines that you feel have been violated:</label>
            <textarea id="message" rows="4" class="h-24 block p-2.5 w-full text-sm text-gray-700 bg-gray-50 rounded-lg border focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              value={policyProcedure}
              onChange={(e) => setPolicyProcedure(e.target.value)}
            />
            <label for="message" class="mt-3 block mb-2 text-sm font-medium text-gray-700 dark:text-white font-weight500"> Proposed solution to grievance:</label>
            <textarea id="message" rows="4" class="h-24 block p-2.5 w-full text-sm text-gray-700 bg-gray-50 rounded-lg border focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              value={proposedSolution}
              onChange={(e) => setProposedSolution(e.target.value)}
            />
          </div>
          <button className="p-1 mt-5 px-5 text-center border  border-black text-lg rounded" onClick={handleSubmit}>Submit</button>
        </form>
        <div className="mt-3">
          <p>
            The grievant should retain a copy of this form for his/her
            records. The signature below indicates that you are a filling a
            grievance,and any information on this form is truthful.
          </p>
          <div className="flex gap-14 w-full">
            <div className="flex flex-col w-full">
              <input
                type="text"
                name="signature"
                id="signature"
                className=" shadow appearance-none border rounded py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mt-1 outline-none my-input  w-full   "
              />
              <label className="block text-gray-700 text-sm font-semibold mb-2">Employee Signature</label>
            </div>
            <div className="flex flex-col w-2/6" >
              <input
                type="date"
                name="date"
                id="date"
                className=" shadow appearance-none border rounded py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mt-1 outline-none my-input  w-full   "
              />
              <label className="block text-gray-700 text-sm font-semibold mb-2">Date</label>
            </div>
          </div>
          <div className="flex gap-14 w-full mt-8">
            <div className="flex flex-col w-full">
              <input
                type="text"
                name="received"
                id="received"
                className=" shadow appearance-none border rounded py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mt-1 outline-none my-input  w-full   "
              />
              <label className="block text-gray-700 text-sm font-semibold mb-2">Received by</label>
            </div>
            <div className="flex flex-col w-2/6" >
              <input
                type="date"
                name="date"
                id="date"
                className=" shadow appearance-none border rounded py-1 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mt-1 outline-none my-input  w-full   "
              />
              <label className="block text-gray-700 text-sm font-semibold mb-2">Date</label>
            </div>
          </div>
        </div>

        <div className="flex flex-wrap justify-between items-center mt-20">
          <div>&#169;Samplewords 2011</div>
          <div>Available for free download at www.samplewords.com</div>
          <div className="mb-4">
            <img src="/Image/sample-words.png" alt="sample words logo" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GrievanceForm;
