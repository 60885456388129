import React, {useState} from "react";
import {Link} from "react-router-dom";
import { ChevronRight, ChevronLeft} from "lucide-react";

 import BaseLayout from "../../../Layout/BaseLayout";
import Footer from "../../Homesection/Footer/Footer";
import { useQuery } from "@tanstack/react-query";
import { getInstallations } from "../../../Services/ServicesService";
import LatestProjects from "../../Homesection/Home/LatestProjects";
import Breadcrumb from "../../navbar/Breadcrumb";
import ImageCarausel from "../ImageCarausel";


export default function Installation () {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [projectSlide, setProjectSlide] = useState(0);
  const { data: installations , status ,error} = useQuery({
    queryKey: ["installations"],
    queryFn: getInstallations,
  });
  if (status === 'loading') {
    return <span>Loading...</span>
  }

  if (status === 'error') {
    return <span>Error: {error.message}</span>
  }
  const handleNextSlide = () => {
    setCurrentSlide(prevSlide => (prevSlide + 1) % totalSlides);
  };

  const handlePrevSlide = () => {
    setCurrentSlide(prevSlide => (prevSlide - 1 + totalSlides) % totalSlides);
  };
  const handleNextProjectSlide = () => {
    setProjectSlide(prevSlide => (prevSlide + 1) % totalProjectSlides);
  };

  const handlePrevProjectSlide = () => {
    setProjectSlide(prevSlide => (prevSlide - 1 + totalProjectSlides) % totalProjectSlides);
  };


  const totalSlides = 2; // Update this with the total number of slides in the slider
  const totalProjectSlides = 4
  const slides = [
    {
      imageUrl: "Image/pngwing.png",
      imageUrl: "Image/pngwing.png",
      imageUrl: "Image/pngwing.png",

    },
    {
      imageUrl: "Image/pngwing.png",

    },

  ];

return (
    <>
    <BaseLayout>
    <Breadcrumb subLink="Services" subLinkOne="Installation and Commission"/>
      <div className="relative pt-24">
        <div
        style={{
          backgroundImage: "url(/Image/service.svg)",
          backgroundRepeat: "no-repeat",
        }}
        className="absolute inset-0 w-full h-full -z-10 opacity-50">
        </div>
        {
          installations?.map(item =>
                
                <div key={item.id} >
              <div>
                <h1 className="text-xl sm:text-5xl md:text-5xl lg:text-5xl xl:text-5xl text-center mb-10 font-bold ">
                  {item?.attributes?.heading}
                </h1>
              </div>

              <div>
                <p className="text-sm sm:text-xl md:text-xl lg:text-xl xl:text-xl mr-10 sm:mr-24 md:mr-24 lg:mr-10 xl:mr-24 break-words " dangerouslySetInnerHTML={{ __html: item?.attributes?.content }}>
                </p>
              </div>
            </div>
          )
        }
      </div>
      <ImageCarausel/>
     <LatestProjects/>
      </BaseLayout>
    </>
  )
}

