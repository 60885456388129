import React from "react";
import { Search } from "lucide-react";
import { NavLink, useLocation, Link } from "react-router-dom";
import Dropdown from "./Dropdown";
import cn from "../../Utils/cn";
import { Menu } from "lucide-react";

import {
  serviceLinks,
  solarSolutionLinks,
  solarComponentsLinks,
  earthingLinks,
  gensetLinks,
  lightningLinks,
} from "./constants";
import "../Homesection/Home.css";
import { Drawer } from "@mui/material";
import SearchBar from "./SearchBar";

const ActiveLink = ({ to, children, className }) => {
  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        cn(
          isActive ? "text-orange-500" : "text-black",
          "hover:text-orange-500 font-medium py-2 px-2 whitespace-nowrap",
          className
        )
      }
    >
      {children}
    </NavLink>
  );
};

export default function Navbar({ isLoggedin }) {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const location = useLocation();

 

  const ResponsiveNavbar = (
    <div className="w-60  mt-20">
      <nav className="  flex justify-center items-center">
        <ul className="flex flex-col gap-12 items-center  relative">
          <li>
            <ActiveLink to="/">Home</ActiveLink>
          </li>
          <li className="group relative">
            <ActiveLink
              to="/on-grid"
              className={cn(
                "group-hover:text-orange-500",
                [
                  ...solarSolutionLinks,
                  ...solarComponentsLinks,
                  ...earthingLinks,
                  ...gensetLinks,
                  ...lightningLinks,
                ]
                  .map(({ url }) => url)
                  .includes(location.pathname)
                  ? "text-orange-500"
                  : ""
              )}
            >
              Products
            </ActiveLink>
            <Dropdown className="hidden group-hover:block w-[430px]" />
          </li>
          <li className="group relative">
            <ActiveLink
              to="/service"
              className={cn(
                "group-hover:text-orange-500",
                serviceLinks.map(({ url }) => url).includes(location.pathname)
                  ? "text-orange-500"
                  : ""
              )}
            >
              Service
            </ActiveLink>
            <div className="absolute h-full w-[250px] -bottom-7 -left-4 hidden group-hover:block">
              <ul className="flex flex-col space-y-2 bg-black text-white shadow-lg p-4 rounded-2xl border">
                {serviceLinks.map((service) => (
                  <li key={service.url}>
                    <Link
                      to={service.url}
                      className="text-white hover:text-orange-500"
                    >
                      {service.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </li>
          <li>
            <ActiveLink to={isLoggedin ? "/employee-dashboard" : "/sampark"}>Sampark</ActiveLink>
          </li>
          <li>
            <ActiveLink to="/about">About Us</ActiveLink>
          </li>
          <li>
            <ActiveLink to="/contact">Contact Us</ActiveLink>
          </li>
          
        </ul>
      </nav>
    </div>
  )

  return (
    <div className="fixed top-0 w-full  bg-white z-20 ">
      <nav className="px-4 xl:px-24 lg:px-14 flex justify-between items-center">
        <div className="flex items-center">
          <Link to="/">
            <img src="Image/Logo.png" alt="Logo" className="w-22 h-24 mr-2" />
          </Link>
        </div>
        {/* <div className="flex relative inline-flex sm:inline-flex md:inline-flex lg:hidden xl:hidden"  >
              <input
                type="text"
                className="bg-orange-500 max-w-[220px] text-white placeholder-white lg:w-96 px-4 py-2 rounded-full"
                placeholder="Search here .."
                onClick={handleSearchClick}
              />
              <Search className="w-6 h-6 text-white absolute right-1 top-2 mr-4 " />
            </div> */}

        <Menu className="w-10 h-10 lg:hidden" onClick={handleDrawerToggle} />

        <ul className="flex gap-4 items-center max-lg:hidden relative">
          <li>
            <ActiveLink to="/">Home</ActiveLink>
          </li>
          <li className="group relative">
            <ActiveLink
              to="/on-grid"
              className={cn(
                "group-hover:text-orange-500",
                [
                  ...solarSolutionLinks,
                  ...solarComponentsLinks,
                  ...earthingLinks,
                  ...gensetLinks,
                  ...lightningLinks,
                ]
                  .map(({ url }) => url)
                  .includes(location.pathname)
                  ? "text-orange-500"
                  : ""
              )}
            >
              Products
            </ActiveLink>
            <Dropdown className="hidden group-hover:block w-[430px]" />
          </li>
          <li className="group relative">
            <ActiveLink
              to="/service"
              className={cn(
                "group-hover:text-orange-500",
                serviceLinks.map(({ url }) => url).includes(location.pathname)
                  ? "text-orange-500"
                  : ""
              )}
            >
              Service
            </ActiveLink>
            <div className="absolute h-full w-[250px] -bottom-7 -left-4 hidden group-hover:block">
              <ul className="flex flex-col space-y-2 bg-black text-white shadow-lg p-4 rounded-2xl border">
                {serviceLinks.map((service) => (
                  <li key={service.url}>
                    <Link
                      to={service.url}
                      className="text-white hover:text-orange-500"
                    >
                      {service.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </li>
          <li>
            <ActiveLink to={isLoggedin ? "/employee-dashboard" : "/sampark"}>Sampark</ActiveLink>
          </li>
          <li>
            <ActiveLink to="/about">About Us</ActiveLink>
          </li>
          <li>
            <ActiveLink to="/contact">Contact Us</ActiveLink>
          </li>
          <li className="flex relative">
           <SearchBar/>
          </li>
        </ul>
      </nav>
      <div component="nav">
        <Drawer

          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}

          style={{
            display: { xs: 'block', sm: 'none' },
          }}
        >
          {ResponsiveNavbar}
        </Drawer>
      </div>
    </div>
  );
}


