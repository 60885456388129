import { useQuery } from '@tanstack/react-query';
import React from 'react'
import { getWhySolar } from '../../../Services/HomeService';

const WhySolarPower = () => {
    const { data: whySolar , status ,error } = useQuery({
        queryKey: ["whySolar"],
        queryFn: getWhySolar,
      });
      if (status === 'loading') {
        return <span>Loading...</span>
      }
    
      if (status === 'error') {
        return <span>Error: {error.message}</span>
      }
  return (
    <div>
      {
        whySolar?.map(item =>
          <div className="text-center mx-4 mt-24 md:mx-32 md:pt-0" key={item.id}>
            <h1 className="text-3xl md:text-4xl lg:text-4xl xl:text-4xl font-bold  ">{item?.attributes?.Heading}</h1>
            <p className="text-xl md:text-2xl lg:text-2xl xl:text-2xl power mt-5" dangerouslySetInnerHTML={{ __html: item?.attributes?.Content }}>
            </p>
            <div className="flex flex-col md:flex-row flex-wrap justify-center mt-12 gap-6 md:gap-60">
          <div className="flex flex-col items-center">
            <img
              src="Image/tree-1.png"
              className="rounded-full bg-green-200 w-24 h-24 p-6"
              alt="Tree"
            />
            <h1 className="text-center">Environment Friendly</h1>
          </div>

          <div className="flex flex-col items-center">
            <img
              src="/Image/money.svg"
              className="rounded-full bg-red-100 w-24 h-24 px-6 "
              alt="Banknote"
            />
            <h1 className="text-center">Pocket Friendly</h1>
          </div>
          <div className="flex flex-col items-center">
            <div className="rounded-full bg-teal-100 p-2">
              <img
                src="Image/chart-20.png"
                className="h-auto w-16 md:w-16 lg:w-20 p-6 "
                alt="Chart"
              />
            </div>
            <h1 className="ml-2 text-center">In Latest Trend</h1>
          </div>
        </div>
          </div>
        )
      }
    </div>
  )
}

export default WhySolarPower
