import React from "react";
import { Link } from "react-router-dom";
import BaseLayout from "../../../../Layout/BaseLayout";
import Footer from "../../Footer/Footer";
import { getPhotovoltic } from "../../../../Services/ProductServices";
import { useQuery } from "@tanstack/react-query";
import { BASE_URL } from "../../../../Services/constant";
import TypeSolarPanel from "./TypeSolarPanel";
import TypeTechnologyPanel from "./TypeTechnologyPanel";
import Breadcrumb from "../../../navbar/Breadcrumb";
import DifferenceTable from "./DifferenceTable";

function Photovoltaic() {
  const { data: photovoltic, status, error } = useQuery({
    queryKey: ["photovoltic"],
    queryFn: getPhotovoltic,
  });
  if (status === 'loading') {
    return <span>Loading...</span>
  }

  if (status === 'error') {
    return <span>Error: {error.message}</span>
  }
  return (
    <BaseLayout>

      <Breadcrumb subLink="Products" subLinkOne="Solar Photovoltaic Panel" />
      <div className="px-5 sm:px-20 md:sm:px-20 lg:sm:px-20 xl:sm:px-20">
        {
          photovoltic.slice(0, 1).map(item =>
            <div className="">
              <div className='mt-10 flex items-center justify-center'>

                <div className='flex flex-col sm:flex-col md:flex-col lg:flex-row xl:flex-row justify-between gap-10 items-center '>
                  <div >
                    <h1 className='text-4xl font-bold mb-5 '>{item?.attributes?.heading}</h1>
                    <p className='text-lg break-words' style={{ fontFamily: 'normal normal normal 35px/53px Poppins;' }} dangerouslySetInnerHTML={{ __html: item?.attributes?.content }}>
                    </p>
                  </div>

                  <div>
                    <img
                      src={`${BASE_URL}${item?.attributes?.image.data.attributes.url}`}
                      alt='Solar Power System' className='w-full sm:w-full md:w-full lg:w-full xl:w-full h-auto  rounded-2xl  md:h-auto mx-auto md:mx-0 md:ml-auto  ' />
                  </div>
                </div>
              </div>
              <div className='mt-20'>
                <h1 className='text-center font-bold text-4xl'>{item?.attributes?.second_heading}</h1>
                <TypeSolarPanel solarPanel={photovoltic} />
              </div>
            </div>
          )
        }


       <DifferenceTable/>

        {
          photovoltic.slice(0, 1).map(item =>

            <div className='mt-10 mb-24' key={item.id}>
              <h1 className='text-center font-bold text-3xl'>{item?.attributes?.third_heading}</h1>

              <TypeTechnologyPanel solarPanel={photovoltic} />
            </div>
          )}
      </div>
    </BaseLayout>
  );
}

export default Photovoltaic;
