import React from "react";
import { Link } from "react-router-dom";
import BaseLayout from "../../../../Layout/BaseLayout";
import CopyGenset from "./CopyGenset";
import { getPetrolGensets } from "../../../../Services/ProductServices";
import { useQuery } from "@tanstack/react-query";
import Breadcrumb from "../../../navbar/Breadcrumb";

function PetrolGenset() {
    const { data: petrolGensets, status, error } = useQuery({
        queryKey: ["petrolGensets"],
        queryFn: getPetrolGensets,
    });
    if (status === 'loading') {
        return <span>Loading...</span>
    }

    if (status === 'error') {
        return <span>Error: {error.message}</span>
    }
    return (
        <BaseLayout>
            <Breadcrumb subLink="Products" subLinkOne="Genset" subLinkTwo="Petrol Genset" />
            <div className=" px-10  lg:px-32 xl:px-32">
                {
                    petrolGensets?.slice(0, 1).map(item =>
                        <div className=' mt-5 md:mt-24 lg:mt-24 xl:mt-24'>
                            <h1 className=' mb-4 text-start md:text-center lg:text-center xl:text-center font-bold text-3xl md:text-4xl lg:text-4xl xl:text-4xl'>
                                {item?.attributes?.heading}
                            </h1>
                            <p className='mt-0 md:mt-10 lg:mt-10 xl:mt-10 text-2xl  font-medium text-start md:text-center lg:text-center xl:text-center break-words' dangerouslySetInnerHTML={{ __html: item?.attributes?.content }}>
                            </p>
                        </div>
                    )
                }
            </div>

            <CopyGenset genset={petrolGensets} />

            <div className='mt-10 mb-10 px-5  '>
                <h1 className='text-center font-semibold text-3xl md:text-4xl lg:text-4xl xl:text-4xl '>Know about other Gensets</h1>
                <div className="flex flex-row flex-wrap justify-center items-center  gap-20  mt-10">
                    <Link to="/diesel-genset">

                        <div className="flex flex-col items-center">
                                <img
                                    src="Assets/Diesel genset.webp"
                                    alt="Image 1"
                                    className="w-full md:w-80 lg:w-80 xl:w-80 h-auto md:h-72 lg:h-72 xl:h-72 p-2 rounded-3xl"
                                    />
                            <p
                                className="text-center mt-2 text-xl md:text-3xl lg:text-3xl xl:text-3xl  font-semibold"
                                style={{
                                    fontFamily: "normal normal normal 35px/53px Poppins",
                                    color: "#000000",
                                }}
                            >
                                Diesel Genset
                            </p>
                        </div>
                    </Link>
                    <Link to="/gas-genset">
                        <div className="flex flex-col items-center">
                                <img
                                    src="Assets/gas genset.webp"
                                    alt="Image 1"
                                    className="w-full md:w-80 lg:w-80 xl:w-80 h-auto md:h-72 lg:h-72 xl:h-72 p-2 rounded-3xl"
                                    />
                            <p
                                className="text-center mt-2  text-black text-xl md:text-3xl lg:text-3xl xl:text-3xl font-semibold"
                            >
                                Gas Genset
                            </p>
                        </div>
                    </Link>
                    <Link to="/duel-fuel-genset">
                        <div className="flex flex-col items-center">
                                <img
                                    src="Assets/Duel fuel.jpeg"
                                    alt="Image 1"
                                    className="w-full md:w-80 lg:w-80 xl:w-80 h-auto md:h-72 lg:h-72 xl:h-72 p-2 rounded-3xl"
                                    />
                            <p
                                className="text-center mt-2 text-xl md:text-3xl lg:text-3xl xl:text-3xl font-semibold"
                                style={{
                                    fontFamily: "normal normal normal 35px/53px Poppins",
                                    color: "#000000",
                                }}
                            >
                                Duel Fuel Genset
                            </p>
                        </div>
                    </Link>
                </div>

            </div>
        </BaseLayout>
    );
}

export default PetrolGenset;
