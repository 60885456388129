import React from 'react'
import { Link } from 'react-router-dom'

const Breadcrumb = ({ subLink, subLinkOne, subLinkTwo,color,to }) => {
    return (
        <div>
            <div className="pt-24">
                <img
                    src="Image/cropped2.png"
                    alt="error"
                    style={{
                        width: "100%",
                        height: "auto",
                        objectFit: "contain",
                        maxHeight: "calc(100vh - 80px)",
                    }}
                    className="bg-image"
                />
                <p className="text-xl md:text-2xl lg:text-2xl xl:text-2xl absolute top-0 left-8  ml-0 sm:ml-24 md:ml-24 lg:ml-24 xl:ml-24 mt-32">
                    <Link to="/" className=' hover:text-orange-600'>Home</Link> &gt;&gt;{" "}
                    <Link to={to} className={`${color} hover:text-orange-600`}>
                        {" "}
                        <span>{subLink}</span>{" "}
                    </Link>{" "}
                    {
                        subLinkOne &&
                        <>
                            &gt;&gt; <span className=" text-orange-600 Solar Components visited:text-orange-600"> {subLinkOne} </span>
                        </>
                    }

                    {
                        subLinkTwo &&
                        <>&gt;&gt; <span className="text-orange-600 hover:text-orange-600"> {subLinkTwo}</span></>
                    }
                </p>
            </div>
        </div>
    )
}

export default Breadcrumb
