export let pages = [
    {
        title: "sampark",
        url: "/sampark"
    },
    {
        title: "electricity appliances",
        url: "/electricity-appliances"
    },
    {
        title: "electricity bill",
        url: "/electricity-bill"
    },
    {
        title: "about",
        url: "/about"
    },
    ,
    {
        title: "contact",
        url: "/contact"
    },
    {
        title: "blogs and news",
        url: "/blogs-and-news"
    },
    {
        title: "blog",
        url: "/blog"
    },
    {
        title: "news",
        url: "/news"
    },
    {
        title: "work with us",
        url: "/work-with-us"
    },
    {
        title: "employee dashboard",
        url: "/employee-dashboard"
    },
    {
        title: "grievance form",
        url: "/grievance-form"
    },
    {
        title: "inverter",
        url: "/inverter"
    },
    {
        title: "photovoltaic",
        url: "/photovoltaic"
    },
    {
        title: "earthing",
        url: "/earthing"
    },
    {
        title: "hybrid",
        url: "/hybrid"
    },
    {
        title: "off grid",
        url: "/off-grid"
    },
    {
        title: "on grid",
        url: "/on-grid"
    },
    {
        title: "plate earthing",
        url: "/plate-earthing"
    },
    {
        title: "chemical earthing grid",
        url: "/chemical-earthing-grid"
    },
    {
        title: "chemical earthing",
        url: "/chemical-earthing"
    },
    {
        title: "balance sheet",
        url: "/balance-sheet"
    },
    {
        title: "calculator",
        url: "/calculator"
    },
    {
        title: "solar structure",
        url: "/solar-structure"
    },
    {
        title: "feasibility",
        url: "/feasibility"
    },
    {
        title: "health",
        url: "/health"
    },
    {
        title: "installation",
        url: "/installation"
    },
    {
        title: "pmc",
        url: "/pmc"
    },
    {
        title: "epc",
        url: "/epc"
    },
    {
        title: "service",
        url: "/service"
    },
    {
        title: "our tie ups",
        url: "/our-tie-ups"
    },
    {
        title: "ese",
        url: "/ese"
    },
    {
        title: "conventional",
        url: "/conventional"
    },
    {
        title: "petrol genset",
        url: "/petrol-genset"
    },
    {
        title: "gas genset",
        url: "/gas-genset"
    },
    {
        title: "duel fuel genset",
        url: "/duel-fuel-genset"
    },
    {
        title: "rod chemical earthing",
        url: "/rod-chemical-earthing"
    },
    {
        title: "diesel genset",
        url: "/diesel-genset"
    }
]