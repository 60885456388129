import { Route, Routes } from "react-router-dom";
import Home from "./Components/Homesection/Home";
import About from "./Components/Homesection/About/About";
import Contact from "./Components/Homesection/Contact/Contact";
import Careers from "./Components/Homesection/Careers/Careers";
import Service from "./Components/Services/Service";
import OnGrid from "./Components/Homesection/Products/OnGrid/OnGrid";
import OffGrid from "./Components/Homesection/Products/OnGrid/OffGrid/OffGrid";
import Hybrid from "./Components/Homesection/Products/Hybrid/Hybrid";
import Earthing from "./Components/Homesection/Products/Earthing/Earthing";
import Photovoltaic from "./Components/Homesection/Products/Photovoltaic/Photovoltaic";
import Inverter from "./Components/Homesection/Products/Inverter/Inverter";
import SolarStructure from "./Components/Homesection/Products/Solarstructure/SolarStructure";
import EAppliances from "./Components/Homesection/EAppliances";
import ElectricityBill from "./Components/Homesection/ElectricityBill";
import BaseLayout from "./Layout/BaseLayout";
import Calculator from "./Components/Homesection/Calculator/Calculator";
import BalanceSheet from "./Components/Homesection/Products/BalanceSheet";
import ChemicalEarthing from "./Components/Homesection/Products/ComEarthing/ChemicalEarthing";
import ChemicalEarthingGrid from "./Components/Homesection/Products/ComEarthing/ChemicalEarthingGrid";
import DieselGenset from "./Components/Homesection/Products/Genset/DieselGenset";
import PlateEarthing from "./Components/Homesection/Products/ComEarthing/PlateEarthing";
import RodChemicalEarthing from "./Components/Homesection/Products/ComEarthing/RodChemicalEarthing";
import EPC from "./Components/Services/EPC/EPC";
import PMC from "./Components/Services/PMC/PMC";
import Installation from "./Components/Services/Installation_And_Commission/Installation";
import Health from "./Components/Services/Health_Checkup/HealthCheckup";
import Feasibility from "./Components/Services/Feasibility_Checkup/FeasibilityCheckup";
import BlogNews from "./Components/Homesection/Blog&News/BlogNews";
import DuelFuelGenset from "./Components/Homesection/Products/Genset/DuelFuelGenset";
import GasGenset from "./Components/Homesection/Products/Genset/GasGenset";
import PetrolGenset from "./Components/Homesection/Products/Genset/PetrolGenset";
import Conventional from "./Components/Homesection/Products/LightningArrester/Conventional";
import Ese from "./Components/Homesection/Products/LightningArrester/Ese";

import TieUps from "./Components/Tieups/TieUps";
import BlogHeading from "./Components/Homesection/Blog&News/BlogHeading";
import NewsHeading from "./Components/Homesection/Blog&News/NewsHeading";
import { Fragment, useState } from "react";
import Protected from "./Components/ProtectedRoutes";
import EmpDashboard from "./Admin/Dashboard/EmpDashboard";
import GrievanceForm from "./Admin/Dashboard/GrievanceForm";
import Sampark from "./Components/Homesection/Sampark/Sampark";
import ScrollToTop from "./Components/ScrollToTop/ScrollToTop";

function App() {
  const [isLoggedIn, setisLoggedIn] = useState(localStorage.getItem('token'));
  return (
    <Fragment>
            <ScrollToTop />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/electricity-appliances"
          element={
            <BaseLayout>
              <EAppliances />
            </BaseLayout>
          }
        />
        <Route
          path="/electricity-bill"
          element={
            <BaseLayout>
              <ElectricityBill /> {/* page also used as component  */}
            </BaseLayout>
          }
        />
        <Route path='/employee-dashboard'
          element={
            <Protected isLoggedIn={isLoggedIn}>
              <EmpDashboard />
            </Protected>
          }
        />
        <Route path='/grievance-form'
          element={
            <Protected isLoggedIn={isLoggedIn}>
              <GrievanceForm />
            </Protected>
          }
        />

        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/blogs-and-news" element={<BlogNews />} />
        <Route path="/blog" element={<BlogHeading />} />
        <Route path="/news" element={<NewsHeading />} />
        <Route path="/work-with-us" element={<Careers />} />
        <Route path="/sampark" element={<Sampark />} />
        <Route path="/service" element={<Service />} />

        <Route path="/on-grid" element={<OnGrid />} />
        <Route path="/off-grid" element={<OffGrid />} />
        <Route path="/hybrid" element={<Hybrid />} />
        <Route path="/earthing" element={<Earthing />} />
        <Route path="/photovoltaic" element={<Photovoltaic />} />
        <Route path="/inverter" element={<Inverter />} />

        <Route path="/solar-structure" element={<SolarStructure />} />
        <Route path="/calculator" element={<Calculator />} />
        <Route path="/balance-sheet" element={<BalanceSheet />} />
        <Route path="/chemical-earthing" element={<ChemicalEarthing />} />
        <Route
          path="/chemical-earthing-grid"
          element={<ChemicalEarthingGrid />}
        />

        <Route path="/plate-earthing" element={<PlateEarthing />} />
        <Route path="/diesel-genset" element={<DieselGenset />} />
        <Route path="/rod-chemical-earthing" element={<RodChemicalEarthing />} />
        <Route path="/duel-fuel-genset" element={<DuelFuelGenset />} />
        <Route path="/gas-genset" element={<GasGenset />} />
        <Route path="/petrol-genset" element={<PetrolGenset />} />
        <Route path="/conventional" element={<Conventional />} />
        <Route path="/ese" element={<Ese />} />
        <Route path="/our-tie-ups" element={<TieUps />} />
        <Route path="/epc" element={<EPC />} />
        <Route path="/pmc" element={<PMC />} />
        <Route path="/installation" element={<Installation />} />
        <Route path="/health" element={<Health />} />
        <Route path="/feasibility" element={<Feasibility />} />
        {/* <Route path="*" element={<ErrorPage />} /> */}
        {/* <Route path="*" element={<NotFound />} /> */}
      </Routes>
    </Fragment>
  );
}

export default App;
