import { ChevronLeft, ChevronRight } from 'lucide-react';
import React, { useState } from 'react'

const ImageCarausel = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const handleNextSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % totalSlides);
    };

    const handlePrevSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide - 1 + totalSlides) % totalSlides);
    };
        const totalSlides = 2; // Update this with the total number of slides in the slide
        const slides = [
            {
                imageUrl: "Image/pngwing.png",
            },
            {
                imageUrl: "Image/pngwing.png",
            },
        ];

   
    return (
        <div className="mt-24 ">
            <div
                id="indicators-carousel"
                className="relative w-full"
                data-carousel="static"
            >
                <div className="flex flex-col md:flex-row md:my-4 mt-10 ">
                    {/* Left Side - Image Section */}
                    <div className="w-full md:w-1/2 m-auto  flex items-center justify-center">
                        <img
                            src={slides[currentSlide].imageUrl}
                            className="w-96 h-auto md:max-w-md "
                            alt="1"
                        />
                        <img
                            src={slides[currentSlide].imageUrl}
                            className="w-96 h-auto md:max-w-md hidden-property-mob-img2"
                            alt="2"
                        />
                        <img
                            src={slides[currentSlide].imageUrl}
                            className="w-96 h-auto md:max-w-md hidden-property-mob-img3"
                            alt="3"
                        />
                    </div>
                </div>


                {/* Slider Buttons */}
                <div className="flex justify-center mt-4 gap-4 ">
                    <button
                        className={`h-5 w-5 rounded-full border-black ${currentSlide === 0 ? "bg-orange-300" : "bg-gray-300"
                            }`}
                        onClick={() => setCurrentSlide(0)}
                    />
                    <button
                        className={`h-5 w-5 rounded-full ${currentSlide === 1 ? "bg-orange-300" : "bg-gray-300"
                            }`}
                        onClick={() => setCurrentSlide(1)}
                    />

                    {/* Add more buttons for additional slides */}
                </div>

                <button
                    type="button"
                    className="absolute top-0 left-0 flex items-center justify-center h-full px-5 py-5 cursor-pointer group focus:outline-none"
                    data-carousel-prev
                    onClick={handlePrevSlide}
                >
                    <ChevronLeft className="w-16 h-16 rounded-full  bg-white   py-4  g" />
                </button>

                <button
                    type="button"
                    className="absolute top-0 right-0 flex items-center justify-center h-full px-5 py-5 cursor-pointer group focus:outline-none"
                    data-carousel-next
                    onClick={handleNextSlide}
                >
                    <ChevronRight className="w-16 h-16 rounded-full  bg-white  py-4" />
                </button>
            </div>
        </div>
    )
}

export default ImageCarausel
