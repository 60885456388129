import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ChevronRight, ChevronLeft } from "lucide-react";
import BaseLayout from "../../../Layout/BaseLayout";
import { getEpc } from "../../../Services/ServicesService";
import { useQuery } from "@tanstack/react-query";
import LatestProjects from "../../Homesection/Home/LatestProjects";
import Breadcrumb from "../../navbar/Breadcrumb";
import ImageCarausel from "../ImageCarausel";

export default function EPC() {
  const [projectSlide, setProjectSlide] = useState(0);

  const { data: epc, status, error } = useQuery({
    queryKey: ["epc"],
    queryFn: getEpc,
  });
  if (status === 'loading') {
    return <span>Loading...</span>
  }

  if (status === 'error') {
    return <span>Error: {error.message}</span>
  }
 
  return (
    <BaseLayout>
      <Breadcrumb subLink="Services" subLinkOne="EPC" />
      <div className="relative pt-24">
        <div
          style={{
            backgroundImage: "url(/Image/service.svg)",
            backgroundRepeat: "no-repeat",
          }}
          className="absolute inset-0 w-full h-full -z-10 opacity-50"
        ></div>
        {
          epc?.map(item =>
            <div key={item.id}>
              <div>
                <h1 className="text-xl sm:text-5xl md:text-5xl lg:text-5xl xl:text-5xl text-center mb-10 font-bold ">
                  {item?.attributes?.heading}
                </h1>
              </div>

              <div>
                <p className=" text-sm sm:text-xl md:text-xl lg:text-xl xl:text-xl mr-10 sm:mr-24 md:mr-24 lg:mr-10 xl:mr-24  break-words " dangerouslySetInnerHTML={{ __html: item?.attributes?.content }}>
                </p>
              </div>
            </div>
          )
        }
      </div>
      {/* fourth section */}
      <ImageCarausel/>

      {/* fifth section */}
      <LatestProjects />
    </BaseLayout>
  );
}
