import React from "react";
import { Link } from "react-router-dom";
import BaseLayout from "../../../../Layout/BaseLayout";
import CopyEarthing from "./CopyEarthing";
import { getRodChemEarthing } from "../../../../Services/ProductServices";
import { useQuery } from "@tanstack/react-query";
import Breadcrumb from "../../../navbar/Breadcrumb";

function RodChemicalEarthing() {
  const { data: rodChemEarthing , status ,error} = useQuery({
    queryKey: ["rodChemEarthing"],
    queryFn: getRodChemEarthing,
  });
  if (status === 'loading') {
    return <span>Loading...</span>
  }

  if (status === 'error') {
    return <span>Error: {error.message}</span>
  }
  return (
    <BaseLayout>
             <Breadcrumb subLink="Products" subLinkOne="Earthing" subLinkTwo="Chemical Earthing"/>
             <div className=" px-10  lg:px-32 xl:px-32">
             {
                    rodChemEarthing?.slice(0, 1).map(item =>
                        <div className='mt-5 md:mt-24 lg:mt-24 xl:mt-24'>
                            <h1 className=' mb-4 text-start md:text-center lg:text-center xl:text-center font-bold text-3xl md:text-4xl lg:text-4xl xl:text-4xl'>
                                {item?.attributes?.heading}
                            </h1>
                            <p className='mt-0 md:mt-10 lg:mt-10 xl:mt-10 text-2xl  font-medium text-start md:text-center lg:text-center xl:text-center break-words' dangerouslySetInnerHTML={{ __html: item?.attributes?.content }}>
                            </p>
                        </div>
                    )
                }

      <CopyEarthing chemEarthing={rodChemEarthing}/>
      </div>

      <div className='mt-10 mb-10 px-5'>
                <h1 className='text-center font-semibold text-3xl md:text-4xl lg:text-4xl xl:text-4xl '>Know about other Earthing</h1>
                <div className="flex flex-row flex-wrap justify-center items-center  gap-20  mt-10">
                <Link to="/chemical-earthing">
                <div className="flex flex-col items-center">
                        <div className="relative w-full md:w-80 lg:w-80 xl:w-80 rounded-2xl">
                            <img
                                src="Assets/chemical-earthing-new.png"
                                alt="Image 1"
                                className=" w-full md:w-80 lg:w-80 xl:w-80 h-auto md:h-56 lg:h-56 xl:h-56 p-2 rounded-3xl"
                                />
                        </div> 
                        <p
                            className="text-center mt-2 text-xl md:text-3xl lg:text-3xl xl:text-3xl  font-semibold"
                            style={{
                                fontFamily: "normal normal normal 35px/53px Poppins",
                                color: "#000000",
                            }}
                        >
                            Chemical Earthing
                        </p>
                    </div>
                    </Link>

                    <Link to="/plate-earthing">
                    <div className="flex flex-col items-center">
                        <div className="relative  w-full md:w-80 lg:w-80 xl:w-80 rounded-2xl">
                            <img
                                src="Assets/plate earthing.jpeg"
                                alt="Image 1"
                                className="w-full md:w-80 lg:w-80 xl:w-80 h-auto md:h-56 lg:h-56 xl:h-56 p-2 rounded-3xl"
                                />
                        </div>
                        <p
                            className="text-center mt-2  text-black text-xl md:text-3xl lg:text-3xl xl:text-3xl font-semibold"
                        >
                            Plate Earthing
                        </p>
                    </div>
                        </Link>

                        <Link to="/chemical-earthing-grid">
                    <div className="flex flex-col items-center">
                        <div className="relative  w-full md:w-80 lg:w-80 xl:w-80 rounded-2xl">
                            <img
                                src="Assets/Chemical Earthing grid.jpg"
                                alt="Image 1"
                                className="w-full md:w-80 lg:w-80 xl:w-80 h-auto md:h-56 lg:h-56 xl:h-56 p-2 rounded-3xl"
                                />
                        </div>
                        <p
                            className="text-center mt-2 text-xl md:text-3xl lg:text-3xl xl:text-3xl font-semibold"
                            style={{
                                fontFamily: "normal normal normal 35px/53px Poppins",
                                color: "#000000",
                            }}
                        >
                             Chemical
                            Earthing Grid
                        </p>
                    </div>
                    </Link>
                </div>

            </div>
    </BaseLayout>
  );
}

export default RodChemicalEarthing;
