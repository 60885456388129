import React, { useState, useReducer } from "react";
import cn from "../../Utils/cn";
import "./Home.css";

const getAvg = (bill, traffic) => {
  const eBillAsNumber = Number(bill);
  const eTrafficAsNumber = Number(traffic);
  if (eBillAsNumber <= 0 || eTrafficAsNumber <= 0) {
    return 0;
  }

  return eBillAsNumber / eTrafficAsNumber;
};

const init = {
  eBill: "",
  eTraffic: "",
  dishwasher: 0,
  induction_stove: 0,
  microwave_oven: 0,
  geysers: 0,
  ac_heaters: 0,
  ups: true,
};

const ElectricityBill = ({ className }) => {
  const [state, dispatch] = useReducer(
    (prev, next) => ({ ...prev, [next.key]: next.value }),
    init
  );
  const [show, setShow] = useState(false);

  const average_monthly_electricity_consumption = getAvg(
    state.eBill,
    state.eTraffic
  ); // kWh
  const additional_load_from_appliances =
    state.dishwasher +
    state.induction_stove +
    state.microwave_oven +
    state.geysers +
    state.ac_heaters; //  kWh/day

  const total_average_monthly_consumption =
    average_monthly_electricity_consumption + additional_load_from_appliances; // kWh
  const solar_system_size = total_average_monthly_consumption / (5 * 30); // kW
  const effective_additional_load = state.ups
    ? additional_load_from_appliances / 0.9
    : 0; // kWh
  const effective_total_average_monthly_consumption =
    average_monthly_electricity_consumption + effective_additional_load; // kWh
  const effective_solar_system_size =
    effective_total_average_monthly_consumption / (5 * 30); // kW

  const onInput = (e) => {
    dispatch({ key: e.target.id, value: e.target.value });
  };

  const onChange = (e) => {
    const kw = Number(e.target.dataset.kw);
    const hourperday = Number(e.target.dataset.hourperday);
    dispatch({
      key: e.target.id,
      value: e.target.checked ? kw * hourperday : 0,
    });
  };

  const onChangeUps = (e) => {
    dispatch({
      key: e.target.name,
      value: e.target.value === "yes" ? true : false,
    });
  };

  const onClickShow = () => {
    setShow(true);
  };

  return (
    <div
      className={cn("mt-40 mb-24 flex items-center", className)}
      data-testid="e-bill"
    >
      <div className="py-2 max-w-3xl mx-auto w-full">
        <div className="flex flex-col gap-5">
          <input
            placeholder="Monthly Electricity bill*"
            value={state.eBill}
            id="eBill"
            onChange={onInput}
            className="py-2 px-2  border border-gray-900 rounded w-full md:w-1/2 lg:w-1/2 xl:w-1/2"
          />
          <input
            placeholder="Monthly Electricity tariff*"
            value={state.eTraffic}
            id="eTraffic"
            onChange={onInput}
            className="py-2 px-2  border border-gray-900  rounded w-full md:w-1/2 lg:w-1/2 xl:w-1/2"
          />
        </div>
        <div className="mt-5 flex flex-col md:flex-row lg:flex-row xl:flex-row gap-6 items-center">
          <div>
            <label className="font-weight500">
              Do you have UPS for power back-up?
            </label>
          </div>
          <div className="inline-flex gap-4">
            <label className="inline-flex items-center">
              <input
                type="radio"
                name="ups"
                value="yes"
                defaultChecked={state.ups}
                onChange={onChangeUps}
                className="mr-1 w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
              />
              <span className="font-bold">Yes</span>
            </label>
            <label className="inline-flex items-center">
              <input
                type="radio"
                name="ups"
                value="no"
                defaultChecked={!state.ups}
                onChange={onChangeUps}
                className="mr-1 w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
              />
              <span className="font-bold">No</span>
            </label>
          </div>
        </div>
        <div className="mt-5 gap-6 items-center">
          <div>
            <label className="font-weight500">
              Select each of the appliances below that you run "regularly"?
            </label>
          </div>
          <div className="mt-5 flex flex-wrap gap-10">
            <div className="flex items-center input-radio">
              {" "}
              <input
                type="checkbox"
                id="dishwasher"
                data-kw="1.5"
                data-hourperday="1"
                onChange={onChange}
                className="mr-2 w-6 h-6"
              />
              <span>Dishwasher</span>
            </div>
            <div className="flex items-center input-radio">
              <input
                type="checkbox"
                id="induction_stove"
                data-kw="2"
                data-hourperday="2"
                onChange={onChange}
                className="mr-2  w-6 h-6"
              />
              <span>Induction Stove</span>
            </div>
            <div className="flex items-center input-radio">
              {" "}
              <input
                type="checkbox"
                id="microwave_oven"
                data-kw="1.2"
                data-hourperday="0.5"
                onChange={onChange}
                className="mr-2  w-6 h-6"
              />
              <span>Microwave Oven</span>
              <br />
            </div>
            <br />
          </div>
          <div className="flex mt-10 sm:mt-10 md:mt-5 lg:mt-5 xl:mt-5">
            <div className="flex items-center input-radio">
              <input
                type="checkbox"
                id="geysers"
                data-kw="3"
                data-hourperday="1"
                onChange={onChange}
                className="mr-2  w-6 h-6"
              />
              <span>Geysers</span>
            </div>
          </div>
          <div className="flex items-center mt-10 sm:mt-10 md:mt-5 lg:mt-5 xl:mt-5  input-radio">
            <input
              type="checkbox"
              id="ac_heaters"
              data-kw="2.5"
              data-hourperday="4"
              onChange={onChange}
              className=" mr-2  w-6 h-6"
            />
            <span>AC/Heaters</span>
          </div>
        </div>
        {show ? (
          <div className="mt-6">
            <p>
              Solar System Size:{" "}
              <strong>{Math.abs(solar_system_size).toFixed(2)} KWH</strong>{" "}
              approximately
            </p>
            {/* <p>
              Effective Solar System Size:{" "}
              <strong>
                {Math.abs(effective_solar_system_size).toFixed(2)} KWH
              </strong>{" "}
              approximately
            </p> */}
          </div>
        ) : null}
        <div className="flex justify-center mt-10">
          <button
            onClick={onClickShow}
            className="bg-[#FFC680] rounded-2xl  text-black font-semibold  text-lg py-2.5 px-8"
          >
            {" "}
            Calculate my savings{" "}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ElectricityBill;
