import React from "react";
import { Link } from "react-router-dom";
import {
  solarSolutionLinks,
  solarComponentsLinks,
  earthingLinks,
  gensetLinks,
  lightningLinks,
} from "./constants";

const OrangeLink = ({ to, children }) => (
  <li>
    <Link to={to} className="hover:text-orange-500">
      {children}
    </Link>
  </li>
);

function Dropdown({ className }) {
  return (
    <div className={`absolute h-full -bottom-7 -left-5 ${className}`}>
      <div className=" mb-24 bg-black rounded-2xl border shadow p-4">
        <div className="flex space-x-10 ">
          {/* First Column: Products */}
          <div className="w-1/2">
            <ul className="text-white ">
              <li>
                <h1 className="text-lg">Solar Solution</h1>
                <hr className="text-white w-full my-1 mb-3"></hr>
                <ul className="w-full space-y-1  font-light text-sm" style={{display:"block"}}>
                  {solarSolutionLinks.map((solarSolution) => (
                    <OrangeLink key={solarSolution.name} to={solarSolution.url}>
                      {solarSolution.name}
                    </OrangeLink>
                  ))}
                </ul>
              </li>
              <li className="mt-2">
                <h1 className="text-lg">Solar Component</h1>
                <hr className="text-white w-full my-1 mb-3"></hr>
                <ul className="space-y-1 font-light text-sm">
                  {solarComponentsLinks.map((solarComponents) => (
                    <OrangeLink
                      key={solarComponents.name}
                      to={solarComponents.url}
                    >
                      {solarComponents.name}
                    </OrangeLink>
                  ))}
                </ul>
              </li>
              <li className=" mt-2">
                <h1 className="text-lg">Earthing</h1>
                <hr className="text-white w-full my-1 mb-3"></hr>
                <ul className="space-y-1 font-light text-sm">
                  {earthingLinks.map((earthing) => (
                    <OrangeLink key={earthing.name} to={earthing.url}>
                      {earthing.name}
                    </OrangeLink>
                  ))}
                </ul>
              </li>
            </ul>
          </div>

          {/* Second Column: Genset, Lightning Arrester */}
          <ul className="w-1/2 text-white">
            <li className="">
              <h1 className="text-lg ">Genset</h1>
              <hr className="text-white w-full my-1 mb-3"></hr>
              <ul className="space-y-1 font-light text-sm">
                {gensetLinks.map((genset) => (
                  <OrangeLink key={genset.name} to={genset.url}>
                    {genset.name}
                  </OrangeLink>
                ))}
              </ul>
            </li>

            <li className="mt-2 ">
              <h1 className="text-lg">Lightning Arrester</h1>
              <hr className="text-white my-1"></hr>
              <ul className="space-y-1 font-light text-sm">
                {lightningLinks.map((lightning) => (
                  <OrangeLink key={lightning.name} to={lightning.url}>
                    {lightning.name}
                  </OrangeLink>
                ))}
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Dropdown;
