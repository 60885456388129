import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ChevronRight, ChevronLeft } from "lucide-react";
import "./Careers.css";
import BaseLayout from "../../../Layout/BaseLayout";
import { getContinueLearning, getJoinus } from "../../../Services/FooterService";
import { useQuery } from "@tanstack/react-query";
import { BASE_URL } from "../../../Services/constant";
import Breadcrumb from "../../navbar/Breadcrumb";

function Careers() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const { data: joinUs, status, error } = useQuery({
    queryKey: ["joinUs"],
    queryFn: getJoinus,
  });
  const { data: continueLearning } = useQuery({
    queryKey: ["continueLearning"],
    queryFn: getContinueLearning,
  });
  if (status === 'loading') {
    return <span>Loading...</span>
  }

  if (status === 'error') {
    return <span>Error: {error.message}</span>
  }
  const handleNextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % totalSlides);
  };

  const handlePrevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide - 1 + totalSlides) % totalSlides);
  };

  const totalSlides = 4;
  return (
    <BaseLayout>
                     <Breadcrumb subLink="Work With Us" color="text-orange-600"/>
      <div className="">
        {/* first section  */}
        <div className="mt-10 p-10">
          <h1 className="text-3xl md:text-5xl lg:text-5xl xl:text-5xl text-md text-center mb-3 mt-0 md:mt-10 lg:mt-10 xl:mt-10 font-semibold">
            We listen more when people root for you
          </h1>
          <h5 className="text-xl md:text-2xl lg:text-2xl xl:text-2xl text-md text-center mb-10 text-orange-600 ">
            We only accept applications through employee referrals.
          </h5>
        </div>
        {/* second section  */}
        <div>
          <h1 className="text-3xl md:text-5xl lg:text-5xl xl:text-5xl text-md text-center mt-10 font-semibold px-3">
            What makes us who we are
          </h1>

          <div
            id="indicators-carousel"
            className="relative w-full mb-10 "
            data-carousel="static"
          >
            {
        continueLearning && 
        <div className="mt-24 ">
        <div
          id="indicators-carousel"
          className="relative w-full"
          data-carousel="static"
        >
          <h1 className="text-center font-semibold text-3xl md:text-5xl lg:text-5xl xl:text-5xl px-3">
            Continuous Learning
          </h1>
          <div className="flex flex-col md:flex-row md:my-4 ">
            <div className="w-full text-center  md:w-1/2 lg:w-1/2   ">
              <p
                className="md:text-left px-10 md:px-0 lg:px-0 xl:px-0 ml-0 md:ml-32 lg:ml-64 xl:ml-64 text-lg  mb-5 mt-10 break-words"
                dangerouslySetInnerHTML={{
                  __html: continueLearning[currentSlide].attributes.continuous_learning_content,
                }}
              ></p>
            </div>
            <div className="w-full md:w-1/2 flex items-center justify-center ">
              <img
                src={`${BASE_URL}${continueLearning[currentSlide].attributes.continuous_learning_image.data.attributes.url}`}
                className="w-64 h-auto md:max-w-md bg-orange-300 rounded-3xl p-14"
                alt="Product"
              />
            </div>
          </div>

          {/* Slider Buttons */}
              <div className="flex justify-center mt-4 gap-4 ">
          {
            continueLearning?.map((item,index)=>
            <button
              className={`h-5 w-5 rounded-full ${
                currentSlide === index ? "bg-orange-300" : "bg-gray-300"
              }`}
              onClick={() => setCurrentSlide(index)}
            />
            )
        }
            {/* Add more buttons for additional slides */}
          </div>

          <button
            type="button"
            className="absolute top-8 left-0  ml-0  xl:ml-24 flex items-center justify-center h-full px-0 xl:px-5 py-5 cursor-pointer group focus:outline-none"
            data-carousel-prev
            onClick={handlePrevSlide}
          >
            <ChevronLeft className="w-16 h-16 rounded-full  bg-white   py-4 group-hover:bg-white group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none" />
          </button>

          <button
            type="button"
            className="absolute top-8 right-0  mr-0  xl:mr-24 flex items-center justify-center h-full px-0 xl:px-5 py-5 cursor-pointer group focus:outline-none"
            data-carousel-next
            onClick={handleNextSlide}
          >
            <ChevronRight className="w-16 h-16 rounded-full  bg-white  py-4 group-hover:bg-white group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none" />
          </button>
        </div>
      </div>
      }
          </div>
        </div>

          {/* join us section  */}
          {
              joinUs?.map(item=>
                <div>
          <div className="mt-20 ml-20 sm:ml-24 md:ml-24 lg:ml-24 xl:ml-24  mx-4 md:mx-32  flex flex-col md:flex-row items-center justify-center" key={item.id}>
            {/* Content*/}
            <div
              className="space-y-4 md:w-1/2 mr-0 sm:mr-20 md:mr-20 lg:mr-20 xl:mr-20 md:mt-0 mt-4"
              style={{
                fontFamily: "font: normal normal normal 32px/48px Poppins;",
                letterspacing: "10px;",
                color: "#000000;",
                opacity: "1;",
              }}
            >
              <h1 className="text-5xl font-semibold  text-orange-500 mb-4 container3">
                Join us
              </h1>
             
               <div className="pt-4">
                 <p className=" text-xl md:text-2xllg:text-2xl xl:text-2xl text-slate-500 break-words"  dangerouslySetInnerHTML={{
                    __html: item?.attributes?.joinus_content,
                  }}>
            </p>
                </div>
              
            </div>
            {/* Image Section */}
            <div className="w-full md:w-1/2">
              <img
                src={`${BASE_URL}${item?.attributes?.joinus_image.data.attributes.url}`}
                className=" mx-auto md:ml-0 lg:ml-20 w-full  md:mr-auto"
                alt="Solar Power"
              />
            </div>
          </div>
            
        {/* thirtd section  */}

        <div className="mt-16 relative bg-img-careers">
          <div
            className="flex flex-col justify-center ml-14 sm:ml-20 md:ml-20 lg:ml-20 xl:ml-20 absolute text-white"
            style={{ height: "600px" }}
          >
           <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-5xl xl:text-5xl font-semibold text-white">
              {item?.attributes?.heading}
            </h1>
            <p className="text-white mt-8 w-1/2 text-xl sm:text-2xl md:text-2xl lg:text-2xl xl:text-2xl break-words"  dangerouslySetInnerHTML={{
                    __html: item?.attributes?.content,
                  }}>
            </p>
          </div>
        </div>
        </div>
        )
      }
        {/* fourth section  */}
        <div className="my-36 pt-0">
          <h1 className="text-4xl text-md text-center font-semibold px-2">
            Building <span className="text-orange-600">DIVY POWER</span>{" "}
            together...
          </h1>
          <div className="flex flex-wrap gap-x-24 gap-y-10 mt-10 justify-center ">
            <img
              src="Image/divy-power.png"
              className=" w-1/4"
              alt="Solar Power"
            />
            <img
              src="Image/divy-power.png"
              className="   w-1/4 "
              alt="Solar Power"
            />
            <img
              src="Image/divy-power.png"
              className="   w-1/4 "
              alt="Solar Power"
            />
            <img
              src="Image/divy-power.png"
              className="  w-1/4  "
              alt="Solar Power"
            />
            <img
              src="Image/divy-power.png"
              className="  w-1/4  "
              alt="Solar Power"
            />
            <img
              src="Image/divy-power.png"
              className="   w-1/4 "
              alt="Solar Power"
            />
          </div>
        </div>
      </div>
    </BaseLayout>
  );
}

export default Careers;
