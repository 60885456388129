import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useRef, useState } from 'react'
import { getWhyDivy } from '../../../Services/HomeService';
import { BASE_URL } from '../../../Services/constant';
import { useInView } from 'framer-motion';
import WhyDivyViewBtn from './WhyDivyViewBtn';

const AnimatedProgressBar = ({ title, value, maxValue }) => {
  const [animatedWidth, setAnimatedWidth] = useState(0);
  const [animatedValue, setAnimatedValue] = useState(0);
  const titleRef = useRef(null);
  const isInView = useInView(titleRef);

  const handleScroll = () => {
    // Calculate the animated width
    setAnimatedWidth((value / maxValue) * 100);

    // Calculate the animated value (increase gradually)
    const step = Math.ceil(value / (maxValue * 100)); // Adjust the step as needed
    const newValue = Math.min(animatedValue + step, value);
    setAnimatedValue(newValue);
  };

  const handleResize = () => {
    // Recalculate the animated width
    setAnimatedWidth((value / maxValue) * 100);
  };

  useEffect(() => {
    if (isInView) {
      handleScroll();
    }
    // eslint-disable-next-line
  }, [animatedValue, isInView, value, maxValue, title]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div ref={titleRef} className="w-full">
      <div className="flex flex-col sm:flex-row justify-between items-center mt-2 mb-4">
        <h2>{title}</h2>

        <span>{animatedValue.toFixed(0)}</span>
      </div>
      <div className="w-full bg-gray-300 rounded-lg overflow-hidden">
        <div
          className="h-1 bg-orange-300 animated-bar"
          style={{ width: `${animatedWidth}%` }}
        ></div>
      </div>
    </div>
  );
};

const WhyDivyy = () => {
  const [showMore, setShowMore] = useState(false);


  const { data: whyDivy, status, error } = useQuery({
    queryKey: ["whyDivy"],
    queryFn: getWhyDivy,
  });
  if (status === 'loading') {
    return <span>Loading...</span>
  }

  if (status === 'error') {
    return <span>Error: {error.message}</span>
  }

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };
  return (
    <div>
      {
        whyDivy?.map(item =>
          <div key={item.id} >
            <div>
              <h1 className="text-center font-bold  mt-16 sm:mt-32 md:mt-32 lg:mt-32 xl:mt-32 text-3xl md:text-4xl lg:text-4xl xl:text-4xl px-1">
                {item?.attributes?.Heading}
              </h1>
            </div>
            <div>
              <div className="mt-5 flex flex-col sm:flex-col md:flex-col lg:flex-row xl:flex-row items-center justify-center sm:space-x-0 px-10 sm:px-10 md:px-10 lg:px-0 xl:px-0">
                {/* Left Side - Progress Bars */}
                <div className="flex flex-col space-y-5 mt-0 sm:mt-0  md:mt-4 lg:mt-4 xl:mt-4 w-full sm:w-full md:w-full lg:w-1/2 xl:w-1/2 ">
                  <h1 className="text-xl md:text-2xl lg:text-2xl xl:text-2xl m-0 ">
                    {item?.attributes?.SubHeading}
                  </h1>
                  {/* Only show these progress bars when showMore is true */}

                  <>
                    <AnimatedProgressBar
                      title={item?.attributes?.First_Slider_Text}
                      value={parseInt(item?.attributes?.First_Slider_Value)}
                      maxValue={80000}
                    />
                    <AnimatedProgressBar
                      title={item?.attributes?.Second_Slider_Text}
                      value={parseInt(item?.attributes?.Second_Slider_Value)}
                      maxValue={70000}
                    />
                    <AnimatedProgressBar
                      title={item?.attributes?.Third_Slider_Text}
                      value={parseInt(item?.attributes?.Third_Slider_Value)}
                      maxValue={60000}
                    />
                  </>
                </div>
                {/* Right Side - Image Section */}
                <div className="w-full sm:w-full md:w-full lg:w-1/3 xl:w-1/3 2xl:w-1/3 mt-10 sm:mt-10 md:mt-10 lg:mt-0 xl:mt-0">
                  <img
                    src={`${BASE_URL}${item?.attributes?.Image?.data?.attributes?.url}`}
                    className="w-full sm:w-full md:w-full lg:w-96 xl:w-96 ml-0 sm:ml-0 md:ml-0 lg:ml-24"
                    alt="DIVYA Power"
                  />
                </div>
              </div>

             
            </div>
          </div>
        )
      }
    </div>
  )
}

export default WhyDivyy
