import { authApi, defaultApi } from "./api";
import { BASE_URL } from "./constant";

// export async function addSampark(data) {
//  const loginData= await defaultApi
//         .post('/api/auth/local', data)
//         .then((response) => {
//           const token = response.data.jwt;
//           const userData = response.data;
//           window.localStorage.setItem('token', token); 
//           window.localStorage.setItem('userData', JSON.stringify(userData));
          
//         })
// }
export async function getDashboard() {
    const res = await authApi.get(`/api/employee-dashboards?populate=*`);
    return res.data.data;
  }
  
  export async function grievanceForm(data) {
    return await authApi.post(`/api/grievance-forms`,data);
   }
   export async function getPdfs() {
      const res =await authApi.get(`/api/sampark-pdfs?populate=*`);
      return res.data.data;
   }
   
