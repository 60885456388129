import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react'
import { getLatestProject } from '../../../Services/HomeService';
import { MapPin, ChevronRight, ChevronLeft } from "lucide-react";
import { BASE_URL } from '../../../Services/constant';
import { Link } from 'react-router-dom';

const LatestProjects = () => {
    const [latestProjects, setLatestProjects] = useState(0);

    const handleNextSlide = () => {
        setLatestProjects((prevSlide) => (prevSlide + 1) % totalSlides);
    };

    const handlePrevSlide = () => {
        setLatestProjects((prevSlide) => (prevSlide - 1 + totalSlides) % totalSlides);
    };

    const totalSlides = 4; // Update this with the total number of slides in the slider

    const { data: latestProject, status, error } = useQuery({
        queryKey: ["latestProject"],
        queryFn: getLatestProject,
    });
    if (status === 'loading') {
        return <span>Loading...</span>
    }

    if (status === 'error') {
        return <span>Error: {error.message}</span>
    }
    return (
        <div className=''>
            {
                latestProject &&
                <div
                    id="indicators-carousel"
                    className="relative w-full"
                    data-carousel="static"
                >
                    <div className="bg-gray-200 py-5 flex flex-col items-center justify-center mt-40 ">
                        <h1 className="text-center text-4xl font-bold mb-10 ">
                            {latestProject[latestProjects].attributes.Heading}
                        </h1>
                        <div className="container mx-auto max-w-screen-md md:border lg:border-2 ">
                            <div className="flex flex-col sm:flex-col md:flex-row lg:flex-row xl:flex-row md:items-center justify-center">
                                <div className="w-full md:w-1/2  ">
                                    <img
                                        src={`${BASE_URL}${latestProject[latestProjects].attributes.Image.data.attributes.url}`}
                                        className="w-full h-auto md:max-w-md lg:max-w-lg "
                                        alt="Project"
                                    />
                                </div>
                                <div className="w-full md:w-1/2 md:pl-10 text-center md:text-left py-10 sm:py-10 md:py-24 lg:py-24 xl:py-24 bg-white  rounded-border ">
                                    <p className="font-lg text-4xl mb-4">{latestProject[latestProjects].attributes.SubHeading}</p>
                                    <hr
                                        className="border-b-2 ml-auto mr-auto border-orange-700"
                                        style={{ width: "40%" }}
                                    />
                                    <div className="mb-4 px-3 sm:px-3 md:px-0 lg:px-0 xl:px-0">
                                        <p className="text-lg mt-2">
                                            {latestProject[latestProjects].attributes.Address}
                                        </p>
                                    </div>
                                    <p className="text-sm text-slate-600 font-normal  px-3 sm:px-3 md:px-0 lg:px-0 xl:px-0">
                                        {latestProject[latestProjects].attributes.Content}
                                    </p>
                                    <div className="flex items-center justify-center md:justify-start mt-4 gap-4 px-3 sm:px-3 md:px-0 lg:px-0 xl:px-0">
                                        <img
                                            src="Image/icon.png"
                                            alt="error"
                                            className="bg-orange-600 rounded-full w-10 h-10 p-2"
                                        />
                                        <div className="text-left">
                                            <h1 className="text-sm md:text-base">{latestProject[latestProjects].attributes.Icon_Side_Text}</h1>
                                            <span className="text-sm font-light">{latestProject[latestProjects].attributes.Icon_Side_Number}</span>
                                        </div>
                                        <button className="bg-orange-600 text-white font-semibold px-6 py-2 ml-6 rounded-full">
                                            <Link to='/on-grid'>
                                                Know More
                                            </Link>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-center mt-4 gap-4">
                            {
                                latestProject?.map((item, index) =>
                                    <button
                                        key={item.id}
                                        className={`h-5 w-5 rounded-full border-2  border-gray-200  ${latestProjects === index ? "bg-orange-500" : "bg-white"
                                            }`}
                                        onClick={() => setLatestProjects(index)}
                                    />
                                )}
                        </div>

                        <button
                            type="button"
                            className="absolute top-0 left-0 0 ml-0 sm:ml-32 md:ml-32 lg:ml-32 xl:ml-32 flex items-center justify-center h-full px-5 py-5 cursor-pointer group focus:outline-none"
                            data-carousel-prev
                            onClick={handlePrevSlide}
                        >
                            <ChevronLeft className="w-16 h-16 rounded-full   py-4 group-hover:bg-white group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none" />
                        </button>

                        <button
                            type="button"
                            className="absolute top-0 right-0  mr-0 sm:mr-32 md:mr-32 lg:mr-32 xl:mr-32 flex items-center justify-center h-full px-5 py-5 cursor-pointer group focus:outline-none"
                            data-carousel-next
                            onClick={handleNextSlide}
                        >
                            <ChevronRight className="w-16 h-16 rounded-full  py-4 group-hover:bg-white group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none" />
                        </button>
                    </div>
                </div>
            }
        </div>
    )
}

export default LatestProjects
