import React from "react";
import { Link } from "react-router-dom";
import BaseLayout from "../../Layout/BaseLayout";
import Breadcrumb from "../navbar/Breadcrumb";
import TieUp from "./TieUp";

function TieUps() {
  return (
    <BaseLayout>
      <div className="pb-24">
      <Breadcrumb subLink="Our Tie-Ups" color="text-orange-600"/>
       <TieUp/>
      </div>
    </BaseLayout>
  );
}

export default TieUps;
