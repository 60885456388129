import React, { useState } from "react";
import { BASE_URL } from "../../../../Services/constant";

function InverterDifference({solarPanel}) {
    console.log(solarPanel);
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabClick = (index) => {
    setSelectedTab(index === selectedTab ? index : index);
  };
 
  return (
    <div className="">
      <div className="mt-5 overflow-x-auto"> 
        <table className="border-collapse border border-black mt-5  overflow-x-auto"> 
          <thead>
            <tr>
              {solarPanel?.slice(0,3).map((tab, index) => (
                <th
                  key={index}
                  className="border border-black p-5 space-x-32 cursor-pointer"
                  onClick={() => handleTabClick(index)}
                >
                  <div className="flex justify-between px-10 font-semibold"> 
                    <span className={`text-2xl ${selectedTab === index ? 'text-black' : 'text-gray-500'}`}>{tab?.attributes?.third_tab_heading}</span>
                  </div>
                </th>
              ))}
            </tr> 
          </thead>
          <tbody>
            <tr>
              {
                solarPanel&&
                <td className="border border-black p-3" colSpan={solarPanel?.length}>
                <div className="mt-5">
                  {selectedTab !== null && (
                    
                    <div className="flex items-center mb-5">
                   <div className="flex items-center grid-cols-1 md:grid-cols-2 lg:grid-cols-1 break-words ml-10" dangerouslySetInnerHTML={{ __html: solarPanel[selectedTab]?.attributes?.third_tab_content }}>
                    </div>
                    <div className='mr-36 ml-10 w-full'>
                    <img 
                    src={`${BASE_URL}${solarPanel[selectedTab].attributes?.third_tab_image?.data?.attributes?.url}`}
                     alt='Solar Power System' className='w-64 rounded-2xl  md:h-auto mx-auto md:mx-0 md:ml-auto  ' />
                  </div>
                   </div>
                  )}
                </div>
              </td>
              }
             
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default InverterDifference;
