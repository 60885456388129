import React from "react";
import { Link } from "react-router-dom";
import BaseLayout from "../../../../Layout/BaseLayout";
import { getEsela } from "../../../../Services/ProductServices";
import { useQuery } from "@tanstack/react-query";
import { BASE_URL } from "../../../../Services/constant";
import Breadcrumb from "../../../navbar/Breadcrumb";

function Ese() {
  const { data: ese, status, error } = useQuery({
    queryKey: ["ese"],
    queryFn: getEsela,
  });
  if (status === 'loading') {
    return <span>Loading...</span>
  }

  if (status === 'error') {
    return <span>Error: {error.message}</span>
  }
  return (
    <BaseLayout>
      <Breadcrumb subLink="Products" subLinkOne="Lightning Arrester" subLinkTwo="ESE LA" />
      <div className=" px-10  lg:px-32 xl:px-32">
        {
          ese?.slice(0, 1).map(item =>
            <div className='mt-5 md:mt-24 lg:mt-24 xl:mt-24'>
              <h1 className=' mb-4 text-start md:text-center lg:text-center xl:text-center font-bold text-3xl md:text-4xl lg:text-4xl xl:text-4xl'>
                {item?.attributes?.heading}
              </h1>
              <p className='mt-0 md:mt-10 lg:mt-10 xl:mt-10 text-2xl  font-medium text-start md:text-center lg:text-center xl:text-center break-words' dangerouslySetInnerHTML={{ __html: item?.attributes?.content }}>
              </p>
            </div>
          )
        }

        {
          ese?.slice(1, 3).map(item =>
            <div className="mt-14">
              <h1 className="text-3xl md:text-4xl lg:text-4xl xl:text-4xl mb-4 font-semibold">
                {item?.attributes?.heading}
              </h1>
              <div className=" flex flex-col md:flex-row justify-between items-center">
                <div className="md:flex-1 w-1/2">
                  <div className="">
                    <p className="text-lg md:text-xl leading-relaxed break-words" dangerouslySetInnerHTML={{ __html: item?.attributes?.content }}>
                    </p>
                  </div>
                </div>

                <div className="w-1/2 mb-6 md:mb-0">
                  <img
                    src={`${BASE_URL}${item?.attributes?.image.data.attributes.url}`}
                    alt="Solar Power System"
                    className="w-full mx-auto md:mx-0 rounded-3xl p-0 md:p-2 lg:p-2 xl:p-2"
                  />
                </div>
              </div>
            </div>
          )
        }


        <div className="mt-20">
          {
            ese?.slice(3, 1000).map(item =>
              <div className="">
                <h1 className="text-center font-semibold text-3xl md:text-4xl lg:text-4xl xl:text-4xl">
                  {item?.attributes?.heading}
                </h1>
                <div className="">
                  <div className="mt-8 flex flex-col md:flex-row justify-between items-start md:items-center">
                    <div className="md:flex-1 inline">
                      <div className="">
                        <div className="flex ">
                          <div className="w-9 px-3 py-2 h-9 mr-2 mt-1 bg-orange-600 rounded-full flex items-center justify-center text-white font-medium">
                            <span className="w-3 h-3 px-2 py-2   bg-white rounded-full"></span>
                          </div>
                          <p className="text-lg md:text-xl leading-relaxed ml-3 break-words" dangerouslySetInnerHTML={{ __html: item?.attributes?.content }}>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="w-full md:w-80 mb-6 md:mb-0 mt-3 md:mt-0 lg:mt-0 xl:mt-0 ">
                      <img
                        src={`${BASE_URL}${item?.attributes?.image.data.attributes.url}`}
                        alt="Solar Power System"
                        className="w-full lg:w-60 mx-auto md:mx-0 rounded-3xl md:mr-auto px-2"
                      />
                    </div>
                  </div>
                </div>

              </div>
            )
          }
        </div>
        <div className="mb-24">
          <h1 className="mt-24 text-center font-bold text-3xl md:text-4xl lg:text-4xl xl:text-4xl">
            Know about other Lightning Arrester
          </h1>

          <div className="flex flex-row flex-wrap justify-center items-center  gap-32 mt-14 ">
            <Link to="/conventional">
              <div className="flex flex-col items-center">
                <div className="relative w-80">
                  <img
                    src="Assets/conventional la.webp"
                    alt="1"
                    className="w-full h-auto p-2 rounded-3xl"
                  />
                </div>
                <p
                  className="text-center mt-2 text-2xl md:text-3xl lg:text-3xl xl:text-3xl font-semibold"
                  style={{
                    fontFamily: "normal normal normal 35px/53px Poppins",
                    color: "#000000",
                  }}
                >
                  Conventional LA
                </p>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </BaseLayout>
  );
}

export default Ese;
