/* eslint-disable jsx-a11y/img-redundant-alt */
import { ChevronLeft, ChevronRight } from "lucide-react";
import React, { useState } from "react";
import { Link } from "react-router-dom";
function Report() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const handleNextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % totalSlides);
  };

  const handlePrevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide - 1 + totalSlides) % totalSlides);
  };
  const totalSlides = 2;
  const slides = [
    {
        imageUrl: "Image/R.png",
    },
    {
        imageUrl: "Image/R.png",
    },
    
];
  return (
    <div className="mt-24 ">
    <div
        id="indicators-carousel"
        className="relative w-full"
        data-carousel="static"
    >
        <div className="flex flex-col md:flex-row md:my-4 mt-10 ">
            {/* Left Side - Image Section */}
            <div className="w-full md:w-1/2 m-auto  flex items-center justify-center gap-5">
                <img
                    src={slides[currentSlide].imageUrl}
                    className="w-1/2 h-auto md:max-w-md "
                    alt="1"
                />
                <img
                    src={slides[currentSlide].imageUrl}
                    className="w-1/2 h-auto md:max-w-md hidden-property-mob-img2"
                    alt="2"
                />
                <img
                    src={slides[currentSlide].imageUrl}
                    className="w-1/2 h-auto md:max-w-md hidden-property-mob-img3"
                    alt="3"
                />
            </div>
        </div>


        {/* Slider Buttons */}
        <div className="flex justify-center mt-4 gap-4 ">
            <button
                className={`h-5 w-5 rounded-full border-black ${currentSlide === 0 ? "bg-orange-300" : "bg-gray-300"
                    }`}
                onClick={() => setCurrentSlide(0)}
            />
            <button
                className={`h-5 w-5 rounded-full ${currentSlide === 1 ? "bg-orange-300" : "bg-gray-300"
                    }`}
                onClick={() => setCurrentSlide(1)}
            />

            {/* Add more buttons for additional slides */}
        </div>

        <button
            type="button"
            className="absolute top-0 left-0 flex items-center justify-center h-full px-5 py-5 cursor-pointer group focus:outline-none"
            data-carousel-prev
            onClick={handlePrevSlide}
        >
            <ChevronLeft className="w-16 h-16 rounded-full  bg-white   py-4  g" />
        </button>

        <button
            type="button"
            className="absolute top-0 right-0 flex items-center justify-center h-full px-5 py-5 cursor-pointer group focus:outline-none"
            data-carousel-next
            onClick={handleNextSlide}
        >
            <ChevronRight className="w-16 h-16 rounded-full  bg-white  py-4" />
        </button>
    </div>
</div>
    // <div className="relative">
    //   <div className="flex flex-row flex-wrap justify-center items-center space-x-4 gap-20 mt-8">
    //     <div className="flex flex-col items-center">
    //       <img
    //         src="Image/R.png"
    //         alt="Image 1"
    //         className="w-64 h-auto p-2 hover:opacity-80 hidden md:block lg:block xl:block"
    //       />
    //     </div>
    //     <div className="flex flex-col items-center hidden md:block lg:block xl:block">
    //       <img
    //         src="Image/R.png"
    //         alt="Image 2"
    //         className="w-64 h-auto p-2 hover:opacity-80 hidden md:hidden lg:block xl:block"
    //       />
    //     </div>
    //     <div className="flex flex-col items-center">
    //       <img
    //         src="Image/R.png"
    //         alt="Image 3"
    //         className="w-64 h-auto p-2 hover:opacity-80"
    //       />
    //     </div>
    //   </div>
     

    //   <div className="flex justify-center mt-4 gap-4">
    //     <button
    //       className={`h-5 w-5 rounded-full ${currentSlide === 0 ? "bg-orange-500" : "bg-gray-300"
    //         }`}
    //       onClick={() => setCurrentSlide(0)}
    //     />
    //     <button
    //       className={`h-5 w-5 rounded-full ${currentSlide === 1 ? "bg-orange-500" : "bg-gray-300"
    //         }`}
    //       onClick={() => setCurrentSlide(1)}
    //     />
    //   </div>
    //   <button
    //     type="button"
    //     className="absolute  top-0 left-arrow-earthing  flex items-center justify-center h-full  cursor-pointer group focus:outline-none"
    //     data-carousel-prev
    //     onClick={handlePrevSlide}
    //   >
    //     <ChevronLeft className="w-16 h-16  rounded-full  bg-white   py-4 group-hover:bg-white group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none" />
    //   </button>

    //   <button
    //     type="button"
    //     className="absolute  top-0 right-arrow-earthing flex items-center justify-center h-full  cursor-pointer group focus:outline-none"
    //     data-carousel-next
    //     onClick={handleNextSlide}
    //   >
    //     <ChevronRight className="w-16 h-16  rounded-full  bg-white  py-4 group-hover:bg-white group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none" />
    //   </button>
    // </div>
  );
}

export default Report;
