import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BaseLayout from "../../../Layout/BaseLayout";

import "./About.css";
import Joinus from "../../JoinNow/Joinus";
import { getAboutus } from "../../../Services/AboutUsService";
import { useQuery } from "@tanstack/react-query";
import WhyDivy from "../Home/WhyDivy";
import WhyDivyy from "../Home/WhyDivy";
import Breadcrumb from "../../navbar/Breadcrumb";
import { getTieUps } from "../../../Services/HomeService";
import { BASE_URL } from "../../../Services/constant";
import TieUp from "../../Tieups/TieUp";
import JoinUsPopup from "./PopupJoinus";

const AnimatedProgressBar = ({ title, value, maxValue }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [animatedWidth, setAnimatedWidth] = useState(0);
  const [animatedValue, setAnimatedValue] = useState(0);

  const handleScroll = () => {
    const element = document.getElementById(title);
    if (!element) return;

    const elementTop = element.getBoundingClientRect().top;
    const windowHeight = window.innerHeight;

    if (elementTop < windowHeight * 0.8 && elementTop > -windowHeight * 0.2) {
      setIsVisible(true);

      // Calculate the animated width
      setAnimatedWidth((value / maxValue) * 100);

      // Calculate the animated value (increase gradually)
      const step = Math.ceil(value / (maxValue * 100)); // Adjust the step as needed
      const newValue = Math.min(animatedValue + step, value);
      setAnimatedValue(newValue);
    } else {
      setIsVisible(false);
    }
  };

  const handleResize = () => {
    const element = document.getElementById(title);
    if (!element) return;

    const elementTop = element.getBoundingClientRect().top;
    const windowHeight = window.innerHeight;

    if (elementTop < windowHeight * 0.8 && elementTop > -windowHeight * 0.2) {
      // Recalculate the animated width
      setAnimatedWidth((value / maxValue) * 100);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);
    handleScroll();
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, [value, maxValue, animatedValue, title]);

  return (
    <div id={title} className="w-full">
      <div className="flex flex-col sm:flex-row justify-between items-center mt-2 mb-4">
        <h2>{title}</h2>
        <span>{isVisible ? animatedValue.toFixed(0) : 0}</span>
      </div>
      <div className="w-full bg-gray-300 rounded-lg overflow-hidden">
        <div
          className="h-1 bg-orange-300 animated-bar"
          style={{ width: `${animatedWidth}%` }}
        ></div>
      </div>
    </div>
  );
};

function About() {
  const [showPopup, setShowPopup] = useState(false);
  const { data: aboutus, status, error } = useQuery({
    queryKey: ["aboutus"],
    queryFn: getAboutus,
  });
  const { data: tieUps} = useQuery({
    queryKey: ["tieUps"],
    queryFn: getTieUps,
  });
  if (status === 'loading') {
    return <span>Loading...</span>
  }

  if (status === 'error') {
    return <span>Error: {error.message}</span>
  }
  const openPopup = () => {
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const Card = ({ imageUrl, title, content, date, to }) => {
    return (
      <div
        className="max-w-md overflow-hidden w-full m-4 w-1/4 bg-slate-100 card-hover rounded-xl"
      // style={{ borderRadius: "2.375rem" }}
      >
        <div className="px-5 py-6 ">
          <div className=" mb-2 text-Zinc-300 break-words">{title}</div>
          <p className=" text-orange-300 text-base mt-5 break-words">{content}</p>
        </div>
      </div>
    );
  };
  return (
    <BaseLayout>
      <Breadcrumb subLink="About Us" color="text-orange-600" />
      {
        aboutus?.map(item =>
          <div className="content " >
            <p className="text-start md:text-center lg:text-center xl text-2xl break-words px-5" dangerouslySetInnerHTML={{ __html: item?.attributes?.main_content }}>

            </p>
          </div>
        )
      }

      <WhyDivyy />
      <div className="mt-20">
        <div className="flex flex-col sm:flex-col md:flex-row lg:flex-row xl:flex-row items-center justify-center  md:flex-row">
          {/* Mission Column */}
          <div className="md:w-1/2 lg:w-1/2 ">
            <h2 className="text-3xl font-lg  mt-6 mb-3 px-24 text-center md:text-start lg:text-start xl:text-start">Our Mission</h2>
            <div className="flex flex-col items-center md:items-center px-5">
              <Card
                title="Our core goal is to provide every big and small business simple and fast way to boost the profit by using the most innovative digital marketing tools."
                content="Digital Marketing for everyone at the reasonable price"
              />
              <Card
                title="Our core goal is to provide every big and small business simple and fast way to boost the profit by using the most innovative digital marketing tools."
                content="Digital Marketing for everyone at the reasonable price"
              />
              <Card
                title="Our core goal is to provide every big and small business simple and fast way to boost the profit by using the most innovative digital marketing tools."
                content="Digital Marketing for everyone at the reasonable price"
              />

            </div>
          </div>

          {/* Vision Column */}
          <div className="md:w-1/2 lg:w-1/2 ">
            <h2 className="text-3xl font-lg mt-6 mb-3 px-12 text-center md:text-start lg:text-start xl:text-start">Our Vision</h2>
            <div className="flex flex-col items-center md:items-start px-5">
              <Card
                title="Our core goal is to provide every big and small business simple and fast way to boost the profit by using the most innovative digital marketing tools."
                content="Digital Marketing for everyone at the reasonable price"
              />
              <Card
                title="Our core goal is to provide every big and small business simple and fast way to boost the profit by using the most innovative digital marketing tools."
                content="Digital Marketing for everyone at the reasonable price"
              />
              <Card
                title="Our core goal is to provide every big and small business simple and fast way to boost the profit by using the most innovative digital marketing tools."
                content="Digital Marketing for everyone at the reasonable price"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="mt-10 pb-24">
      <TieUp/>
      <JoinUsPopup/>
       
      </div>
    </BaseLayout>
  );
}

export default About;

