import React from "react";
import { BASE_URL } from "../../../../Services/constant";

function CopyEarthing({ chemEarthing }) {
  return (
    <div>
      <div className="mt-24">
        {
          chemEarthing?.slice(1, 3).map(item =>
            <div className="mt-10">
                  <h1 className="text-3xl md:text-4xl lg:text-4xl xl:text-4xl  font-semibold">
                    {item?.attributes?.heading}
                  </h1>
            <div className=" flex flex-col md:flex-row justify-between items-center">
              <div className="md:flex-1">
                <div className="">
                  <p className="text-lg md:text-xl leading-relaxed break-words" dangerouslySetInnerHTML={{ __html: item?.attributes?.content }}>
                  </p>
                </div>
              </div>

              <div className="w-full md:w-80 mb-6  md:mb-0">
                <img
                  src={`${BASE_URL}${item?.attributes?.image?.data?.attributes?.url}`}
                  alt="Solar Power System"
                  className="w-full h-72 mx-auto md:mx-0 p-0 md:p-2 lg:p-2 xl:p-2"
                />
              </div>
            </div>
            </div>
          )
        }
      </div>

      <div className="mt-10">
        {
          chemEarthing?.slice(3, 1000).map(item =>
            <div className="">
              <h1 className="text-center font-semibold text-3xl md:text-4xl lg:text-4xl xl:text-4xl">
                {item?.attributes?.heading}
              </h1>
              <div className="">
                <div className="mt-8 flex flex-col md:flex-row justify-between items-start md:items-center">
                  <div className="md:flex-1 inline">
                    <div className="">
                      <div className="flex ">
                        <div className="w-9 px-3 py-2 h-9 mr-2 mt-1 bg-orange-600 rounded-full flex items-center justify-center text-white font-medium">
                          <span className="w-3 h-3 px-2 py-2   bg-white rounded-full"></span>
                        </div>
                        <p className="text-lg md:text-xl leading-relaxed ml-3 break-words" dangerouslySetInnerHTML={{ __html: item?.attributes?.content }}>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="w-full md:w-80 mb-6 md:mb-0 mt-3 md:mt-0 lg:mt-0 xl:mt-0 ">
                    <img
                      src={`${BASE_URL}${item?.attributes?.image?.data?.attributes?.url}`}
                      alt="Solar Power System"
                      className="w-full lg:w-60 mx-auto md:mx-0 rounded-3xl md:mr-auto px-2"
                    />
                  </div>
                </div>
              </div>

            </div>
          )
        }
      </div>
    </div>
  );
}

export default CopyEarthing;
