import { defaultApi } from "./api";
import { BASE_URL } from "./constant";

export async function getService() {
  const res = await defaultApi.get(`/api/services`);
  return res.data.data;
}
export async function getEpc() {
  const res = await defaultApi.get(`/api/epcs`);
  return res.data.data;
}
export async function getPmc() {
  const res = await defaultApi.get(`/api/pmcs`);
  return res.data.data;
}
export async function getInstallations() {
  const res = await defaultApi.get(`/api/installations`);
  return res.data.data;
}
export async function getHealthCheckups() {
  const res = await defaultApi.get(`/api/health-checkups`);
  return res.data.data;
}
export async function getFeasibility() {
  const res = await defaultApi.get(`/api/feasibilities`);
  return res.data.data;
}