import { useQuery } from '@tanstack/react-query';
import React from 'react'
import { getProducts } from '../../../Services/HomeService';
import { MapPin, ChevronRight, ChevronLeft } from "lucide-react";
import { BASE_URL } from '../../../Services/constant';
import { Link } from 'react-router-dom';


const OurProducts = ({ currentSlide, handleNextSlide, handlePrevSlide, setCurrentSlide }) => {
  const { data: products, status, error } = useQuery({
    queryKey: ["products"],
    queryFn: getProducts,
  });
  if (status === 'loading') {
    return <span>Loading...</span>
  }

  if (status === 'error') {
    return <span>Error: {error.message}</span>
  }
  const redirection = [
    {
      to: "/on-grid",
    },
    {
      to: "/off-grid",
    },
    {
      to: "/hybrid",
    },
    {
      to: "/earthing",
    }
  ]

  return (
    <div>
      {
        products &&
        <div className="mt-24" >
          <div
            id="indicators-carousel"
            className="relative w-full px-10 sm:px-24 md:px-20 lg:px-44 xl:px-44"
            data-carousel="static"
          >
            <h1 className="text-4xl text-center w-full mb-0 sm:mb-0 md:mb-10 lg:mb-10 xl:mb-10  font-bold">
              {products[currentSlide].attributes.Heading}
            </h1>
            <div className="flex flex-col items-center md:flex-row lg:flex-row xl:flex-row mt-10 ">
              {/* Left Side - Image Section */}
              <div className="w-full sm:w-full md:w-1/2 lg:w-1/2 xl:w-1/2 ml-10  flex items-center justify-center">
                <img
                  src={`${BASE_URL}${products[currentSlide].attributes.Image.data.attributes.url}`}
                  className="w-full sm:w-full md:w-11/12 lg:w-11/12 xl:w-11/12 h-auto  md:max-w-md "
                  alt="Product"
                />
              </div>

              {/* Right Side - Content Section */}
              <div className="w-full  md:w-1/2 flex-1">
                <h1 className="text-3xl text-center sm:text-center md:text-end lg:text-end xl:text-end mb-10 font-bold">
                  {products[currentSlide].attributes.SubHeading}
                </h1>
                <p
                  className="md:text-left mb-5 w-full max-w-lg break-words"
                  dangerouslySetInnerHTML={{
                    __html: products[currentSlide].attributes.content,
                  }}
                ></p>
                <div className="flex items-center justify-center sm:justify-end md:justify-end lg:justify-end xl:justify-end mt-4">
                  <button
                    type="button"
                    className="absolute  top-50  left-0 flex items-center justify-center h-full  cursor-pointer group focus:outline-none"
                    data-carousel-prev
                    onClick={handlePrevSlide}
                  >
                    <ChevronLeft className="w-16 h-16 block md:hidden lg:hidden xl:hidden rounded-full  bg-white   py-4 group-hover:bg-white group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none" />
                  </button>
                  <button className="bg-orange-600 text-white font-semibold px-4 py-2 rounded-full">
                    <Link to={redirection[currentSlide].to}>
                      Know More
                    </Link>
                  </button>
                  <button
                    type="button"
                    className="absolute top-50 right-0 flex items-center justify-center h-full  cursor-pointer group focus:outline-none"
                    data-carousel-next
                    onClick={handleNextSlide}
                  >
                    <ChevronRight className="w-16 h-16 block md:hidden lg:hidden xl:hidden rounded-full  bg-white  py-4 group-hover:bg-white group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none" />
                  </button>

                </div>
              </div>
            </div>

            {/* Slider Buttons */}
            <div className="flex justify-center mt-4 gap-4 ">
              {
                products?.map((item, index) =>
                  <button
                    key={item.id}
                    className={`h-5 w-5 rounded-full ${currentSlide === index ? "bg-orange-500" : "bg-gray-300"
                      }`}
                    onClick={() => setCurrentSlide(index)}
                  />
                )}
              {/* Add more buttons for additional slides */}
            </div>

            <button
              type="button"
              className="absolute arrow-btn-left top-0  flex items-center justify-center h-full  cursor-pointer group focus:outline-none"
              data-carousel-prev
              onClick={handlePrevSlide}
            >
              <ChevronLeft className="w-16 h-16 hidden md:block lg:block xl:block rounded-full  bg-white   py-4 group-hover:bg-white group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none" />
            </button>

            <button
              type="button"
              className="absolute arrow-btn-right top-0 flex items-center justify-center h-full  cursor-pointer group focus:outline-none"
              data-carousel-next
              onClick={handleNextSlide}
            >
              <ChevronRight className="w-16 h-16 hidden md:block lg:block xl:block rounded-full  bg-white  py-4 group-hover:bg-white group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none" />
            </button>
          </div>
        </div>
      }
    </div>
  )
}

export default OurProducts
