import React, { useState } from "react";
import Footer from "../Components/Homesection/Footer/Footer";
// import Navbar from "../Components/navbar";
import Navbar from "../Components/navbar/navbar";
import ScrollToTop from "../Components/ScrollToTop/ScrollToTop";

export default function BaseLayout({ children }) {
  const [isLoggedin, setIsLoggedin] = useState(localStorage.getItem('token'));
  return (
    <>
      {/* <ScrollToTop /> */}
      <Navbar isLoggedin={isLoggedin}/>
      {children}
      <Footer />
    </>
  );
}
