import React, { useState } from "react";
import { Link } from "react-router-dom";
import BaseLayout from "../../../Layout/BaseLayout";
import { useQuery } from "@tanstack/react-query";
import { getBlogs, getNews } from "../../../Services/FooterService";
import "./Blog.css";
import Breadcrumb from "../../navbar/Breadcrumb";

const Card = ({ imageUrl, title, date, to, tag }) => {
  return (
    <div
      className=" max-w-md overflow-hidden relative  m-4 w-full md:w-2/4 lg:w-1/4 xl:w-1/4 border border-black card-hover"
      style={{ borderRadius: "2.375rem" }}
    >
      <Link to={to}>
        <button className="bg-white-600 rounded-2xl text-black absolute ongrid-btn mt-5 ml-20 right-4">
          {tag}
        </button>
        <img
          className="w-full h-40 object-cover border-gray-800"
          src={imageUrl}
          alt={title}
        />
        <div className="px-5 py-2 ">
          <div className="font-bold text-xl mb-2 relative">{title}</div>
          {/* <p className="text-gray-700 text-base">{content}</p> */}
          <hr className="text-black-600 mt-5 border border-current"></hr>
          <p className=" text-center text-base mt-5 font-semibold">{date}</p>
        </div>
      </Link>
    </div>
  );
};

function BlogNews() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [blog, setBlog] = useState(true);
  const [news, setNews] = useState(false);

  const { data: newsData } = useQuery({
    queryKey: ["newsData"],
    queryFn: getNews,
  });
  const { data: blogs } = useQuery({
    queryKey: ["blogs"],
    queryFn: getBlogs,
  });

  const handleBlogOpen = () => {
    setNews(!news);
    setBlog(!blog);
  };

  return (
    <BaseLayout>
      <Breadcrumb subLink="Blogs & News" color="text-orange-600" />
      <div className="container mx-auto my-24">
        <div className=" flex justify-evenly mb-10 w-4/6">
          <button
            className={`text-gray-500 text-3xl font-semibold`}
            onClick={() => {
              handleBlogOpen();
            }}
          >
            {blog ? "News" : "Blogs"}
          </button>
          <button className="text-3xl   font-semibold ">
            {news ? "News" : "Blogs"}
          </button>
        </div>
        {blog &&
          blogs?.map((item, index) => (
            <div
              className="flex flex-wrap justify-center text-center gap-5 transition"
              key={item.id}
            >
              <Card
                imageUrl="Image/Blog.png"
                title={item?.attributes?.title}
                date="24 july 2023"
                to="/blog"
                tag={item?.attributes?.tag}
              />
              <Card
                imageUrl="Image/Blog.png"
                title={item?.attributes?.title}
                date="24 july 2023"
                to="/blog"
                tag={item?.attributes?.tag}
              />
              <Card
                imageUrl="Image/Blog.png"
                title={item?.attributes?.title}
                date="24 july 2023"
                to="/blog"
                tag={item?.attributes?.tag}
              />
              <Card
                imageUrl="Image/Blog.png"
                title={item?.attributes?.title}
                date="24 july 2023"
                to="/blog"
                tag={item?.attributes?.tag}
              />
              <Card
                imageUrl="Image/Blog.png"
                title={item?.attributes?.title}
                date="24 july 2023"
                to="/blog"
                tag={item?.attributes?.tag}
              />
              <Card
                imageUrl="Image/Blog.png"
                title={item?.attributes?.title}
                date="24 july 2023"
                to="/blog"
                tag={item?.attributes?.tag}
              />
              
            </div>
          ))}
        {news &&
          newsData?.map((item, index) => (
            <div
              className="flex flex-wrap justify-center text-center gap-5 transition"
              key={item.id}
            >
              <Card
                imageUrl="Image/Blog.png"
                title={item?.attributes?.title}
                date="24 july 2023"
                to="/news"
                tag={item?.attributes?.tag}
              />
               <Card
                imageUrl="Image/Blog.png"
                title={item?.attributes?.title}
                date="24 july 2023"
                to="/news"
                tag={item?.attributes?.tag}
              />
               <Card
                imageUrl="Image/Blog.png"
                title={item?.attributes?.title}
                date="24 july 2023"
                to="/news"
                tag={item?.attributes?.tag}
              />
               <Card
                imageUrl="Image/Blog.png"
                title={item?.attributes?.title}
                date="24 july 2023"
                to="/news"
                tag={item?.attributes?.tag}
              />
               <Card
                imageUrl="Image/Blog.png"
                title={item?.attributes?.title}
                date="24 july 2023"
                to="/news"
                tag={item?.attributes?.tag}
              />
               <Card
                imageUrl="Image/Blog.png"
                title={item?.attributes?.title}
                date="24 july 2023"
                to="/news"
                tag={item?.attributes?.tag}
              />
              
            </div>
          ))}

        <div className="flex justify-center mt-4 gap-4">
          <button
            className={`h-8 w-8  text-white  border-red-400  rounded-full ${currentSlide === 0 ? "bg-orange-500" : "bg-gray-300"
              }`}
            onClick={() => setCurrentSlide(0)}
          >
            1
          </button>

          <button
            className={`h-8 w-8 text-white rounded-full ${currentSlide === 1 ? "bg-orange-500" : "bg-gray-300"
              }`}
            onClick={() => setCurrentSlide(1)}
          >
            2
          </button>

          <button
            className={`h-8 w-8  text-white rounded-full ${currentSlide === 2 ? "bg-orange-500" : "bg-gray-300"
              }`}
            onClick={() => setCurrentSlide(2)}
          >
            3
          </button>

          <button
            className={`h-8 w-8 text-white rounded-full ${currentSlide === 3 ? "bg-orange-500" : "bg-gray-300"
              }`}
            onClick={() => setCurrentSlide(3)}
          >
            4
          </button>
        </div>
      </div>
    </BaseLayout>
  );
}

export default BlogNews;
