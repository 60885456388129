import React from "react";
import { Link } from "react-router-dom";
import BaseLayout from "../../../Layout/BaseLayout";
import { useQuery } from "@tanstack/react-query";
import { getBlogs } from "../../../Services/FooterService";
import { BASE_URL } from "../../../Services/constant";
import Breadcrumb from "../../navbar/Breadcrumb";

const BlogHeading = () => {
  const { data: blogs } = useQuery({
    queryKey: ["blogs"],
    queryFn: getBlogs,
  });

  return (
    <BaseLayout>
      <Breadcrumb subLink="Blogs" subLinkOne="Blog Name" to="/blogs-and-news"/>
      <div className="relative pb-20">
        <div
          style={{
            backgroundImage: "url(/Image/service.svg)",
            backgroundRepeat: "no-repeat",
          }}
          className="absolute inset-0 w-full h-full -z-10 opacity-50"
        ></div>
        <div className="pt-0 md:pt-20 lg:pt-20 xl:pt-20 px-8 md:px-20 lg:px-20 xl:px-20">
          <h1 className="text-4xl font-bold p-10 mt-10 text-center">
            Blogs Heading
          </h1>
          {blogs?.map((item) => (
            <div className="break-words" key={item.id}>
              <div>
                <img
                  src={`${BASE_URL}${item?.attributes?.blog_image.data.attributes.url}`}
                  alt="Longtail boat in Thailand"
                  className="w-full md:w-2/5 lg:w-2/5 xl:w-2/5 h-full md:h-2/5 lg:h-2/5 xl:h-2/5 rounded-3xl  md:p-3 lg:p-3 xl:p-3  md:float-right lg:float-right xl:float-right"
                />
              </div>

              <p
                className="text-lg break-words mt-5 md:mt-0 lg:mt-0 xl:mt-0"
                dangerouslySetInnerHTML={{
                  __html: item?.attributes?.blog_content,
                }}
              ></p>
            </div>
          ))}
        </div>
      </div>
    </BaseLayout>
  );
};

export default BlogHeading;
