import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { defaultApi } from "../../../Services/api";
import BaseLayout from "../../../Layout/BaseLayout";
import Breadcrumb from "../../navbar/Breadcrumb";
import { toast } from "react-toastify";

function Sampark() {
  const [userId, setUserId] = useState("");
  const [password, setPassword] = useState("");
  const [currentSlide, setCurrentSlide] = useState(0);

  const handleUserIdChange = (e) => {
    setUserId(e.target.value);
  };
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  const navigate = useNavigate();

  const loginCredential = {
    "identifier": userId,
    "password": password,
  }
  const handleFormSubmit = async (event) => {
    event.preventDefault()
    if (!userId) {
      toast.warning("Email is required!");
      return;
    }
    if (!password) {
      toast.warning("Password is required!");
      return;
    }
    try {
      await defaultApi
        .post('https://divyadmin.pb18.in/api/auth/local?populate=*', loginCredential)
        .then((response) => {
          const token = response.data.jwt;
          const userData = response.data;
          window.localStorage.setItem('token', token);
          window.localStorage.setItem('userData', JSON.stringify(userData));
          window.location.href = '/employee-dashboard'; 
          // navigate('/employee-dashboard')
        })
    } catch (error) {
      toast.error(error?.response?.data?.error?.message)
      console.log('error', error)
    }
  }

  return (
    <BaseLayout>
      <Breadcrumb subLink="Sampark" />
      <div className="my-24 px-6">
        <h1 className="text-center font-bold mt-10 text-4xl text-md">
          Log into Your Account
        </h1>
        <form
          className="mt-10 max-w-md space-y-5 mx-auto"
          onSubmit={handleFormSubmit}
        >
          <div className="mb-4 ">
            <input
              type="text"
              id="userId"
              placeholder="Enter Your ID"
              className="mt-1 px-4 py-4  w-full border border-gray-500 font-medium  placeholder-slate-500 text-xl rounded-full focus:ring focus:ring-orange-500 focus:outline-none"
              value={userId}
              onChange={handleUserIdChange}
              required
            />
          </div>
          <div className="mb-4">
            <input
              type="password"
              id="password"
              placeholder="Password"
              className="mt-1 px-4 py-4  w-full border border-gray-500 rounded-full placeholder-slate-500 text-xl    focus:ring focus:ring-orange-500 focus:outline-none"
              value={password}
              onChange={handlePasswordChange}
              required
            />
          </div>
          <div className="flex justify-center mt-24 ">
            <button
              type="submit"
              className="bg-orange-600 text-white text-2xl  px-12  py-4 rounded-full"
            >
              Sign In
            </button>
          </div>
        </form>
      </div>
    </BaseLayout>
  );
}

export default Sampark;
