import React from 'react'
import { getTieUps } from '../../Services/HomeService';
import { useQuery } from '@tanstack/react-query';
import { BASE_URL } from '../../Services/constant';

const TieUp = () => {
    const { data: tieUps, status, error } = useQuery({
        queryKey: ["tieUps"],
        queryFn: getTieUps,
      });
      if (status === 'loading') {
        return <span>Loading...</span>
      }
    
      if (status === 'error') {
        return <span>Error: {error.message}</span>
      }
  return (
    <div className="mt-10">
    <h1 className="text-4xl text-center font-bold">Our Tie-Ups</h1>
    <div className="flex flex-wrap items-center justify-evenly ml-24 mt-10  mr-24 md:flex-row">
      {/* Mission Column */}
        {
          tieUps?.map(img=>
      <div className="md:w-1/4 lg:w-1/4 ">
            <div className="flex flex-col items-center md:items-start">
            <img
              className="w-52 object-cover mb-2"
              src={`${BASE_URL}${img?.attributes?.image?.data?.attributes?.url}`}
              alt="Mission 1"
            />
          </div>
      </div>
            )
        }
    </div>
  </div>
  )
}

export default TieUp
