import axios from "axios";
import { BASE_URL } from "./constant";

export const defaultApi = axios.create({ 
  baseURL: BASE_URL ,
});

export const authApi = axios.create({ 
  baseURL: BASE_URL ,
});

authApi.interceptors.request.use(
  (config) => { 
    const token = localStorage.getItem("token"); 
    if (token) { 
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);

  } 
); 

authApi.interceptors.response.use(function  (response) {
  console.log(response);
 
  return response;
}, function (error) { 
    window.location.href='*';
  return Promise.reject(error);
});

